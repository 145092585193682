<template>
  <b-card id="productCardProductContacts">
    <template #header>
      <a id="productcontacts" />
      <h5 class="d-inline-block">Product Contacts</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-productcontacts/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <dl v-if="productContacts" class="row">
      <dt class="col-sm-3 font-weight-bold text-secondary">Product Contacts</dt>
      <dd class="col-sm-9">&#160;</dd>
      <template v-if="productContactsGroup || showAllFields">
        <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Group</dt>
        <dd v-if="productContactsGroup" class="col-sm-9">
          {{ productContactsGroup.getName }}
        </dd>
        <dd v-else-if="showAllFields" class="col-sm-9">&#x2014;</dd>
      </template>
      <template v-for="(contact, iContact) in productContacts">
        <dt
          :key="'l' + iContact"
          class="col-sm-3 pl-5 font-weight-bold text-secondary"
        >
          <template v-if="productContactsGroup">&emsp;</template>
          {{ contact.getRoleName }}
        </dt>
        <dd :key="'d' + iContact" class="col-sm-9">
          <template v-if="productContactsGroup">&emsp;</template>
          {{ contact.getOrganisation }}
          <i v-if="contact.getOrganisationContact">
            ({{ contact.getOrganisationContact }})
          </i>
        </dd>
      </template>
    </dl>
    <dl v-else-if="showAllFields" class="row">
      <dt class="col-sm-3 font-weight-bold text-secondary">Product Contacts</dt>
      <dd class="col-sm-9">&#160;</dd>
    </dl>
    <template v-for="(supplier, iSupplier) in suppliers">
      <dl
        v-if="supplier.getSupplierContacts || showAllFields"
        :key="iSupplier"
        class="row"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Supplier Contacts
        </dt>
        <dd class="col-sm-9">{{ supplier.getName }}</dd>
        <template v-if="supplier.getSupplierContactsGroup || showAllFields">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Group</dt>
          <dd v-if="supplier.getSupplierContactsGroup" class="col-sm-9">
            {{ supplier.getSupplierContactsGroup.getName }}
          </dd>
          <dd v-else-if="showAllFields" class="col-sm-9">&#x2014;</dd>
        </template>
        <template v-for="(contact, iContact) in supplier.getSupplierContacts">
          <dt
            :key="'l' + iContact"
            class="col-sm-3 pl-5 font-weight-bold text-secondary"
          >
            <template v-if="supplier.getSupplierContactsGroup">&emsp;</template>
            {{ contact.getRoleName }}
          </dt>
          <dd :key="'d' + iContact" class="col-sm-9">
            <template v-if="supplier.getSupplierContactsGroup">&emsp;</template>
            {{ contact.getOrganisation }}
            <i v-if="contact.getOrganisationContact">
              ({{ contact.getOrganisationContact }})
            </i>
          </dd>
        </template>
      </dl>
    </template>
  </b-card>
</template>

<script>
/**
 * Displays a card containing the product contacts
 */
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";

export default {
  name: "ProductContacts",

  components: {
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
  },

  mixins: [DisplaySetting],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * product contacts group
     */
    productContactsGroup: {
      type: Object,
      default: null,
    },

    /**
     * product contacts (can be product contacts from a group)
     */
    productContacts: {
      type: Array,
      default: null,
    },

    /**
     * Suppliers
     */
    suppliers: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
