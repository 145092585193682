<template>
  <tr :class="'biblio_resultslist_line' + className">
    <template v-for="(column, columnNo) in columnData">
      <td
        :key="columnNo"
        :class="
          typeof column == 'object' && 'imageType' in column
            ? 'biblio_resultslist_image'
            : null
        "
      >
        <input
          v-if="columnInfo[columnNo].marker"
          v-model="recordMarked"
          type="checkbox"
          @click="$emit('markedChange', recordId, $event.target.checked)"
        />
        <a
          v-if="columnInfo[columnNo].dlink"
          :href="detailedLink"
          :data-v-step="'biblio-searchresults-title' + columnNo"
        >
          <template v-if="typeof column == 'object' && 'imageType' in column">
            <img
              v-if="column.imageType == 'pdf'"
              src="/common/images/pdflogo_l.png"
              class="biblio_resultslist_image_pdf"
            />
            <img
              v-else-if="column.imageType == 'localImage'"
              :src="column.url + '?L=' + logonId + '&H50W50B'"
              class="biblio_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
            <img
              v-else
              :src="remoteImageUrl(column.url)"
              class="biblio_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
          </template>
          <template v-else>
            {{ column }}
          </template>
        </a>
        <template v-else>
          <template v-if="typeof column == 'object' && 'imageType' in column">
            <img
              v-if="column.imageType == 'pdf'"
              src="/common/images/pdflogo_l.png"
              class="biblio_resultslist_image_pdf"
            />
            <img
              v-else-if="column.imageType == 'localImage'"
              :src="column.url + '?L=' + logonId + '&H50W50B'"
              class="biblio_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
            <img
              v-else
              :src="column.url"
              class="biblio_resultslist_image"
              onerror="this.onerror=null;this.src='/common/images/noimage.png'"
            />
          </template>
          <template v-if="typeof column == 'object' && 'class' in column">
            <div :class="column.class">{{ column.value }}</div>
          </template>
          <template v-else>
            {{ column }}
          </template>
        </template>
        <resultslist-tags v-if="columnInfo[columnNo].tags" :tags="tags">
        </resultslist-tags>
      </td>
    </template>
  </tr>
</template>

<script>
/**
 * Display the Biblio Results List Line
 */
import ResultsListTags from "./ResultsListTags.vue";

export default {
  name: "ResultsListLine",

  components: {
    "resultslist-tags": ResultsListTags,
  },

  props: {
    /**
     * Logon id
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      required: true,
    },

    /**
     * Record position (in results list)
     */
    recordPos: {
      type: Number,
      required: true,
    },

    /**
     * Record Id
     */
    recordId: {
      type: Number,
      required: true,
    },

    /**
     * Record is marked
     */
    recordMarked: {
      type: Boolean,
      default: false,
    },

    /**
     * Column information (each entry in an object with the properties 'caption', 'width', 'sort' (optional),
     * 'dlink' (bool, optional), 'tags' (bool, optional), 'marker' (bool, optional))
     */
    columnInfo: {
      type: Array,
      required: true,
    },

    /**
     * Column data
     */
    columnData: {
      type: Array,
      required: true,
    },

    /**
     * Tags data
     */
    tags: {
      type: Array,
      default: () => [],
    },

    /**
     * Line css class
     */
    className: {
      type: String,
      default: null,
    },

    /**
     * Search form name
     */
    searchForm: {
      type: String,
      default: "biblio",
    },

    /**
     * Object containing details of the FTP catalogue. If this is present
     * then the search form and results are being used to update the search
     * configuration on an existing catalogue.
     *
     * @property {string} mode The mode of the catalogue
     * @property {int} id The ID of the catalogue
     */
    ftpCatalogueDetails: {
      type: Object,
      default: null,
    },
  },

  computed: {
    detailedLink: function () {
      let link =
        this.$baseUrl +
        "biblio/detailed/" +
        this.recordId +
        "/" +
        this.searchId +
        "/" +
        this.recordPos;
      if (this.ftpCatalogueDetails !== null) {
        link +=
          "?ftpCatalogueMode=" +
          this.ftpCatalogueDetails.mode +
          "&ftpCatalogueId=" +
          this.ftpCatalogueDetails.id;
      }
      return link;
    },
  },

  methods: {
    remoteImageUrl: function (url) {
      if (location.protocol == "https:" && url.search(/^http:/) == 0) {
        return (
          "/common/proxy.php/" + this.$appId + "?url=" + encodeURIComponent(url)
        );
      }

      return url;
    },
  },
};
</script>

<style>
tr.biblio_resultslist_line td {
  padding: 0 0.25em;
}
td.biblio_resultslist_image {
  text-align: center;
}
img.biblio_resultslist_image {
  border: 0;
  height: 50px;
  margin: 5px;
}
img.biblio_resultslist_image_pdf {
  border: 0;
  height: 32px;
  width: 32px;
  margin: 5px;
}
</style>
