<template>
  <div>
    <b-tabs>
      <b-tab title="Data" active>
        <!-- format -->
        <div class="row">
          <div class="col-12">
            Send data in
            <select v-model="format" size="1" @change="formatChanged">
              <optgroup label="ONIX 2.1 XML">
                <option value="ONIXsx">
                  ONIX 2.1 XML with Short tags and Schema
                </option>
                <option value="ONIXrx">
                  ONIX 2.1 XML with Reference names and Schema
                </option>
                <option value="ONIXsd">
                  ONIX 2.1 XML with Short tags and DTD
                </option>
                <option value="ONIXrd">
                  ONIX 2.1 XML with Reference names and DTD
                </option>
                <option value="ONIXsx/ra">
                  ONIX 2.1 XML with Short tags, Schema and release attribute
                </option>
                <option value="ONIXrx/ra">
                  ONIX 2.1 XML with Reference names, Schema and release
                  attribute
                </option>
                <option value="ONIXsd/ra">
                  ONIX 2.1 XML with Short tags, DTD and release attribute
                </option>
                <option value="ONIXrd/ra">
                  ONIX 2.1 XML with Reference names, DTD and release attribute
                </option>
              </optgroup>
              <optgroup label="ONIX 3.0 XML">
                <option value="ONIX30s">ONIX 3.0 XML with Short tags</option>
                <option value="ONIX30r">
                  ONIX 3.0 XML with Reference names
                </option>
                <option value="ONIX30s/ns">
                  ONIX 3.0 XML with Short tags and namespace
                </option>
                <option value="ONIX30r/ns">
                  ONIX 3.0 XML with Reference names and namespace
                </option>
              </optgroup>
              <optgroup label="ONIX 3.1 XML">
                <option value="ONIX31s">ONIX 3.1 XML with Short tags</option>
                <option value="ONIX31r">
                  ONIX 3.1 XML with Reference names
                </option>
                <option value="ONIX31s/ns">
                  ONIX 3.1 XML with Short tags and namespace
                </option>
                <option value="ONIX31r/ns">
                  ONIX 3.1 XML with Reference names and namespace
                </option>
              </optgroup>
              <optgroup label="ONIX based XML">
                <option value="ONIXcpubsw">
                  ONIX 2.1 based XML for Publishing Software
                </option>
                <option value="ONIXcilsacs">
                  Ingram Lightning Source's Automated Content Submission (ACS)
                </option>
                <option value="ONIXcitmsp">iTunes Store Book Package</option>
              </optgroup>
              <optgroup label="Spreadsheets">
                <option
                  v-for="(formatName, formatKey) in dataFormats"
                  :key="formatKey"
                  :value="formatKey"
                >
                  {{ formatName }}
                </option>
              </optgroup>
            </select>
            format
            <template v-if="format.substring(0, 4) == 'ONIX'">
              with
              <select v-model="formatEncoding" size="1" @change="formatChanged">
                <option value="">ISO-8859-1</option>
                <option value="/u8">UTF-8</option>
                <option value="/ascii">US-ASCII</option>
              </select>
              encoding
            </template>
          </div>
        </div>
        <!-- all the other settings -->
        <xfer-settings
          :feed-recipient-id="feedRecipientId"
          :feed-recipient-name="feedRecipientName"
          channel-id="data"
          :xfer="dataChannel"
          :default-xfer-options="defaultDataXferOptions"
          :filename-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
            $INDEX$: ['File Index'],
          }"
          :webform-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
            $INDEX$: ['File Index'],
            $MIME$: ['MIME-type'],
            $MD5$: ['MD5'],
            $SHA1$: ['SHA-1'],
            $SHA256$: ['SHA-256'],
            $SHA224$: ['SHA-224'],
          }"
          :zip-filename-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
          }"
          :allow-email="true"
          :allow-itmst="true"
          :allow-zip-split="true"
          :allow-file-split="true"
          @change="
            (newXfer) => {
              dataChannelChanged(newXfer);
            }
          "
          @data-errors="dataChannelChanged(false)"
        />
      </b-tab>
      <b-tab title="Images">
        <!-- image settings -->
        <xfer-settings
          :feed-recipient-id="feedRecipientId"
          :feed-recipient-name="feedRecipientName"
          channel-id="image"
          description="images"
          :xfer="mediaChannels.image"
          :default-xfer-options="mediaDefaultXferOptions.image"
          :filename-tokens="{
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'Image file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'Image file\'s extension'],
            $MTYPE$: ['Media-type', 'Image file\'s media type'],
            $INDEX$: ['Index', 'Image file\'s index'],
          }"
          :webform-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'Image file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'Image file\'s extension'],
            $MIME$: ['MIME-type', 'Image file\'s MIME type'],
            $MTYPE$: ['Media-type', 'Image file\'s media type'],
            $INDEX$: ['Index', 'Image file\'s index'],
          }"
          :zip-filename-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
          }"
          :allow-all-media="true"
          :allow-ref="true"
          :allow-data-zip="true"
          :allow-credentials-copy="true"
          :credentials-xfer="dataChannel"
          @change="
            (newXfer) => {
              mediaChannelChanged('image', 2, newXfer);
            }
          "
          @data-errors="mediaChannelChanged('image', 2, false)"
        />
      </b-tab>
      <b-tab title="eBooks">
        <!-- ebook settings -->
        <xfer-settings
          :feed-recipient-id="feedRecipientId"
          :feed-recipient-name="feedRecipientName"
          channel-id="ebook"
          description="eBooks"
          :xfer="mediaChannels.ebook"
          :default-xfer-options="mediaDefaultXferOptions.ebook"
          :filename-tokens="{
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'eBook file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'eBook file\'s extension'],
          }"
          :webform-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'eBook file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'eBook file\'s extension'],
            $MIME$: ['MIME-type', 'eBook file\'s MIME type'],
          }"
          :zip-filename-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
          }"
          :allow-data-zip="true"
          :allow-credentials-copy="true"
          :credentials-xfer="dataChannel"
          @change="
            (newXfer) => {
              mediaChannelChanged('ebook', 4, newXfer);
            }
          "
          @data-errors="mediaChannelChanged('ebook', 4, false)"
        />
      </b-tab>
      <b-tab title="eBooks for Searching">
        <!-- ebook for searching settings -->
        <xfer-settings
          :feed-recipient-id="feedRecipientId"
          :feed-recipient-name="feedRecipientName"
          channel-id="ebookSearch"
          description="eBooks for Searching"
          :xfer="mediaChannels.ebookSearch"
          :default-xfer-options="mediaDefaultXferOptions.ebookSearch"
          :filename-tokens="{
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'eBook file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'eBook file\'s extension'],
          }"
          :webform-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $PUBID$: [
              'Publisher-ID',
              'Associated record\'s Publisher Unique ID value',
            ],
            $EAN$: ['EAN', 'Associated record\'s EAN value'],
            $ISBN13$: ['ISBN-13', 'Associated record\'s ISBN-13 value'],
            $ISBN10$: ['ISBN-10', 'Associated record\'s ISBN-10 value'],
            $UPC$: ['UPC', 'Associated record\'s UPC value'],
            $TXTWORKID$: [
              'Work-ID',
              'Associated record\'s Textual Work ID value',
            ],
            $NAME$: [
              'Name',
              'eBook file\'s original filename (without an extension)',
            ],
            $EXT$: ['Extension', 'eBook file\'s extension'],
            $MIME$: ['MIME-type', 'eBook file\'s MIME type'],
          }"
          :zip-filename-tokens="{
            $YYYYMMDD$: ['Date(YMD)'],
            $YYDDD$: ['Date(YD)'],
            $YYYYMMDD$$HHMMSS$: ['Date+Time'],
            $EXT$: ['Extension'],
          }"
          :allow-data-zip="true"
          :allow-credentials-copy="true"
          :credentials-xfer="dataChannel"
          @change="
            (newXfer) => {
              mediaChannelChanged('ebookSearch', 8, newXfer);
            }
          "
          @data-errors="mediaChannelChanged('ebookSearch', 8, false)"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings feed transfer component
 */
import XferSettings from "./XferSettings.vue";

export default {
  name: "FeedXfer",

  components: {
    "xfer-settings": XferSettings,
  },

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Feed recipient name
     */
    feedRecipientName: {
      type: String,
      required: true,
    },

    /**
     * Data transfer object
     */
    dataXfer: {
      type: Object,
      default: null,
    },

    /**
     * Media transfer object
     */
    mediaXfer: {
      type: Object,
      default: null,
    },

    /**
     * Default data transfer options
     */
    defaultDataXferOptions: {
      type: String,
      default: "",
    },

    /**
     * Default media transfer options
     */
    defaultMediaXferOptions: {
      type: Object,
      default: null,
    },

    /**
     * Data formats
     */
    dataFormats: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    let dataXfer = this.dataXfer ?? {};
    let mediaXfer = this.mediaXfer ?? {};
    let defaultMediaXferOptions = this.defaultMediaXferOptions ?? {};
    let dataObject = {
      format: dataXfer.format ?? "ONIXsx",
      formatEncoding: "",
      dataChannel: dataXfer,
      mediaChannels: {
        image: mediaXfer.image ?? {},
        ebook: mediaXfer.ebook ?? {},
        ebookSearch: mediaXfer.ebookSearch ?? {},
      },
      channelErrors: 0,
      mediaDefaultXferOptions: {
        image: defaultMediaXferOptions.image ?? "",
        ebook: defaultMediaXferOptions.ebook ?? "",
        ebookSearch: defaultMediaXferOptions.ebookSearch ?? "",
      },
    };

    if (dataObject.format.search(/^ONIX[a-z0-9/]+\/(u8|ascii)$/) == 0) {
      let encodingSeparator = dataObject.format.lastIndexOf("/");
      dataObject.formatEncoding =
        dataObject.format.substring(encodingSeparator);
      dataObject.format = dataObject.format.substring(0, encodingSeparator);
    }

    for (let channel in mediaXfer) {
      if (channel.search(/image|ebook|ebookSearch/) == -1) {
        dataObject.mediaChannels[channel] = mediaXfer[channel];
      }
    }

    return dataObject;
  },

  methods: {
    /**
     * The data format has changed
     */
    formatChanged: function () {
      this.dataChannel.format = this.format + this.formatEncoding;
      if (this.channelErrors == 0) {
        this.$emit("change", this.dataChannel, this.mediaChannels);
      } else {
        this.$emit("data-errors");
      }
    },

    /**
     * Data transfer settings have changed
     */
    dataChannelChanged: function (newXfer) {
      if (newXfer !== false) {
        this.dataChannel = newXfer;
        this.dataChannel.format = this.format + this.formatEncoding;
        this.channelErrors &= ~1;
        if (this.channelErrors == 0) {
          this.$emit("change", this.dataChannel, this.mediaChannels);
        } else {
          this.$emit("data-errors");
        }
      } else {
        this.channelErrors |= 1;
        this.$emit("data-errors");
      }
    },

    /**
     * Media transfer channel settings have changed
     */
    mediaChannelChanged: function (channelId, channelMask, newXfer) {
      if (newXfer !== false) {
        this.mediaChannels[channelId] = newXfer;
        this.channelErrors &= ~channelMask;
        if (this.channelErrors == 0) {
          this.$emit("change", this.dataChannel, this.mediaChannels);
        } else {
          this.$emit("data-errors");
        }
      } else {
        this.channelErrors |= channelMask;
        this.$emit("data-errors");
      }
    },
  },
};
</script>
