/**
 * This file imports and includes all page components
 *
 * This file should always be included in the entry file
 *
 * To import this file (and all pages) add:
 * import "./components/pages";
 * to the entry file
 */

import Vue from "vue";

import ReportsMenuPage from "./reports/ReportsMenuPage.vue";
import ReportPage from "./reports/ReportPage.vue";
import BiblioResultsListPage from "./biblio/BiblioResultsListPage.vue";
import BiblioDetailPage from "./biblio/BiblioDetailPage.vue";
import BiblioProductContactsPage from "./biblio/BiblioProductContactsPage.vue";
import ContactsResultsListPage from "./contacts/ContactsResultsListPage.vue";
import ContactDetailPage from "./contacts/ContactDetailPage.vue";
import FtpCataloguesIndexPage from "./ftpCatalogues/FtpCataloguesIndexPage.vue";
import DisseminationSettingsPage from "./configuration/DisseminationSettingsPage.vue";
import ProductContactsGroupsPage from "./configuration/ProductContactsGroupsPage.vue";
// edit components
import ContentItemsEdit from "../biblio/edit/ContentItemsEdit.vue";
// non page component BiblioDetailTab is also includes as it's used as a page on the output action
import BiblioDetailTab from "../biblio/BiblioDetailTab.vue";
import BooksonixWebConsole from "./modals/BooksonixWebConsole.vue";

Vue.component("reports-menu-page", ReportsMenuPage);
Vue.component("report-page", ReportPage);
Vue.component("biblio-results-list-page", BiblioResultsListPage);
Vue.component("biblio-detail-page", BiblioDetailPage);
Vue.component("biblio-productcontacts-page", BiblioProductContactsPage);
Vue.component("contacts-results-list-page", ContactsResultsListPage);
Vue.component("contact-detail-page", ContactDetailPage);
Vue.component("ftp-catalogues-index-page", FtpCataloguesIndexPage);
Vue.component("dissemination-settings-page", DisseminationSettingsPage);
Vue.component("productcontactsgroups-page", ProductContactsGroupsPage);
Vue.component("content-items-edit", ContentItemsEdit);
Vue.component("biblio-detail-tab", BiblioDetailTab);
Vue.component("booksonix-web-console", BooksonixWebConsole);

/**
 * Some components need to be included globally to be used on pages
 * which are not created using Vue.
 */

import ReportsSelect from "../utility/Report/ReportsSelect.vue";
import ReportLinks from "../utility/Report/ReportLinks.vue";
import LoadingSpinner from "../ui/LoadingSpinner.vue";

Vue.component("reports-select", ReportsSelect);
Vue.component("report-links", ReportLinks);
Vue.component("loading-spinner", LoadingSpinner);
