<template>
  <div class="productcontactsgroupspage">
    <b-tabs>
      <b-tab title="Product Contacts Groups">
        <table width="100%" class="table-striped">
          <thead>
            <tr>
              <th class="productcontactsgroups-col-name">Group Name</th>
              <th class="productcontactsgroups-col-cont">Contacts</th>
              <th class="productcontactsgroups-col-edit">
                <a href="#" title="Add Group" @click="editProductGroup(0)">
                  <i class="fa fa-plus" />
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in productContactsGroupsList" :key="group.id">
              <td>{{ group.name }}</td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="buildContactsList(group, 'product')" />
              </td>
              <td class="editbtn">
                <a
                  :id="'productcontactsgroups-product-' + group.id + '-edit'"
                  href="#"
                  title="Edit Group"
                  @click="editProductGroup(group.id)"
                >
                  <i class="fa fa-edit" />
                </a>
                &nbsp;
                <a
                  :id="'productcontactsgroups-product-' + group.id + '-delete'"
                  href="#"
                  title="Delete Group"
                  @click="deleteProductGroup(group.id)"
                >
                  <i class="fa fa-trash" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab title="Supplier Contacts Groups">
        <table width="100%" class="table-striped">
          <thead>
            <tr>
              <th class="productcontactsgroups-col-name">Group Name</th>
              <th class="productcontactsgroups-col-cont">Contacts</th>
              <th class="productcontactsgroups-col-edit">
                <a href="#" title="Add Group" @click="editSupplierGroup(0)">
                  <i class="fa fa-plus" />
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in supplierContactsGroupsList" :key="group.id">
              <td>{{ group.name }}</td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="buildContactsList(group, 'supplier')" />
              </td>
              <td class="editbtn">
                <a
                  :id="'productcontactsgroups-supplier-' + group.id + '-edit'"
                  href="#"
                  title="Edit Group"
                  @click="editSupplierGroup(group.id)"
                >
                  <i class="fa fa-edit" />
                </a>
                &nbsp;
                <a
                  :id="'productcontactsgroups-supplier-' + group.id + '-delete'"
                  href="#"
                  title="Delete Group"
                  @click="deleteSupplierGroup(group.id)"
                >
                  <i class="fa fa-trash" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
    <!-- buttons -->
    <div class="formcontrols productcontactsgroupspage-footer">
      <a
        :href="
          $baseUrl + 'customisation/productcontactsgroups-management?cancel'
        "
        class="btn btn-primary"
      >
        <i class="fa fa-list-ul"></i> Back to Menu
      </a>
    </div>
    <!-- modals -->
    <b-modal
      v-if="editProductModal.group"
      id="edit-product"
      size="xl"
      :title="editProductModal.title"
      no-close-on-backdrop
      static
      lazy
      @ok="editProductModalSave"
    >
      <biblio-productcontacts
        component-id="prod"
        contacts-desc="product"
        :roles="productContactRoles"
        :product-contacts="editProductModal.group.contacts"
        :all-countries="allCountries"
        @changed="
          (data) => {
            editProductModal.formData = data;
            editProductModal.formError = null;
          }
        "
        @error="
          (which) => {
            editProductModal.formError = which;
          }
        "
      >
        <template v-slot:group>
          <div class="col-11 productcontactsgroupspage-modal-group">
            <label for="prod-groupname-edit">Group:</label>
            <input
              id="prod-groupname-edit"
              v-model="editProductModal.group.name"
              type="text"
            />
          </div>
        </template>
      </biblio-productcontacts>
    </b-modal>
    <b-modal
      v-if="editSupplierModal.group"
      id="edit-supplier"
      size="xl"
      :title="editSupplierModal.title"
      no-close-on-backdrop
      static
      lazy
      @ok="editSupplierModalSave"
    >
      <biblio-productcontacts
        component-id="supl"
        contacts-desc="supplier"
        :roles="supplierContactRoles"
        :product-contacts="editSupplierModal.group.contacts"
        :all-countries="allCountries"
        @changed="
          (data) => {
            editSupplierModal.formData = data;
            editSupplierModal.formError = null;
          }
        "
        @error="
          (which) => {
            editSupplierModal.formError = which;
          }
        "
      >
        <template v-slot:group>
          <div class="col-11 productcontactsgroupspage-modal-group">
            <label for="supl-groupname-edit">Group:</label>
            <input
              id="supl-groupname-edit"
              v-model="editSupplierModal.group.name"
              type="text"
            />
          </div>
        </template>
      </biblio-productcontacts>
    </b-modal>
  </div>
</template>

<script>
/**
 * Product & supplier contacts groups page
 */
import BiblioProductContactsEdit from "../../biblio/edit/BiblioProductContactsEdit.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "ProductContactsGroupsPage",

  components: {
    "biblio-productcontacts": BiblioProductContactsEdit,
  },

  props: {
    /**
     * Product Contact Roles
     */
    productContactRoles: {
      type: Array,
      required: true,
    },

    /**
     * Supplier Contact Roles
     */
    supplierContactRoles: {
      type: Array,
      required: true,
    },
    /**
     * Product Contacts Groups
     */
    productContactsGroups: {
      type: Array,
      default: null,
    },

    /**
     * Supplier Contacts Groups
     */
    supplierContactsGroups: {
      type: Array,
      default: null,
    },

    /**
     * The list of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let productContactRolesLookup = {};
    for (let i in this.productContactRoles) {
      productContactRolesLookup[this.productContactRoles[i].code] =
        this.productContactRoles[i].name
          .replace(/</g, "&lt;")
          .replace(/&/g, "&amp;");
    }

    let supplierContactRolesLookup = {};
    for (let i in this.supplierContactRoles) {
      supplierContactRolesLookup[this.supplierContactRoles[i].code] =
        this.supplierContactRoles[i].name
          .replace(/</g, "&lt;")
          .replace(/&/g, "&amp;");
    }

    return {
      productContactRolesLookup: productContactRolesLookup,
      supplierContactRolesLookup: supplierContactRolesLookup,
      productContactsGroupsList: this.productContactsGroups,
      supplierContactsGroupsList: this.supplierContactsGroups,
      editProductModal: {
        title: null,
        group: null,
        formData: null,
        formError: null,
      },
      editSupplierModal: {
        title: null,
        group: null,
        formData: null,
        formError: null,
      },
    };
  },

  methods: {
    /**
     * Build contacts list
     */
    buildContactsList: function (group, which) {
      let roleLookup =
        which == "product"
          ? this.productContactRolesLookup
          : this.supplierContactRolesLookup;
      let list = "";
      for (let i in group.contacts) {
        let contact = group.contacts[i];
        if (list.length != 0) {
          list += "<br>";
        }
        list +=
          roleLookup[contact.role] +
          " : " +
          contact.contact_name.replace(/</g, "&lt;").replace(/&/g, "&amp;") +
          (contact.contact_name2
            ? " <i>(" +
              contact.contact_name2
                .replace(/</g, "&lt;")
                .replace(/&/g, "&amp;") +
              ")</i>"
            : "");
      }

      return list;
    },

    /**
     * Add or edit a product group
     */
    editProductGroup: function (groupId) {
      if (groupId === 0) {
        this.editProductModal.title = "Add product contacts group";
        this.editProductModal.group = { id: 0, name: "", contacts: [] };
        this.$nextTick(() => {
          this.$bvModal.show("edit-product");
        });
        return;
      }

      for (let i in this.productContactsGroupsList) {
        if (this.productContactsGroupsList[i].id == groupId) {
          this.editProductModal.title = "Edit product contacts group";
          this.editProductModal.group = {
            ...this.productContactsGroupsList[i],
          };
          this.$nextTick(() => {
            this.$bvModal.show("edit-product");
          });
          break;
        }
      }
    },

    /**
     * Save product group changes
     */
    editProductModalSave: function (event) {
      // don't close modal
      event.preventDefault();

      // check
      if (this.editProductModal.group.name == "") {
        this.$bvModal.msgBoxOk(
          "You have not entered a name for this product contacts group"
        );
        return;
      }
      if (this.editProductModal.formError === "role") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a contact selected but no role selected"
        );
        return;
      }
      if (this.editProductModal.formError === "contact") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a role selected but no contact selected"
        );
        return;
      }
      if (this.editProductModal.formData == "[]") {
        this.$bvModal.msgBoxOk(
          "You have not entered any contacts for this product contacts group"
        );
        return;
      }

      // save
      let url =
        this.$baseUrl + "customisation/productcontactsgroups-management";
      let postData = new URLSearchParams();
      postData.append("type", "product");
      postData.append("id", this.editProductModal.group.id);
      postData.append("name", this.editProductModal.group.name);
      postData.append("contacts", this.editProductModal.formData);
      HTTP.post(url, postData)
        .then((response) => {
          // changes have been saved
          let groupId = this.editProductModal.group.id;
          if (groupId != 0) {
            for (let i in this.productContactsGroupsListList) {
              if (this.productContactsGroupsList[i].id == groupId) {
                this.productContactsGroupsList[i] = response.data;
                break;
              }
            }
          } else {
            this.productContactsGroupsList.push(response.data);
          }
          this.$nextTick(() => {
            this.$bvModal.hide("edit-product");
            this.editProductModal.group = null;
          });
        })
        .catch((error) => {
          if (error.response.data == "NAME-EXISTS") {
            this.$bvModal.msgBoxOk(
              "This group name is already being used by another product contacts group."
            );
          } else {
            this.$bvModal.msgBoxOk(
              "An error has occurred while saving your changes."
            );
          }
        });
    },

    /**
     * Delete a product group
     */
    deleteProductGroup: function (groupId) {
      for (let i in this.productContactsGroupsList) {
        if (this.productContactsGroupsList[i].id == groupId) {
          this.$bvModal
            .msgBoxConfirm(
              "Do you want to delete the product contacts group '" +
                this.productContactsGroupsList[i].name
                  .replace(/</g, "&lt;")
                  .replace(/&/g, "&amp;") +
                "' ?"
            )
            .then((value) => {
              if (value) {
                let url =
                  this.$baseUrl +
                  "customisation/productcontactsgroups-management?product=" +
                  groupId;
                HTTP.delete(url)
                  .then(() => {
                    this.productContactsGroupsList.splice(i, 1);
                  })
                  .catch((error) => {
                    if (error.response.data == "INUSE") {
                      this.$bvModal.msgBoxOk(
                        "This product contact group cannot be deleted because it is being used by product record(s)."
                      );
                    } else {
                      this.$bvModal.msgBoxOk(
                        "An error has occurred while deleting this product contacts group."
                      );
                    }
                  });
              }
            });
          break;
        }
      }
    },

    /**
     * Add or edit a supplier group
     */
    editSupplierGroup: function (groupId) {
      if (groupId === 0) {
        this.editSupplierModal.title = "Add supplier contacts group";
        this.editSupplierModal.group = { id: 0, name: "", contacts: [] };
        this.$nextTick(() => {
          this.$bvModal.show("edit-supplier");
        });
        return;
      }

      for (let i in this.supplierContactsGroupsList) {
        if (this.supplierContactsGroupsList[i].id == groupId) {
          this.editSupplierModal.title = "Edit supplier contacts group";
          this.editSupplierModal.group = {
            ...this.supplierContactsGroupsList[i],
          };
          this.$nextTick(() => {
            this.$bvModal.show("edit-supplier");
          });
          break;
        }
      }
    },

    /**
     * Save supplier group changes
     */
    editSupplierModalSave: function (event) {
      // don't close modal
      event.preventDefault();

      // check
      if (this.editSupplierModal.group.name == "") {
        this.$bvModal.msgBoxOk(
          "You have not entered a name for this product contacts group"
        );
        return;
      }
      if (this.editSupplierModal.formError === "role") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a contact selected but no role selected"
        );
        return;
      }
      if (this.editSupplierModal.formError === "contact") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a role selected but no contact selected"
        );
        return;
      }
      if (this.editSupplierModal.formData == "[]") {
        this.$bvModal.msgBoxOk(
          "You have not entered any contacts for this product contacts group"
        );
        return;
      }

      // save
      let url =
        this.$baseUrl + "customisation/productcontactsgroups-management";
      let postData = new URLSearchParams();
      postData.append("type", "supplier");
      postData.append("id", this.editSupplierModal.group.id);
      postData.append("name", this.editSupplierModal.group.name);
      postData.append("contacts", this.editSupplierModal.formData);
      HTTP.post(url, postData)
        .then((response) => {
          // changes have been saved
          let groupId = this.editSupplierModal.group.id;
          if (groupId != 0) {
            for (let i in this.supplierContactsGroupsList) {
              if (this.supplierContactsGroupsList[i].id == groupId) {
                this.supplierContactsGroupsList[i] = response.data;
                break;
              }
            }
          } else {
            this.supplierContactsGroupsList.push(response.data);
          }
          this.$nextTick(() => {
            this.$bvModal.hide("edit-supplier");
            this.editSupplierModal.group = null;
          });
        })
        .catch((error) => {
          if (error.response.data == "NAME-EXISTS") {
            this.$bvModal.msgBoxOk(
              "This group name is already being used by another supplier contacts group."
            );
          } else {
            this.$bvModal.msgBoxOk(
              "An error has occurred while saving your changes."
            );
          }
        });
    },

    /**
     * Delete a supplier group
     */
    deleteSupplierGroup: function (groupId) {
      for (let i in this.supplierContactsGroupsList) {
        if (this.supplierContactsGroupsList[i].id == groupId) {
          this.$bvModal
            .msgBoxConfirm(
              "Do you want to delete the supplier contacts group '" +
                this.supplierContactsGroupsList[i].name
                  .replace(/</g, "&lt;")
                  .replace(/&/g, "&amp;") +
                "' ?"
            )
            .then((value) => {
              if (value) {
                let url =
                  this.$baseUrl +
                  "customisation/productcontactsgroups-management?supplier=" +
                  groupId;
                HTTP.delete(url)
                  .then(() => {
                    this.supplierContactsGroupsList.splice(i, 1);
                  })
                  .catch((error) => {
                    if (error.response.data == "INUSE") {
                      this.$bvModal.msgBoxOk(
                        "This supplier contact group cannot be deleted because it is being used by product record(s)."
                      );
                    } else {
                      this.$bvModal.msgBoxOk(
                        "An error has occurred while deleting this supplier contacts group."
                      );
                    }
                  });
              }
            });
          break;
        }
      }
    },
  },
};
</script>

<style>
div.productcontactsgroupspage {
  margin-top: 2rem;
}
div.productcontactsgroupspage th {
  text-align: left;
  padding: 0.25rem;
}
div.productcontactsgroupspage td {
  padding: 0.25rem;
  vertical-align: top;
}
div.productcontactsgroupspage td div {
  max-height: 40px;
  overflow-y: hidden;
}
div.productcontactsgroupspage-footer {
  margin-top: 2rem;
}
.productcontactsgroups-col-name {
  width: 25%;
}
.productcontactsgroups-col-cont {
  width: 70%;
}
.productcontactsgroups-col-edit {
  width: 5%;
  text-align: right;
}
div.productcontactsgroupspage-modal-group label {
  padding-right: 0.5rem;
}
div.productcontactsgroupspage-modal-group input {
  width: 30%;
}
</style>
