<template>
  <div :class="rowClass">
    <div v-if="caption" class="col-3 mt-2 pr-0 caption">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <label v-html="captionHtml" />
      <select
        v-if="allowExcept"
        v-model="currentExcept"
        size="1"
        @change="listModified"
      >
        <option value="N">(these only)</option>
        <option value="Y">(all except)</option>
      </select>
    </div>
    <div :class="selectorClass">
      <v-select
        v-model="currentItems"
        label="name"
        :options="allItemsList"
        :get-option-key="(o) => o.code"
        :multiple="multiple"
        :close-on-select="!multiple"
        :selectable="(o) => o.code"
        :placeholder="'Select ' + itemType + ' or start typing'"
        @input="listModified"
      >
        <template #option="option">
          <div v-if="!option.code" class="list-groupitem">
            {{ option.name }}
          </div>
          <div v-else-if="allItemsGrouped" class="list-item-indent">
            {{ option.name }}
          </div>
          <div v-else class="list-item">
            {{ option.name }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings accepted items code list component
 */
import vSelect from "vue-select";

export default {
  name: "AcceptedItemsCodeList",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * Caption
     */
    caption: {
      type: String,
      default: null,
    },

    /**
     * Item type
     */
    itemType: {
      type: String,
      default: "item",
    },

    /**
     * Current items list (multiple items mode)
     */
    items: {
      type: Array,
      default: null,
    },

    /**
     * Current item (single item mode)
     */
    item: {
      type: [Number, String],
      default: null,
    },

    /**
     * Except items
     */
    except: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow multiple items
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * List of all items; each item is an object with the properties code and name
     */
    allItems: {
      type: Array,
      required: true,
    },

    /**
     * All items list is grouped
     */
    allItemsGrouped: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow except
     */
    allowExcept: {
      type: Boolean,
      default: true,
    },

    /**
     * Row class
     */
    rowClass: {
      type: String,
      default: "row",
    },

    /**
     * Selector Class
     */
    selectorClass: {
      type: String,
      default: "col-9 mt-2",
    },
  },

  data: function () {
    let dataObject = {
      allItemsList: [],
      currentItems: this.multiple ? [] : null,
      currentExcept: this.except ? "Y" : "N",
    };

    if (this.allItemsGrouped) {
      for (const groupKey in this.allItems) {
        let group = this.allItems[groupKey];
        dataObject.allItemsList.push({ code: null, name: group.code });
        for (const itemKey in group.name) {
          dataObject.allItemsList.push(group.name[itemKey]);
        }
      }
    } else {
      dataObject.allItemsList = [...this.allItems];
    }

    if (this.multiple ? this.items : this.item) {
      let itemsList = this.multiple ? this.items : [this.item];
      for (const itemIndex in itemsList) {
        let item = itemsList[itemIndex];
        // find this item in the allItems list
        for (const allItemIndex in dataObject.allItemsList) {
          let allItem = dataObject.allItemsList[allItemIndex];
          if (allItem.code == item) {
            if (this.multiple) {
              dataObject.currentItems.push(allItem);
            } else {
              dataObject.currentItems = allItem;
            }
            break;
          }
        }
      }
    }
    return dataObject;
  },

  computed: {
    captionHtml: function () {
      return this.caption.replace(/\|/g, "<br />&emsp;");
    },
  },

  methods: {
    /**
     * The list has been modified
     */
    listModified: async function () {
      await this.$nextTick();
      if (this.multiple) {
        let list = [];
        for (const itemIndex in this.currentItems) {
          let item = this.currentItems[itemIndex];
          if (item.code != "") {
            list.push(item.code);
          }
        }
        this.$emit("change", list, this.currentExcept == "Y");
      } else if (this.currentItems) {
        this.$emit("change", this.currentItems.code, this.currentExcept == "Y");
      } else {
        this.$emit("change", null, this.currentExcept == "Y");
      }
    },
  },
};
</script>

<style scoped>
div.caption {
  padding-top: 5px;
}
div.caption select {
  float: right;
}
div.list-groupitem {
  font-weight: bold;
  font-style: italic;
  color: #000;
  padding: 0px 2px;
}
div.list-item {
  padding: 0px 2px;
}
div.list-item-indent {
  padding: 0px 2px;
  padding-left: 1rem;
}
</style>
