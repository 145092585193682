<template>
  <div>
    <b-tabs>
      <b-tab title="Header" active>
        <!-- sender -->
        <div class="row">
          <div class="col-3">Sender</div>
          <div class="col-9">
            <input
              v-model="sender"
              type="text"
              placeholder="Sender name"
              @input="settingChanged"
            />
          </div>
        </div>
        <!-- sender identifier -->
        <div class="row">
          <div class="col-3">Sender Identifier</div>
          <div class="col-3">
            <select
              v-model="senderId.type"
              size="1"
              class="sender-id-type"
              @change="settingChanged"
            >
              <option value="07">SAN</option>
              <option value="06">GLN (EAN)</option>
              <option value="03">DNB publisher identifier</option>
              <option value="04">Börsenverein Verkehrsnummer</option>
              <option value="05">
                German ISBN Agency publisher identifier
              </option>
              <option value="08">MARC organization code</option>
              <option value="10">Centraal Boekhuis Relatie ID</option>
              <option value="13">Fondscode Boekenbank</option>
              <option value="15">Y-tunnus</option>
              <option value="16">ISNI</option>
              <option value="17">PND</option>
              <option value="18">LCCN</option>
              <option value="19">Japanese Publisher identifier</option>
              <option value="20">GKD</option>
              <option value="21">ORCID</option>
              <option value="22">GAPP Publisher Identifier</option>
              <option value="23">VAT Identity Number</option>
              <option value="24">JP Distribution Identifier</option>
              <option value="25">GND</option>
              <option value="26">DUNS</option>
              <option value="27">Ringgold ID</option>
              <option value="28">Identifiant Editeur Electre</option>
              <option value="29">EIDR Party DOI</option>
              <option value="30">Identifiant Marque Electre</option>
              <option value="31">VIAF ID</option>
              <option value="32">FundRef DOI</option>
              <option value="33">BNE CN</option>
              <option value="34">BNF Control Number</option>
              <option value="35">ARK</option>
              <option value="36">
                Nasjonalt autoritetsregister &nbsp;&nbsp;&dagger;3
              </option>
              <option value="01itmsp">
                iTunes Store Provider &nbsp;&nbsp;*
              </option>
              <option value="01ilsacs_ff">
                Ingram Lightning Source ACS Flex Field &nbsp;&nbsp;*
              </option>
            </select>
          </div>
          <div class="col-3">
            <input
              v-model="senderId.value"
              type="text"
              placeholder="Sender Identifier"
              @input="settingChanged"
            />
          </div>
          <div class="col-3 pt-2">
            <input
              :id="'feed' + feedRecipientId + '-senderid-noncomp'"
              v-model="senderId.nonComposite"
              type="checkbox"
              class="sender-id-non-composite"
              @change="settingChanged"
            />
            <label
              :for="'feed' + feedRecipientId + '-senderid-noncomp'"
              title="Use non-composite element (ONIX 2.1 and SAN/GLN only)"
            >
              Use non-composite element
            </label>
          </div>
        </div>
        <!-- sender contact details -->
        <div class="row">
          <div class="col-3">Sender Contact Details</div>
          <div class="col-9">
            <input
              v-model="senderContact.details"
              type="text"
              placeholder="Details which can include name, department and telephone number"
              @input="settingChanged"
            />
          </div>
        </div>
        <!-- sender contact email -->
        <div class="row">
          <div class="col-3">Sender Contact Email</div>
          <div class="col-9">
            <input
              v-model="senderContact.email"
              type="text"
              :pattern="emailAddrPattern"
              placeholder="Email address"
              @input="settingChangedCheck"
            />
          </div>
        </div>
        <hr />
        <!-- recipient -->
        <div class="row">
          <div class="col-3">Recipient</div>
          <div class="col-9">
            <input
              v-model="recipient"
              type="text"
              placeholder="Recipient name"
              @input="settingChanged"
            />
          </div>
        </div>
        <!-- recipient identifier -->
        <div class="row">
          <div class="col-3">Recipient Identifier</div>
          <div class="col-3">
            <select
              v-model="recipientId.type"
              size="1"
              class="sender-id-type"
              @change="settingChanged"
            >
              <option value="07">SAN</option>
              <option value="06">GLN (EAN)</option>
              <option value="03">DNB publisher identifier</option>
              <option value="04">Börsenverein Verkehrsnummer</option>
              <option value="05">
                German ISBN Agency publisher identifier
              </option>
              <option value="08">MARC organization code</option>
              <option value="10">Centraal Boekhuis Relatie ID</option>
              <option value="13">Fondscode Boekenbank</option>
              <option value="15">Y-tunnus</option>
              <option value="16">ISNI</option>
              <option value="17">PND</option>
              <option value="18">LCCN</option>
              <option value="19">Japanese Publisher identifier</option>
              <option value="20">GKD</option>
              <option value="21">ORCID</option>
              <option value="22">GAPP Publisher Identifier</option>
              <option value="23">VAT Identity Number</option>
              <option value="24">JP Distribution Identifier</option>
              <option value="25">GND</option>
              <option value="26">DUNS</option>
              <option value="27">Ringgold ID</option>
              <option value="28">Identifiant Editeur Electre</option>
              <option value="29">EIDR Party DOI</option>
              <option value="30">Identifiant Marque Electre</option>
              <option value="31">VIAF ID</option>
              <option value="32">FundRef DOI</option>
              <option value="33">BNE CN</option>
              <option value="34">BNF Control Number</option>
              <option value="35">ARK</option>
              <option value="36">
                Nasjonalt autoritetsregister &nbsp;&nbsp;&dagger;3
              </option>
            </select>
          </div>
          <div class="col-3">
            <input
              v-model="recipientId.value"
              type="text"
              placeholder="Recipient identifier"
              @input="settingChanged"
            />
          </div>
          <div class="col-3 pt-2">
            <input
              :id="'feed' + recipientId + '-recipientid-noncomp'"
              v-model="recipientId.nonComposite"
              type="checkbox"
              class="sender-id-non-composite"
              @change="settingChanged"
            />
            <label
              :for="'feed' + recipientId + '-recipientid-noncomp'"
              title="Use non-composite element (ONIX 2.1 and SAN/GLN only)"
            >
              Use non-composite element
            </label>
          </div>
        </div>
        <!-- recipient contact details -->
        <div class="row">
          <div class="col-3">Recipient Contact Details</div>
          <div class="col-9">
            <input
              v-model="recipientContact.details"
              type="text"
              placeholder="Details which can include name, department and telephone number"
              @input="settingChanged"
            />
          </div>
        </div>
        <!-- recipient contact email -->
        <div class="row">
          <div class="col-3">Recipient Contact Email</div>
          <div class="col-9">
            <input
              v-model="recipientContact.email"
              type="text"
              :pattern="emailAddrPattern"
              placeholder="Email address"
              @input="settingChangedCheck"
            />
          </div>
        </div>
        <hr />
        <!-- message defaults -->
        <div class="row">
          <div class="col-3">Message Defaults</div>
          <div class="col-3">
            <select
              v-model="messageDefaults.language"
              size="1"
              class="message-defaults"
              @change="settingChanged"
            >
              <option value="">(select default language)</option>
              <option
                v-for="language in languages"
                :key="language.code"
                :value="language.code"
              >
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="col-3">
            <select
              v-model="messageDefaults.priceType"
              size="1"
              class="message-defaults"
              @change="settingChanged"
            >
              <option value="">(select default price type)</option>
              <option
                v-for="priceType in priceTypes"
                :key="priceType.code"
                :value="priceType.code"
              >
                {{ priceType.name }}
              </option>
            </select>
          </div>
          <div class="col-3">
            <select
              v-model="messageDefaults.currency"
              size="1"
              class="message-defaults"
              @change="settingChanged"
            >
              <option value="">(select default currency)</option>
              <optgroup
                v-for="currencyGroup in currencies"
                :key="currencyGroup.code"
                :label="currencyGroup.code"
              >
                <option
                  v-for="currency in currencyGroup.name"
                  :key="currency.code"
                  :value="currency.code"
                >
                  {{ currency.name }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
      </b-tab>
      <b-tab title="Record">
        <!-- imprint identifiers -->
        <div v-if="imprintIdentifiers.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Imprint Identifiers
            <a
              href="#"
              @click.prevent="
                imprintIdentifiers.push({
                  type: '',
                  value: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(imprintIdentifier, index) in imprintIdentifiers"
          :key="'II' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Imprint Identifiers
              <a
                href="#"
                @click.prevent="
                  imprintIdentifiers.push({
                    type: '',
                    value: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <select
              v-model="imprintIdentifier.type"
              size="1"
              style="width: 32%"
              @change="settingChangedCS"
            >
              <option value="">BooksoniX Publisher (proprietary)</option>
              <option value="03">DNB publisher identifier</option>
              <option value="04">Börsenverein Verkehrsnummer</option>
              <option value="05">
                German ISBN Agency publisher identifier
              </option>
              <option value="06">GLN</option>
              <option value="07">SAN</option>
              <option value="08">MARC organization code</option>
              <option value="10">Centraal Boekhuis Relatie ID</option>
              <option value="13">Fondscode Boekenbank</option>
              <option value="15">Y-tunnus</option>
              <option value="16">ISNI</option>
              <option value="17">PND</option>
              <option value="18">LCCN</option>
              <option value="19">Japanese Publisher identifier</option>
              <option value="20">GKD</option>
              <option value="21">ORCID</option>
              <option value="22">GAPP Publisher Identifier</option>
              <option value="23">VAT Identity Number</option>
              <option value="24">JP Distribution Identifier</option>
              <option value="25">GND</option>
              <option value="26">DUNS</option>
              <option value="27">Ringgold ID</option>
              <option value="28">Identifiant Editeur Electre</option>
              <option value="29">EIDR Party DOI</option>
              <option value="30">Identifiant Marque Electre</option>
              <option value="31">VIAF ID</option>
              <option value="32">FundRef DOI</option>
              <option value="33">BNE CN</option>
              <option value="34">BNF Control Number</option>
              <option value="35">ARK</option>
              <option value="36">Nasjonalt autoritetsregister</option>
              <option value="37">GRID</option>
            </select>
            &nbsp;
            <input
              v-model="imprintIdentifier.value"
              type="text"
              style="width: 16%"
              placeholder="Imprint Identifier"
              @input="settingChangedCS"
            />
            &nbsp;when&nbsp;
            <select
              v-model="imprintIdentifier.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <div
              v-show="imprintIdentifier.condition.type == ''"
              class="condition-list"
            >
              &nbsp;
            </div>
            <accepted-items-auth-dyn-list
              v-show="imprintIdentifier.condition.type == 'I'"
              item-type="imprint"
              :item="imprintIdentifier.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  imprintIdentifier.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="imprintIdentifier.condition.type == 'P'"
              item-type="publisher"
              :item="imprintIdentifier.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  imprintIdentifier.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="imprintIdentifier.condition.type == 'F'"
              v-model="imprintIdentifier.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
            &nbsp;
            <a href="#" @click.prevent="deleteItem(imprintIdentifiers, index)">
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a href="#" @click.prevent="moveItemUp(imprintIdentifiers, index)">
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(imprintIdentifiers, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
          </div>
        </div>
        <!-- publisher identifiers -->
        <div v-if="publisherIdentifiers.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Publisher Identifiers
            <a
              href="#"
              @click.prevent="
                publisherIdentifiers.push({
                  type: '',
                  value: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(publisherIdentifier, index) in publisherIdentifiers"
          :key="'PI' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Publisher Identifiers
              <a
                href="#"
                @click.prevent="
                  publisherIdentifiers.push({
                    type: '',
                    value: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <select
              v-model="publisherIdentifier.type"
              size="1"
              style="width: 32%"
              @change="settingChangedCS"
            >
              <option value="">BooksoniX Publisher (proprietary)</option>
              <option value="03">DNB publisher identifier</option>
              <option value="04">Börsenverein Verkehrsnummer</option>
              <option value="05">
                German ISBN Agency publisher identifier
              </option>
              <option value="06">GLN</option>
              <option value="07">SAN</option>
              <option value="08">MARC organization code</option>
              <option value="10">Centraal Boekhuis Relatie ID</option>
              <option value="13">Fondscode Boekenbank</option>
              <option value="15">Y-tunnus</option>
              <option value="16">ISNI</option>
              <option value="17">PND</option>
              <option value="18">LCCN</option>
              <option value="19">Japanese Publisher identifier</option>
              <option value="20">GKD</option>
              <option value="21">ORCID</option>
              <option value="22">GAPP Publisher Identifier</option>
              <option value="23">VAT Identity Number</option>
              <option value="24">JP Distribution Identifier</option>
              <option value="25">GND</option>
              <option value="26">DUNS</option>
              <option value="27">Ringgold ID</option>
              <option value="28">Identifiant Editeur Electre</option>
              <option value="29">EIDR Party DOI</option>
              <option value="30">Identifiant Marque Electre</option>
              <option value="31">VIAF ID</option>
              <option value="32">FundRef DOI</option>
              <option value="33">BNE CN</option>
              <option value="34">BNF Control Number</option>
              <option value="35">ARK</option>
              <option value="36">Nasjonalt autoritetsregister</option>
              <option value="37">GRID</option>
            </select>
            &nbsp;
            <input
              v-model="publisherIdentifier.value"
              type="text"
              style="width: 16%"
              placeholder="Publisher Identifier"
              @input="settingChangedCS"
            />
            &nbsp;when&nbsp;
            <select
              v-model="publisherIdentifier.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <div
              v-show="publisherIdentifier.condition.type == ''"
              class="condition-list"
            >
              &nbsp;
            </div>
            <accepted-items-auth-dyn-list
              v-show="publisherIdentifier.condition.type == 'I'"
              item-type="imprint"
              :item="publisherIdentifier.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  publisherIdentifier.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="publisherIdentifier.condition.type == 'P'"
              item-type="publisher"
              :item="publisherIdentifier.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  publisherIdentifier.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="publisherIdentifier.condition.type == 'F'"
              v-model="publisherIdentifier.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
            &nbsp;
            <a
              href="#"
              @click.prevent="deleteItem(publisherIdentifiers, index)"
            >
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemUp(publisherIdentifiers, index)"
            >
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(publisherIdentifiers, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
          </div>
        </div>
        <!-- proprietary subjects -->
        <div v-if="proprietarySubjects.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Proprietary Subjects
            <a
              href="#"
              @click.prevent="
                proprietarySubjects.push({
                  scheme: '',
                  code: '',
                  heading: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(proprietarySubject, index) in proprietarySubjects"
          :key="'PS' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Proprietary Subjects
              <a
                href="#"
                @click.prevent="
                  proprietarySubjects.push({
                    scheme: '',
                    code: '',
                    heading: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <input
              v-model="proprietarySubject.scheme"
              type="text"
              style="width: 14%"
              placeholder="Scheme Name"
              @input="settingChangedCS"
            />
            &nbsp;
            <input
              v-model="proprietarySubject.code"
              type="text"
              style="width: 14%"
              placeholder="Code"
              @input="settingChangedCS"
            />
            &nbsp;
            <input
              v-model="proprietarySubject.heading"
              type="text"
              style="width: 18%"
              placeholder="Heading"
              @input="settingChangedCS"
            />
            &nbsp;when&nbsp;
            <select
              v-model="proprietarySubject.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <div
              v-show="proprietarySubject.condition.type == ''"
              class="condition-list"
            >
              &nbsp;
            </div>
            <accepted-items-auth-dyn-list
              v-show="proprietarySubject.condition.type == 'I'"
              item-type="imprint"
              :item="proprietarySubject.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  proprietarySubject.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="proprietarySubject.condition.type == 'P'"
              item-type="publisher"
              :item="proprietarySubject.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  proprietarySubject.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="proprietarySubject.condition.type == 'F'"
              v-model="proprietarySubject.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
            &nbsp;
            <a href="#" @click.prevent="deleteItem(proprietarySubjects, index)">
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a href="#" @click.prevent="moveItemUp(proprietarySubjects, index)">
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(proprietarySubjects, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
          </div>
        </div>
        <!-- supplier identifiers -->
        <div v-if="supplierIdentifiers.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Supplier Identifiers
            <a
              href="#"
              @click.prevent="
                supplierIdentifiers.push({
                  type: '',
                  supplier: null,
                  value: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(supplierIdentifier, index) in supplierIdentifiers"
          :key="'SI' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Supplier Identifiers
              <a
                href="#"
                @click.prevent="
                  supplierIdentifiers.push({
                    type: '',
                    supplier: null,
                    value: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <select
              v-model="supplierIdentifier.type"
              size="1"
              style="width: 32%"
              @change="settingChangedCS"
            >
              <option value="">BooksoniX Publisher (proprietary)</option>
              <option value="01:ipsuk.supcode">
                Ingram Publisher Services UK POD Indicator
              </option>
              <option value="03">DNB publisher identifier</option>
              <option value="04">Börsenverein Verkehrsnummer</option>
              <option value="05">
                German ISBN Agency publisher identifier
              </option>
              <option value="06">GLN</option>
              <option value="07">SAN</option>
              <option value="08">MARC organization code</option>
              <option value="10">Centraal Boekhuis Relatie ID</option>
              <option value="13">Fondscode Boekenbank</option>
              <option value="15">Y-tunnus</option>
              <option value="16">ISNI</option>
              <option value="17">PND</option>
              <option value="18">LCCN</option>
              <option value="19">Japanese Publisher identifier</option>
              <option value="20">GKD</option>
              <option value="21">ORCID</option>
              <option value="22">GAPP Publisher Identifier</option>
              <option value="23">VAT Identity Number</option>
              <option value="24">JP Distribution Identifier</option>
              <option value="25">GND</option>
              <option value="26">DUNS</option>
              <option value="27">Ringgold ID</option>
              <option value="28">Identifiant Editeur Electre</option>
              <option value="29">EIDR Party DOI</option>
              <option value="30">Identifiant Marque Electre</option>
              <option value="31">VIAF ID</option>
              <option value="32">FundRef DOI</option>
              <option value="33">BNE CN</option>
              <option value="34">BNF Control Number</option>
              <option value="35">ARK</option>
              <option value="36">Nasjonalt autoritetsregister</option>
              <option value="37">GRID</option>
            </select>
            &nbsp;
            <input
              v-model="supplierIdentifier.value"
              type="text"
              style="width: 16%"
              placeholder="Supplier Identifier"
              @input="settingChangedCS"
            />
            &nbsp;for&nbsp;
            <accepted-items-auth-dyn-list
              item-type="supplier"
              :item="supplierIdentifier.supplier"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/suppliers"
              :allow-except="false"
              row-class="condition-suppier-list-si"
              selector-class=""
              @change="
                (id) => {
                  supplierIdentifier.supplier = id;
                  settingChangedCS();
                }
              "
            />
            &nbsp;
            <a href="#" @click.prevent="deleteItem(supplierIdentifiers, index)">
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a href="#" @click.prevent="moveItemUp(supplierIdentifiers, index)">
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(supplierIdentifiers, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
            <br />when&nbsp;
            <select
              v-model="supplierIdentifier.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <accepted-items-auth-dyn-list
              v-show="supplierIdentifier.condition.type == 'I'"
              item-type="imprint"
              :item="supplierIdentifier.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  supplierIdentifier.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="supplierIdentifier.condition.type == 'P'"
              item-type="publisher"
              :item="supplierIdentifier.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  supplierIdentifier.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="supplierIdentifier.condition.type == 'F'"
              v-model="supplierIdentifier.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
          </div>
        </div>
        <!-- distributor discounts -->
        <div v-if="distributorDiscounts.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Distributor Discounts
            <a
              href="#"
              @click.prevent="
                distributorDiscounts.push({
                  value: '',
                  supplier: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(distributorDiscount, index) in distributorDiscounts"
          :key="'DD' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Distributor Discounts
              <a
                href="#"
                @click.prevent="
                  distributorDiscounts.push({
                    value: '',
                    supplier: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <input
              v-model="distributorDiscount.value"
              type="text"
              style="width: 10%"
              placeholder="Discount"
              pattern="[1-9]?[0-9](\.[0-9]+)?"
              @input="settingChangedCheckCS"
            />
            %&nbsp;for&nbsp;
            <accepted-items-auth-dyn-list
              item-type="supplier"
              :item="distributorDiscount.supplier"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/suppliers"
              :allow-except="false"
              row-class="condition-suppier-list-dd"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscount.supplier = id;
                  settingChangedCS();
                }
              "
            />
            &nbsp;when&nbsp;
            <select
              v-model="distributorDiscount.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <div
              v-show="distributorDiscount.condition.type == ''"
              class="condition-list"
            >
              &nbsp;
            </div>
            <accepted-items-auth-dyn-list
              v-show="distributorDiscount.condition.type == 'I'"
              item-type="imprint"
              :item="distributorDiscount.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscount.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="distributorDiscount.condition.type == 'P'"
              item-type="publisher"
              :item="distributorDiscount.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscount.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="distributorDiscount.condition.type == 'F'"
              v-model="distributorDiscount.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
            &nbsp;
            <a
              href="#"
              @click.prevent="deleteItem(distributorDiscounts, index)"
            >
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemUp(distributorDiscounts, index)"
            >
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(distributorDiscounts, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
          </div>
        </div>
        <!-- distributor discount codes -->
        <div v-if="distributorDiscountCodes.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Distributor Discount Codes
            <a
              href="#"
              @click.prevent="
                distributorDiscountCodes.push({
                  type: '',
                  value: '',
                  supplier: '',
                  condition: {
                    type: '',
                    imprint: null,
                    publisher: null,
                    format: '',
                  },
                })
              "
            >
              <i class="fa fa-add" />
            </a>
          </div>
        </div>
        <div
          v-for="(distributorDiscountCode, index) in distributorDiscountCodes"
          :key="'DC' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Distributor Discount Codes
              <a
                href="#"
                @click.prevent="
                  distributorDiscountCodes.push({
                    type: '',
                    value: '',
                    supplier: '',
                    condition: {
                      type: '',
                      imprint: null,
                      publisher: null,
                      format: '',
                    },
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
            </template>
          </div>
          <div class="col-9">
            <select
              v-model="distributorDiscountCode.type"
              size="1"
              style="width: 32%"
              @change="settingChangedCS"
            >
              <option
                v-for="(discountScheme, dsIndex) in discountSchemesList"
                :key="'DC-D' + dsIndex"
                :value="discountScheme.code"
              >
                {{ discountScheme.name }}
              </option>
            </select>
            &nbsp;
            <input
              v-model="distributorDiscountCode.value"
              type="text"
              style="width: 16%"
              placeholder="Discount code"
              @input="settingChangedCS"
            />
            &nbsp;for&nbsp;
            <accepted-items-auth-dyn-list
              item-type="supplier"
              :item="distributorDiscountCode.supplier"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/suppliers"
              :allow-except="false"
              row-class="condition-suppier-list-dc"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscountCode.supplier = id;
                  settingChangedCS();
                }
              "
            />
            &nbsp;
            <a
              href="#"
              @click.prevent="deleteItem(distributorDiscountCodes, index)"
            >
              <i class="fa fa-trash" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemUp(distributorDiscountCodes, index)"
            >
              <i class="fa fa-angle-up" />
            </a>
            &nbsp;
            <a
              href="#"
              @click.prevent="moveItemDown(distributorDiscountCodes, index)"
            >
              <i class="fa fa-angle-down" />
            </a>
            <br />when&nbsp;
            <select
              v-model="distributorDiscountCode.condition.type"
              size="1"
              style="width: 10%"
              @change="settingChangedCS"
            >
              <option value="">(any)</option>
              <option value="I">Imprint</option>
              <option value="P">Publisher</option>
              <option value="F">Format</option>
            </select>
            <accepted-items-auth-dyn-list
              v-show="distributorDiscountCode.condition.type == 'I'"
              item-type="imprint"
              :item="distributorDiscountCode.condition.imprint"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/imprints"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscountCode.condition.imprint = id;
                  settingChangedCS();
                }
              "
            />
            <accepted-items-auth-dyn-list
              v-show="distributorDiscountCode.condition.type == 'P'"
              item-type="publisher"
              :item="distributorDiscountCode.condition.publisher"
              :multiple="false"
              all-items-url="customisation/dissemination-settings/publishers"
              :allow-except="false"
              row-class="condition-list"
              selector-class=""
              @change="
                (id) => {
                  distributorDiscountCode.condition.publisher = id;
                  settingChangedCS();
                }
              "
            />
            <select
              v-show="distributorDiscountCode.condition.type == 'F'"
              v-model="distributorDiscountCode.condition.format"
              size="1"
              class="condition-list"
              @change="settingChangedCS"
            >
              <option value="">Any Format</option>
              <option value="BK">Any Book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^B/) == 0"
                  :key="'II-F' + format.code"
                  :value="'BK' + format.code"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="EB">Any E-book</option>
              <template v-for="format in formats">
                <option
                  v-if="format.code.search(/^ED\./) == 0"
                  :key="'II-F' + format.code.substring(3)"
                  :value="'EB' + format.code.substring(3)"
                >
                  {{ "\u2003" + format.name }}
                </option>
              </template>
              <option value="OL">Online Resource</option>
              <option value="AV">Audio/Video/Digital</option>
              <option value="PS">Point of Sale</option>
              <option value="MM">Mixed Media</option>
              <option value="XX">Other Formats</option>
            </select>
          </div>
        </div>
        <!-- included suppliers -->
        <accepted-items-auth-dyn-list
          caption="Included Suppliers"
          item-type="supplier(s)"
          :items="includedSuppliers"
          :except="includedSuppliersExcept"
          all-items-url="customisation/dissemination-settings/suppliers"
          @change="
            (list, except) => {
              includedSuppliers = list;
              includedSuppliersExcept = except;
              settingChangedCS();
            }
          "
        />
        <!-- included prices -->
        <accepted-items-code-list
          caption="Included Prices"
          item-type="currency(ies)"
          :items="includedPrices"
          :except="includedPricesExcept"
          :all-items="currencies"
          :all-items-grouped="true"
          @change="
            (list, except) => {
              includedPrices = list;
              includedPricesExcept = except;
              settingChangedCS();
            }
          "
        />
        <!-- included market representatives -->
        <accepted-items-auth-dyn-list
          caption="Included Market|Representatives"
          item-type="representative(s)"
          :items="includedMarketRepresentatives"
          :except="includedMarketRepresentativesExcept"
          all-items-url="customisation/dissemination-settings/suppliers"
          @change="
            (list, except) => {
              includedMarketRepresentatives = list;
              includedMarketRepresentativesExcept = except;
              settingChangedCS();
            }
          "
        />
        <!-- Preferred full-text language/dialect -->
        <accepted-items-code-list
          caption="Preferred full-text language/dialect"
          item-type="language/dialect"
          :item="preferredLanguage"
          :all-items="languagesDialects"
          :multiple="false"
          :allow-except="false"
          @change="
            (lang) => {
              preferredLanguage = lang;
              settingChangedCS();
            }
          "
        />
        <!-- Price Tiers -->
        <div v-if="priceTiers.length == 0" class="row">
          <div class="col-3 pr-0 identifier-add">
            Price Tiers
            <a
              href="#"
              @click.prevent="
                priceTiers.push({
                  currency: '',
                  tiers: [],
                })
              "
            >
              <i class="fa fa-add" />
            </a>
            <a
              href="https://docs.google.com/document/d/1vM0XOQtpHnbsBeVzIiH6aVG-7gKpN3wbhKuVPWmYzoE/preview#heading=h.10whd1bifyjr"
              target="Help"
              title="Click here for more information"
              style="padding-right: 5px"
            >
              <i class="fa-regular fa-circle-question" />
            </a>
          </div>
        </div>
        <div
          v-for="(priceTier, index) in priceTiers"
          :key="'PT' + index"
          class="row"
        >
          <div class="col-3 pr-0 identifier-add">
            <template v-if="index == 0">
              Price Tiers
              <a
                href="#"
                @click.prevent="
                  priceTiers.push({
                    currency: '',
                    tiers: [],
                  })
                "
              >
                <i class="fa fa-add" />
              </a>
              <a
                href="https://docs.google.com/document/d/1vM0XOQtpHnbsBeVzIiH6aVG-7gKpN3wbhKuVPWmYzoE/preview#heading=h.10whd1bifyjr"
                target="Help"
                title="Click here for more information"
                style="padding-right: 5px"
              >
                <i class="fa-regular fa-circle-question" />
              </a>
            </template>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-4 pr-0">
                <select
                  v-model="priceTier.currency"
                  size="1"
                  class="pricetier-currency"
                  @change="settingChangedCS"
                >
                  <optgroup
                    v-for="currencyGroup in currencies"
                    :key="currencyGroup.code"
                    :label="currencyGroup.code"
                  >
                    <option
                      v-for="currency in currencyGroup.name"
                      :key="currency.code"
                      :value="currency.code"
                    >
                      {{ currency.name }}
                    </option>
                  </optgroup>
                </select>
                &nbsp;
                <a
                  href="#"
                  @click.prevent="
                    priceTier.tiers.push({
                      id: '',
                      lo: '',
                      hi: '',
                    })
                  "
                >
                  <i class="fa fa-add" />
                </a>
              </div>
              <div
                v-for="(tier, tIndex) in priceTier.tiers"
                :key="'PT' + index + '-T' + tIndex"
                class="col-4 pr-0"
              >
                <input
                  v-model="tier.id"
                  type="text"
                  class="pricetier-tier-id"
                  placeholder="Tier id"
                  required
                  pattern="[a-zA-Z0-9]+"
                  @input="settingChangedCheckCS"
                />
                :
                <input
                  v-model="tier.lo"
                  type="text"
                  class="pricetier-tier-value"
                  placeholder="Low"
                  required
                  pattern="([1-9][0-9]*|0)(\.[0-9]+)?"
                  @input="settingChangedCheckCS"
                />
                <input
                  v-model="tier.hi"
                  type="text"
                  class="pricetier-tier-value"
                  placeholder="High"
                  pattern="([1-9][0-9]*|0)(\.[0-9]+)?"
                  @input="settingChangedCheckCS"
                />
                &nbsp;
                <a
                  href="#"
                  @click.prevent="deleteItem(priceTier.tiers, tIndex)"
                >
                  <i class="fa fa-close" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-1">
            <a href="#" @click.prevent="deleteItem(priceTiers, index)">
              <i class="fa fa-trash" />
            </a>
          </div>
        </div>
        <!-- Alternate Publication Date -->
        <div class="row">
          <div class="col-3">Alternate Publication Date</div>
          <div class="col-3">
            <select
              v-model="alternatePubDate.type"
              size="1"
              @change="settingChangedCS"
            >
              <option value="">None</option>
              <option value="S">Supplier (On-Sale date)</option>
              <option value="M">Sales Agent (Market Representation)</option>
            </select>
          </div>
          <accepted-items-auth-dyn-list
            v-if="alternatePubDate.type != ''"
            item-type="supplier"
            :item="alternatePubDate.value"
            :multiple="false"
            all-items-url="customisation/dissemination-settings/suppliers"
            :allow-except="false"
            row-class="col-3"
            selector-class=""
            @change="
              (id) => {
                alternatePubDate.value = id;
                settingChangedCS();
              }
            "
          />
        </div>
        <!-- Data-Only Image Filename -->
        <div class="row">
          <div class="col-3">Data-Only Image Filename</div>
          <div class="col-3">
            <input
              :id="'feed' + feedRecipientId + '-do-image-filename'"
              ref="imageFilename"
              v-model="imageFilename"
              type="text"
              @input="settingChangedCS"
            />
          </div>
          <div class="col-6 pt-1">
            insert:
            <a
              href="#"
              title="Associated record's Publisher Unique ID value"
              @click.prevent="imageFilenameInsertText('$PUBID$')"
            >
              Publisher-ID
            </a>
            &nbsp;
            <a
              href="#"
              title="Associated record's EAN value"
              @click.prevent="imageFilenameInsertText('$EAN$')"
            >
              EAN
            </a>
            &nbsp;
            <a
              href="#"
              title="Associated record's ISBN-13 value"
              @click.prevent="imageFilenameInsertText('$ISBN13$')"
            >
              ISBN-13
            </a>
            &nbsp;
            <a
              href="#"
              title="Associated record's ISBN-10 value"
              @click.prevent="imageFilenameInsertText('$ISBN10$')"
            >
              ISBN-10
            </a>
            &nbsp;
            <a
              href="#"
              title="Associated record's UPC value"
              @click.prevent="imageFilenameInsertText('$UPC$')"
            >
              UPC
            </a>
            &nbsp;
            <a
              href="#"
              title="Associated record's Textual Work ID value"
              @click.prevent="imageFilenameInsertText('$TXTWORKID$')"
            >
              Work-ID
            </a>
            &nbsp;
            <a
              href="#"
              title="Image file's extension"
              @click.prevent="imageFilenameInsertText('$EXT$')"
            >
              Extension
            </a>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings data settings component
 */
import AcceptedItemsAuthDynList from "./AcceptedItemsAuthDynList.vue";
import AcceptedItemsCodeList from "./AcceptedItemsCodeList.vue";

export default {
  name: "DataSettings",

  components: {
    "accepted-items-auth-dyn-list": AcceptedItemsAuthDynList,
    "accepted-items-code-list": AcceptedItemsCodeList,
  },

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Data settings object
     */
    dataSettings: {
      type: Object,
      default: null,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * List of all currencies
     */
    currencies: {
      type: Array,
      required: true,
    },

    /**
     * List of all price types
     */
    priceTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all discount schemes
     */
    discountSchemes: {
      type: Array,
      required: true,
    },

    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let dataSettings = this.dataSettings ? this.dataSettings : {};
    let senderId = "senderId" in dataSettings ? dataSettings.senderId : {};
    let senderContact =
      "senderContact" in dataSettings ? dataSettings.senderContact : {};
    let recipientId =
      "recipientId" in dataSettings ? dataSettings.recipientId : {};
    let recipientContact =
      "recipientContact" in dataSettings ? dataSettings.recipientContact : {};
    let messageDefaults =
      "messageDefaults" in dataSettings ? dataSettings.messageDefaults : {};

    let dataObject = {
      sender: dataSettings.sender ?? "",
      senderId: {
        type: senderId.type ?? "07",
        value: senderId.value ?? "",
        nonComposite: senderId.nonComposite ?? false,
      },
      senderContact: {
        details: senderContact.details ?? "",
        email: senderContact.email ?? "",
      },
      recipient: dataSettings.recipient ?? "",
      recipientId: {
        type: recipientId.type ?? "07",
        value: recipientId.value ?? "",
        nonComposite: recipientId.nonComposite ?? false,
      },
      recipientContact: {
        details: recipientContact.details ?? "",
        email: recipientContact.email ?? "",
      },
      messageDefaults: {
        language: messageDefaults.language ?? "",
        priceType: messageDefaults.priceType ?? "",
        currency: messageDefaults.currency ?? "",
      },
      imprintIdentifiers: [],
      publisherIdentifiers: [],
      proprietarySubjects: [],
      supplierIdentifiers: [],
      distributorDiscounts: [],
      distributorDiscountCodes: [],
      includedSuppliers: dataSettings.includedSuppliers ?? [],
      includedSuppliersExcept: dataSettings.includedSuppliers_except ?? false,
      includedPrices: dataSettings.includedPrices ?? [],
      includedPricesExcept: dataSettings.includedPrices_except ?? false,
      includedMarketRepresentatives:
        dataSettings.includedMarketRepresentatives ?? [],
      includedMarketRepresentativesExcept:
        dataSettings.includedMarketRepresentatives_except ?? false,
      preferredLanguage: dataSettings.preferredLanguage ?? null,
      priceTiers: [],
      alternatePubDate: {
        type: "",
        value: "",
      },
      imageFilename: dataSettings.imageFilename ?? "",

      dataErrors: 0,
      discountSchemesList: [
        { code: "", name: "(select discount scheme)" },
        ...this.discountSchemes,
      ],
      languagesDialects: [],
    };

    // imprint identifiers
    if ("imprintIdentifiers" in dataSettings) {
      for (const index in dataSettings.imprintIdentifiers) {
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.imprintIdentifiers[index]) {
          switch (dataSettings.imprintIdentifiers[index].condition.type ?? "") {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.imprintIdentifiers[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.imprintIdentifiers[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.imprintIdentifiers[index].condition.value;
              break;
          }
        }
        dataObject.imprintIdentifiers.push({
          type: dataSettings.imprintIdentifiers[index].type,
          value: dataSettings.imprintIdentifiers[index].value,
          condition: condition,
        });
      }
    }

    // publisher identifiers
    if ("publisherIdentifiers" in dataSettings) {
      for (const index in dataSettings.publisherIdentifiers) {
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.publisherIdentifiers[index]) {
          switch (
            dataSettings.publisherIdentifiers[index].condition.type ??
            ""
          ) {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.publisherIdentifiers[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.publisherIdentifiers[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.publisherIdentifiers[index].condition.value;
              break;
          }
        }
        dataObject.publisherIdentifiers.push({
          type: dataSettings.publisherIdentifiers[index].type,
          value: dataSettings.publisherIdentifiers[index].value,
          condition: condition,
        });
      }
    }

    // proprietary subjects
    if ("proprietarySubjects" in dataSettings) {
      for (const index in dataSettings.proprietarySubjects) {
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.proprietarySubjects[index]) {
          switch (
            dataSettings.proprietarySubjects[index].condition.type ??
            ""
          ) {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.proprietarySubjects[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.proprietarySubjects[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.proprietarySubjects[index].condition.value;
              break;
          }
        }
        dataObject.proprietarySubjects.push({
          scheme: dataSettings.proprietarySubjects[index].scheme,
          code: dataSettings.proprietarySubjects[index].code,
          heading: dataSettings.proprietarySubjects[index].heading,
          condition: condition,
        });
      }
    }

    // supplier identifiers
    if ("supplierIdentifiers" in dataSettings) {
      for (const index in dataSettings.supplierIdentifiers) {
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.supplierIdentifiers[index]) {
          switch (
            dataSettings.supplierIdentifiers[index].condition.type ??
            ""
          ) {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.supplierIdentifiers[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.supplierIdentifiers[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.supplierIdentifiers[index].condition.value;
              break;
          }
        }
        dataObject.supplierIdentifiers.push({
          type: dataSettings.supplierIdentifiers[index].type,
          value: dataSettings.supplierIdentifiers[index].value,
          supplier: dataSettings.supplierIdentifiers[index].supplier ?? null,
          condition: condition,
        });
      }
    }

    // distributor discounts
    if ("distributorDiscounts" in dataSettings) {
      for (const index in dataSettings.distributorDiscounts) {
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.distributorDiscounts[index]) {
          switch (
            dataSettings.distributorDiscounts[index].condition.type ??
            ""
          ) {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.distributorDiscounts[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.distributorDiscounts[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.distributorDiscounts[index].condition.value;
              break;
          }
        }
        dataObject.distributorDiscounts.push({
          value: dataSettings.distributorDiscounts[index].value,
          supplier: dataSettings.distributorDiscounts[index].supplier ?? null,
          condition: condition,
        });
      }
    }

    // distributor discount codes
    if ("distributorDiscountCodes" in dataSettings) {
      for (const index in dataSettings.distributorDiscountCodes) {
        let discountCodeType =
          dataSettings.distributorDiscountCodes[index].type;
        let discountCodeTypeKnown = false;
        for (const discountSchemeIndex in dataObject.discountSchemesList) {
          if (
            dataObject.discountSchemesList[discountSchemeIndex].code ==
            discountCodeType
          ) {
            discountCodeTypeKnown = true;
            break;
          }
        }
        if (!discountCodeTypeKnown) {
          dataObject.discountSchemesList.push({
            code: discountCodeType,
            name: discountCodeType.substring(2),
          });
        }
        let condition = {
          type: "",
          imprint: null,
          publisher: null,
          format: "",
        };
        if ("condition" in dataSettings.distributorDiscountCodes[index]) {
          switch (
            dataSettings.distributorDiscountCodes[index].condition.type ??
            ""
          ) {
            case "IMPRINT":
              condition.type = "I";
              condition.imprint =
                dataSettings.distributorDiscountCodes[index].condition.value;
              break;
            case "PUBLISHER":
              condition.type = "P";
              condition.publisher =
                dataSettings.distributorDiscountCodes[index].condition.value;
              break;
            case "FORMAT":
              condition.type = "F";
              condition.format =
                dataSettings.distributorDiscountCodes[index].condition.value;
              break;
          }
        }
        dataObject.distributorDiscountCodes.push({
          type: discountCodeType,
          value: dataSettings.distributorDiscountCodes[index].value,
          supplier:
            dataSettings.distributorDiscountCodes[index].supplier ?? null,
          condition: condition,
        });
      }
    }

    // price tiers
    if ("priceTiers" in dataSettings) {
      for (const index in dataSettings.priceTiers) {
        let tiers = [];
        for (const itemIndex in dataSettings.priceTiers[index].tiers) {
          let item = dataSettings.priceTiers[index].tiers[itemIndex];
          tiers.push(item.id + "=" + item.lo + "~" + item.hi);
        }
        dataObject.priceTiers.push({
          currency: dataSettings.priceTiers[index].currency,
          tiers: tiers.join("\n"),
        });
      }
    }

    // alternatePubDate
    if ("alternatePubDate" in dataSettings) {
      if ("supplier" in dataSettings.alternatePubDate) {
        dataObject.alternatePubDate.type = "S";
        dataObject.alternatePubDate.value =
          dataSettings.alternatePubDate.supplier;
      } else if ("marketRepresentative" in dataSettings.alternatePubDate) {
        dataObject.alternatePubDate.type = "M";
        dataObject.alternatePubDate.value =
          dataSettings.alternatePubDate.marketRepresentative;
      }
    }

    // languages and dialects
    for (let i in this.languages) {
      dataObject.languagesDialects.push(this.languages[i]);
      if (this.languages[i].code == "eng") {
        let langName = this.languages[i].name;
        dataObject.languagesDialects.push({
          code: "eng-AU",
          name: "\u2003" + langName + " (Australia)",
        });
        dataObject.languagesDialects.push({
          code: "eng-CA",
          name: "\u2003" + langName + " (Canada)",
        });
        dataObject.languagesDialects.push({
          code: "eng-IE",
          name: "\u2003" + langName + " (Ireland)",
        });
        dataObject.languagesDialects.push({
          code: "eng-NZ",
          name: "\u2003" + langName + " (New Zealand)",
        });
        dataObject.languagesDialects.push({
          code: "eng-GB",
          name: "\u2003" + langName + " (United Kingdom)",
        });
        dataObject.languagesDialects.push({
          code: "eng-US",
          name: "\u2003" + langName + " (United States)",
        });
      } else if (this.languages[i].code == "fre") {
        let langName = this.languages[i].name;
        dataObject.languagesDialects.push({
          code: "fre-AU",
          name: "\u2003" + langName + " (Canada)",
        });
        dataObject.languagesDialects.push({
          code: "fre-FR",
          name: "\u2003" + langName + " (France)",
        });
      }
    }

    return dataObject;
  },

  computed: {
    emailAddrPattern: function () {
      // reg exp pattern for a single email address (no display name)
      // see SO question 429 for the structure of this pattern
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      return "\\s*([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])\\s*";
    },
  },

  methods: {
    /**
     * A setting has changed
     */
    settingChanged: function () {
      if (this.dataErrors == 0) {
        let newSettings = {};
        // sender
        if (this.sender != "") {
          newSettings.sender = this.sender;
        }
        if (this.senderId.value != "") {
          newSettings.senderId = {
            type: this.senderId.type,
            value: this.senderId.value,
            nonComposite: this.senderId.nonComposite,
          };
        }
        if (
          this.senderContact.details != "" ||
          this.senderContact.email != ""
        ) {
          newSettings.senderContact = {};
          if (this.senderContact.details != "") {
            newSettings.senderContact.details = this.senderContact.details;
          }
          if (this.senderContact.email != "") {
            newSettings.senderContact.email = this.senderContact.email;
          }
        }

        // recipient
        if (this.recipient != "") {
          newSettings.recipient = this.recipient;
        }
        if (this.recipientId.value != "") {
          newSettings.recipientId = {
            type: this.recipientId.type,
            value: this.recipientId.value,
            nonComposite: this.recipientId.nonComposite,
          };
        }
        if (
          this.recipientContact.details != "" ||
          this.recipientContact.email != ""
        ) {
          newSettings.recipientContact = {};
          if (this.recipientContact.details != "") {
            newSettings.recipientContact.details =
              this.recipientContact.details;
          }
          if (this.recipientContact.email != "") {
            newSettings.recipientContact.email = this.recipientContact.email;
          }
        }

        // message defaults
        newSettings.messageDefaults = {};
        if (this.messageDefaults.language != "") {
          newSettings.messageDefaults.language = this.messageDefaults.language;
        }
        if (this.messageDefaults.priceType != "") {
          newSettings.messageDefaults.priceType =
            this.messageDefaults.priceType;
        }
        if (this.messageDefaults.currency != "") {
          newSettings.messageDefaults.currency = this.messageDefaults.currency;
        }

        // imprint identifiers
        let imprintIdentifiers = [];
        for (const index in this.imprintIdentifiers) {
          if (this.imprintIdentifiers[index].value != "") {
            let idEntry = {
              type: this.imprintIdentifiers[index].type,
              value: this.imprintIdentifiers[index].value,
            };
            if (this.imprintIdentifiers[index].condition.type != "") {
              idEntry.condition = this.outputCondition(
                this.imprintIdentifiers[index].condition
              );
            }
            imprintIdentifiers.push(idEntry);
          }
        }
        newSettings.imprintIdentifiers = imprintIdentifiers;

        // publisher identifiers
        let publisherIdentifiers = [];
        for (const index in this.publisherIdentifiers) {
          if (this.publisherIdentifiers[index].value != "") {
            let idEntry = {
              type: this.publisherIdentifiers[index].type,
              value: this.publisherIdentifiers[index].value,
            };
            if (this.publisherIdentifiers[index].condition.type != "") {
              idEntry.condition = this.outputCondition(
                this.publisherIdentifiers[index].condition
              );
            }
            publisherIdentifiers.push(idEntry);
          }
        }
        newSettings.publisherIdentifiers = publisherIdentifiers;

        // proprietary subjects
        let proprietarySubjects = [];
        for (const index in this.proprietarySubjects) {
          if (
            this.proprietarySubjects[index].scheme != "" &&
            (this.proprietarySubjects[index].code != "" ||
              this.proprietarySubjects[index].heading != "")
          ) {
            let subjectEntry = {
              scheme: this.proprietarySubjects[index].scheme,
              code: this.proprietarySubjects[index].code,
              heading: this.proprietarySubjects[index].heading,
            };
            if (this.proprietarySubjects[index].condition.type != "") {
              subjectEntry.condition = this.outputCondition(
                this.proprietarySubjects[index].condition
              );
            }
            proprietarySubjects.push(subjectEntry);
          }
        }
        newSettings.proprietarySubjects = proprietarySubjects;

        // supplier identifiers
        let supplierIdentifiers = [];
        for (const index in this.supplierIdentifiers) {
          if (this.supplierIdentifiers[index].value != "") {
            let idEntry = {
              type: this.supplierIdentifiers[index].type,
              value: this.supplierIdentifiers[index].value,
            };
            if (this.supplierIdentifiers[index].supplier) {
              idEntry.supplier = this.supplierIdentifiers[index].supplier;
            }
            if (this.supplierIdentifiers[index].condition.type != "") {
              idEntry.condition = this.outputCondition(
                this.supplierIdentifiers[index].condition
              );
            }
            supplierIdentifiers.push(idEntry);
          }
        }
        newSettings.supplierIdentifiers = supplierIdentifiers;

        // distributor discounts
        let distributorDiscounts = [];
        for (const index in this.distributorDiscounts) {
          if (this.distributorDiscounts[index].value != "") {
            let idEntry = {
              value: this.distributorDiscounts[index].value,
            };
            if (this.distributorDiscounts[index].supplier) {
              idEntry.supplier = this.distributorDiscounts[index].supplier;
            }
            if (this.distributorDiscounts[index].condition.type != "") {
              idEntry.condition = this.outputCondition(
                this.distributorDiscounts[index].condition
              );
            }
            distributorDiscounts.push(idEntry);
          }
        }
        newSettings.distributorDiscounts = distributorDiscounts;

        // distributor discount codes
        let distributorDiscountCodes = [];
        for (const index in this.distributorDiscountCodes) {
          if (this.distributorDiscountCodes[index].value != "") {
            let idEntry = {
              type: this.distributorDiscountCodes[index].type,
              value: this.distributorDiscountCodes[index].value,
            };
            if (this.distributorDiscountCodes[index].supplier) {
              idEntry.supplier = this.distributorDiscountCodes[index].supplier;
            }
            if (this.distributorDiscountCodes[index].condition.type != "") {
              idEntry.condition = this.outputCondition(
                this.distributorDiscountCodes[index].condition
              );
            }
            distributorDiscountCodes.push(idEntry);
          }
        }
        newSettings.distributorDiscountCodes = distributorDiscountCodes;

        // included suppliers
        newSettings.includedSuppliers = this.includedSuppliers;
        newSettings.includedSuppliers_except = this.includedSuppliersExcept;

        // included prices
        newSettings.includedPrices = this.includedPrices;
        newSettings.includedPrices_except = this.includedPricesExcept;

        // included market representatives
        newSettings.includedMarketRepresentatives =
          this.includedMarketRepresentatives;
        newSettings.includedMarketRepresentatives_except =
          this.includedMarketRepresentativesExcept;

        // preferred language
        if (this.preferredLanguage) {
          newSettings.preferredLanguage = this.preferredLanguage;
        }

        // priceTiers
        let priceTiers = [];
        for (const index in this.priceTiers) {
          if (this.priceTiers[index].currency != "") {
            let tiers = [];
            for (const tIndex in this.priceTiers[index].tiers) {
              let tier = this.priceTiers[index].tiers[tIndex];
              if (tier.id != "" && tier.lo != "") {
                tiers.push({
                  id: tier.id,
                  lo: tier.lo,
                  hi: tier.hi.length != 0 ? tier.hi : tier.lo,
                });
              }
            }
            if (tiers.length != 0) {
              priceTiers.push({
                currency: this.priceTiers[index].currency,
                tiers: tiers,
              });
            }
          }
        }
        newSettings.priceTiers = priceTiers;

        // alternate publication date
        switch (this.alternatePubDate.type) {
          case "S":
            if (this.alternatePubDate.value) {
              newSettings.alternatePubDate = {
                supplier: this.alternatePubDate.value,
              };
            }
            break;
          case "M":
            if (this.alternatePubDate.value) {
              newSettings.alternatePubDate = {
                marketRepresentative: this.alternatePubDate.value,
              };
            }
            break;
        }

        // data-only image filename
        newSettings.imageFilename = this.imageFilename;

        // keep non-modified settings
        if (this.dataSettings) {
          for (const prop in this.dataSettings) {
            switch (prop) {
              case "sender":
              case "senderId":
              case "senderContact":
              case "recipient":
              case "recipientId":
              case "recipientContact":
              case "messageDefaults":
              case "imprintIdentifiers":
              case "publisherIdentifiers":
              case "proprietarySubjects":
              case "supplierIdentifiers":
              case "distributorDiscounts":
              case "distributorDiscountCodes":
              case "includedSuppliers":
              case "includedSuppliers_except":
              case "includedPrices":
              case "includedPrices_except":
              case "includedMarketRepresentatives":
              case "includedMarketRepresentatives_except":
              case "preferredLanguage":
              case "priceTiers":
              case "alternatePubDate":
              case "imageFilename":
                break;
              default:
                newSettings[prop] = this.dataSettings[prop];
                break;
            }
          }
        }

        this.$emit("change", newSettings);
      } else {
        this.$emit("data-errors");
      }
    },

    /**
     * A setting has changed with the clear store option
     */
    settingChangedCS: function () {
      this.settingChanged();
      if (this.dataErrors == 0) {
        this.$emit("change-clearstore");
      }
    },

    /**
     * A setting has changed that needs checking
     */
    settingChangedCheck: function () {
      // check if the sender/recipient email addresses are valid
      let listPattern = new RegExp("^(" + this.emailAddrPattern + ")?$", "");

      if (this.senderContact.email.search(listPattern) == -1) {
        this.dataErrors |= 1;
      } else {
        this.dataErrors &= ~1;
      }

      if (this.recipientContact.email.search(listPattern) == -1) {
        this.dataErrors |= 2;
      } else {
        this.dataErrors &= ~2;
      }

      // discounts
      this.dataErrors &= ~4;
      for (const index in this.distributorDiscounts) {
        if (
          this.distributorDiscounts[index].value.search(
            /^([1-9]?[0-9](\.[0-9]+)?)?$/
          ) == -1
        ) {
          this.dataErrors |= 4;
          break;
        }
      }

      // price tiers
      this.dataErrors &= ~8;
      for (const index in this.priceTiers) {
        for (const tIndex in this.priceTiers[index].tiers) {
          let tier = this.priceTiers[index].tiers[tIndex];
          // tier id
          if (tier.id.search(/^[a-zA-Z0-9]$/) == -1) {
            this.dataErrors |= 8;
            break;
          }
          // tier low value
          if (tier.lo.search(/^([1-9][0-9]*|0)(\.[0-9]+)?$/) == -1) {
            this.dataErrors |= 8;
            break;
          }
          // tier high value
          if (tier.hi.search(/^(([1-9][0-9]*|0)(\.[0-9]+)?)?$/) == -1) {
            this.dataErrors |= 8;
            break;
          }
        }
      }

      this.settingChanged();
    },

    /**
     * A setting has changed with the clear store option that needs checking
     */
    settingChangedCheckCS: function () {
      this.settingChangedCheck();
      if (this.dataErrors == 0) {
        this.$emit("change-clearstore");
      }
    },

    /**
     * Output a condition
     */
    outputCondition: function (condition) {
      switch (condition.type) {
        case "I":
          if (condition.imprint) {
            return {
              type: "IMPRINT",
              value: condition.imprint,
            };
          }
          return null;
        case "P":
          if (condition.publisher) {
            return {
              type: "PUBLISHER",
              value: condition.publisher,
            };
          }
          return null;
        case "F":
          if (condition.format != "") {
            return {
              type: "FORMAT",
              value: condition.format,
            };
          }
          return null;
        default:
          return null;
      }
    },

    /**
     * Delete an item
     */
    deleteItem: function (list, index) {
      list.splice(index, 1);
      this.settingChanged();
    },

    /**
     * Move an item up
     */
    moveItemUp: function (list, index) {
      if (index > 0) {
        let itemToMove = list.splice(index, 1)[0];
        list.splice(index - 1, 0, itemToMove);
        this.settingChanged();
      }
    },

    /**
     * Move an item down
     */
    moveItemDown: function (list, index) {
      if (index < list.length - 1) {
        let itemToMove = list.splice(index, 1)[0];
        list.splice(index + 1, 0, itemToMove);
        this.settingChanged();
      }
    },

    /**
     * Insert text into image filename control
     */
    imageFilenameInsertText: function (insertText) {
      let control = this.$refs.imageFilename;
      let selStart = control.selectionStart;
      let selEnd = control.selectionEnd;
      let newCursorPos = selStart + insertText.length;
      let newValue =
        this.imageFilename.substring(0, selStart) +
        insertText +
        this.imageFilename.substring(selEnd);
      this.imageFilename = newValue;
      this.$nextTick(() => {
        control.focus();
        control.setSelectionRange(newCursorPos, newCursorPos);
      });
      this.settingChangedCS();
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  width: 100%;
}
select.sender-id-type,
select.message-defaults {
  width: 100%;
}
input.sender-id-non-composite {
  margin-right: 0.5rem;
}
div.identifier-add a {
  float: right;
}
div.condition-list {
  display: inline-block;
  width: 24%;
}
select.condition-list {
  width: 23.5%;
}
div.condition-suppier-list-si {
  display: inline-block;
  width: 36.5%;
}
div.condition-suppier-list-dd {
  display: inline-block;
  width: 34.3%;
}
div.condition-suppier-list-dc {
  display: inline-block;
  width: 36.5%;
}
input[type="text"]:invalid {
  border: red solid 3px;
}
option.invalid-item {
  background-color: #ff9999;
}
select.pricetier-currency {
  width: 85%;
}
input.pricetier-tier-id,
input.pricetier-tier-value {
  width: 4rem;
}
</style>
