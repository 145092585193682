<template>
  <b-card
    v-b-popover.hover.top.html="tooltipContent"
    style="width: 150px; height: 100%"
  >
    <div class="d-flex h-100">
      <i class="m-auto" :class="fileIcon"></i>
    </div>
    <template #footer>
      <div class="text-center">
        <template v-if="externalFile">
          <a
            :href="mediaObject.getLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ mediaObject.getLink }}
          </a>
        </template>
        <template v-else>
          {{ mediaObject.getLink }}<br /><a :href="downloadLink" target="_blank"
            >Download</a
          >
        </template>
        <div>
          <b-badge
            v-if="resource.internal"
            class="internal-badge"
            variant="secondary"
            >Internal</b-badge
          >
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ProductMediaFileCard",

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * The logon (session) ID of the current user
     */
    logonId: {
      type: String,
      default: null,
    },

    /**
     * The product resource media type
     *
     * Leave blank if only passing in the productId
     */
    mediaType: {
      type: String,
      default: null,
    },

    /**
     * The product resource file size
     *
     * Leave blank if only passing in the productId
     */
    fileSize: {
      type: [String, Number],
      default: null,
    },

    /**
     * The product resource last modified date string
     *
     * Format yyyy-mm-dd
     *
     * Leave blank if only passing in the productId
     */
    lastModified: {
      type: String,
      default: null,
    },

    /**
     * The product resource file name
     *
     * Leave blank if only passing in the productId
     */
    fileName: {
      type: String,
      default: null,
    },

    /**
     * Media object of product getProductMedia
     */
    mediaObject: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      resource: {
        mediaType: this.mediaType,
        fileSize: this.fileSize,
        lastModified: this.lastModified,
        fileName: this.fileName,
        caption: this.mediaObject ? this.mediaObject.getCaption : null,
        credit: this.mediaObject ? this.mediaObject.getCredit : null,
        copyrightHolder: this.mediaObject
          ? this.mediaObject.getCopyrightHolder
          : null,
        alternateText: this.mediaObject
          ? this.mediaObject.getAlternateText
          : null,
        internal: this.mediaObject ? this.mediaObject.getInternal : null,
      },
    };
  },

  computed: {
    fileIcon: function () {
      const defaultIcon = "fa fa-file-o fa-4x";
      if (!this.mediaObject) {
        return defaultIcon;
      }
      if (
        this.mediaObject.getFormatName &&
        this.mediaObject.getFormatName == "PDF"
      ) {
        return "fa fa-file-pdf fa-4x";
      }
      // media is a URL
      if (this.mediaObject.getLinkType == "01") {
        const { hostname } = new URL(this.mediaObject.getLink);
        // section is tested but ignored from coverage to prevent a brittle test if we add or remove icons
        /* istanbul ignore next */
        if (hostname.includes("youtube.com")) {
          return "fa-brands fa-youtube fa-4x";
        } else if (hostname.includes("facebook.com")) {
          return "fa-brands fa-facebook fa-4x";
        } else if (hostname.includes("twitter.com")) {
          return "fa-brands fa-twitter fa-4x";
        } else if (hostname.includes("instagram.com")) {
          return "fa-brands fa-instagram fa-4x";
        } else if (hostname.includes("tiktok.com")) {
          return "fa-brands fa-tiktok fa-4x";
        } else if (hostname.includes("linkedin.com")) {
          return "fa-brands fa-linkedin fa-4x";
        }
      }
      return defaultIcon;
    },
    externalFile: function () {
      if (this.mediaObject && this.mediaObject.getLinkType != "06") {
        return true;
      } else {
        return false;
      }
    },
    downloadLink: function () {
      return (
        "/" +
        this.$appId +
        "/" +
        this.resource.fileName +
        "?L=" +
        this.logonId +
        "&D"
      );
    },
    roundedFileSize: function () {
      if (!this.resource.fileSize) {
        return null;
      }
      return (this.resource.fileSize / 1000).toFixed(0);
    },
    tooltipContent: function () {
      let content = "";
      if (this.resource.fileSize) {
        content += "<b>File Size:</b> " + this.roundedFileSize + " KB<br>";
      }
      if (this.resource.lastModified) {
        content +=
          "<b>Last Modified:</b> " +
          this.$options.filters.parseDate(
            this.resource.lastModified,
            this.$userPreferences.dateFormat,
            this.dateOptions
          ) +
          "<br>";
      }
      if (this.resource.caption) {
        content += "<b>Caption:</b> " + this.resource.caption + "<br>";
      }
      if (this.resource.credit) {
        content += "<b>Credit:</b> " + this.resource.credit + "<br>";
      }
      if (this.resource.copyrightHolder) {
        content +=
          "<b>Copyright Holder:</b> " + this.resource.copyrightHolder + "<br>";
      }
      if (this.resource.alternateText) {
        content +=
          "<b>Alternate Text:</b> " + this.resource.alternateText + "<br>";
      }
      let title = "";
      if (this.resource.mediaType) {
        title += this.resource.mediaType;
        if (this.resource.internal) {
          title += " (Internal)";
        }
      }
      if (this.resource.fileName) {
        if (this.resource.mediaType) {
          title += ": ";
        }
        title += this.resource.fileName;
      }
      return {
        title: title,
        content: content,
      };
    },
  },
};
</script>

<style scoped>
.internal-badge {
  font-size: 1rem;
}
</style>
