<template>
  <div class="biblioproductcontactsedit">
    <div class="row biblioproductcontactsedit-group">
      <template v-if="groups">
        <div class="col-6">
          <label :for="'productcontactsedit-' + componentId + '-group'">
            Group:
          </label>
          <div class="biblioproductcontactsedit-group-select">
            <v-select
              v-model="currentGroup"
              :clearable="true"
              :input-id="'productcontactsedit-' + componentId + '-group'"
              :options="groups"
              label="name"
              :placeholder="'(select ' + contactsDesc + ' contacts group)'"
              @input="groupSelected"
            >
              <template v-slot:no-options="{ search, searching }">
                <em v-if="searching"
                  >No {{ contactsDesc }} contacts group found</em
                >
                <em v-else>
                  Start typing to search for a {{ contactsDesc }} contacts group
                </em>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-5">
          <button
            v-if="currentGroup"
            :id="'productcontactsedit-' + componentId + '-copyedit'"
            type="button"
            :title="'Edit ' + contactsDesc + ' contacts'"
            class="btn btn-outline-primary btn-sm"
            @click="editContacts"
          >
            <i class="fa fa-copy"></i>
            Copy &amp; Edit
          </button>
        </div>
      </template>
      <template v-else>
        <slot name="group">
          <div class="col-11"></div>
        </slot>
      </template>
      <div class="col-1">
        <button
          v-if="!currentGroup"
          :id="'productcontactsedit-' + componentId + '-add'"
          type="button"
          :title="'Add another ' + contactsDesc + ' contact'"
          class="btn btn-outline-primary"
          @click="addContact"
        >
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
    </div>
    <div
      v-for="(contact, iContact) in currentContacts"
      :key="iContact"
      class="row biblioproductcontactsedit-contact"
    >
      <div class="col-4 pr-0 py-1">
        <label
          :for="
            'productcontactsedit-' +
            componentId +
            '-contact-' +
            iContact +
            '-role'
          "
        >
          Role:
        </label>
        <select
          :id="
            'productcontactsedit-' +
            componentId +
            '-contact-' +
            iContact +
            '-role'
          "
          v-model="contact.role"
          :disabled="!!currentGroup"
          size="1"
          class="biblioproductcontactsedit-role"
          @change="roleChanged"
        >
          <option value="">(select role)</option>
          <option v-for="role in roles" :key="role.code" :value="role.code">
            {{ role.name }}
          </option>
        </select>
      </div>
      <div class="col-7 pl-0 py-1">
        <label
          :for="
            'productcontactsedit-' +
            componentId +
            '-contact-' +
            iContact +
            '-cont'
          "
        >
          Organisation:
        </label>
        <div
          v-if="currentGroup"
          class="biblioproductcontactsedit-contact-noselect"
        >
          {{ contact.contact_name }}
          <i v-if="contact.contact_name2"> ({{ contact.contact_name2 }})</i>
        </div>
        <div v-else class="biblioproductcontactsedit-contact-select">
          <contacts-dropdown
            :select-id="
              'productcontactsedit-' +
              componentId +
              '-contact-' +
              iContact +
              '-cont'
            "
            :base-url="$baseUrl"
            data-source-options="/biblio-edit/contact-selector-terms/c2"
            placeholder="Select a contact..."
            :value="
              contact.contact_id
                ? {
                    id: contact.contact_id,
                    name: contact.contact_name,
                    name2: contact.contact_name2,
                  }
                : null
            "
            :corporate="true"
            @selected="
              (data) => {
                contactSelected(iContact, data);
              }
            "
            @create="contactCreateModal(iContact)"
          >
            <template #option="option">
              {{ option.name }}
              <i v-if="option.name2"> ({{ option.name2 }})</i>
            </template>
            <template #selected-option="option">
              {{ option.name }}
              <i v-if="option.name2"> ({{ option.name2 }})</i>
            </template>
          </contacts-dropdown>
        </div>
      </div>
      <div class="col-1 py-1">
        <button
          v-if="!currentGroup"
          :id="
            'productcontactsedit-' +
            componentId +
            '-contact-' +
            iContact +
            '-delete'
          "
          type="button"
          :title="'Delete this ' + contactsDesc + ' contact'"
          class="btn btn-outline-primary"
          @click="deleteContact(iContact)"
        >
          Delete
        </button>
      </div>
    </div>
    <b-modal
      v-if="newContactModalData"
      :id="'productcontactsedit-' + componentId + '-newcontact'"
      size="lg"
      title="Create Contact"
      no-close-on-backdrop
      static
      lazy
      @ok="contactCreate"
    >
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Corporate Name</div>
        <div class="col-9">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-cn'"
            v-model="newContactModalData.corpName"
            type="text"
            maxlength="200"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div
          class="col-3"
          title="Corporate Contact (person, department, and so on)"
        >
          Corporate Contact
        </div>
        <div class="col-9">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-cc'"
            v-model="newContactModalData.corpContact"
            type="text"
            maxlength="300"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Email address</div>
        <div class="col-9">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-em'"
            v-model="newContactModalData.email"
            type="text"
            maxlength="100"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Telephone number</div>
        <div class="col-5">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-pn'"
            v-model="newContactModalData.phone"
            type="text"
            maxlength="70"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Fax number</div>
        <div class="col-5">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-fn'"
            v-model="newContactModalData.fax"
            type="text"
            maxlength="70"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Address: Street</div>
        <div class="col-9">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-as'"
            v-model="newContactModalData.addressStreet"
            type="text"
            maxlength="200"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Address: Town/City</div>
        <div class="col-9">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-at'"
            v-model="newContactModalData.addressTown"
            type="text"
            maxlength="200"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Address: Postal/ZIP code</div>
        <div class="col-5">
          <input
            :id="'productcontactsedit-' + componentId + '-newcontact-ap'"
            v-model="newContactModalData.addressPostCode"
            type="text"
            maxlength="25"
          />
        </div>
      </div>
      <div class="row biblioproductcontactsedit-newcontact">
        <div class="col-3">Address: Country / Region</div>
        <div class="col-5">
          <select
            :id="'productcontactsedit-' + componentId + '-newcontact-ar'"
            v-model="newContactModalData.addressRegion"
            size="1"
          >
            <option value=""></option>
            <optgroup
              v-for="continent in allCountries"
              :key="continent.code"
              :label="continent.code"
            >
              <template v-for="country in continent.name">
                <option
                  v-if="country.code.length >= 4"
                  :key="country.code"
                  :value="country.code"
                >
                  &emsp;
                  {{ country.name }}
                </option>
                <option v-else :key="country.code" :value="country.code">
                  {{ country.name }}
                </option>
              </template>
            </optgroup>
          </select>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * Bibliographic product contacts edit container
 *
 * Emits a 'changed' event with product contacts data (group id or array of contacts)
 * Emits an 'error' event if a role but no contact, or vice versa
 */
import { HTTP } from "../../../http-common.js";
import vSelect from "vue-select";
import ContactsDropdown from "../../utility/ContactsDropdown.vue";

export default {
  name: "BiblioProductContactsEdit",

  components: {
    "v-select": vSelect,
    "contacts-dropdown": ContactsDropdown,
  },

  props: {
    /**
     * Component id
     */
    componentId: {
      type: String,
      default: "",
    },

    /**
     * What type of contacts do we have
     */
    contactsDesc: {
      type: String,
      default: "product",
    },

    /**
     * Roles
     */
    roles: {
      type: Array,
      required: true,
    },

    /**
     * Groups
     */
    groups: {
      type: Array,
      default: null,
    },

    /**
     * Current Group
     */
    group: {
      type: Number,
      default: null,
    },

    /**
     * Current product contacts
     */
    productContacts: {
      type: Array,
      default: null,
    },

    /**
     * The list of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    let currentGroup = null;
    let currentContacts = this.productContacts;
    if (this.group) {
      for (let iGroup in this.groups) {
        if (this.group == this.groups[iGroup].id) {
          currentGroup = this.groups[iGroup];
          currentContacts = this.groups[iGroup].contacts;
          break;
        }
      }
    } else {
      if (!currentContacts || currentContacts.length == 0) {
        currentContacts = [
          { role: "", contact_id: null, contact_name: "", contact_name2: null },
        ];
      }
    }
    return {
      currentGroup: currentGroup,
      currentContacts: currentContacts,
      formData: this.updateFormData(currentGroup, currentContacts, true),
      newContactModalData: null,
    };
  },

  methods: {
    /**
     * A new group has been selected (or cleared)
     */
    groupSelected: function (aGroup) {
      if (aGroup) {
        this.currentContacts = aGroup.contacts;
      } else {
        this.currentContacts = [
          { role: "", contact_id: null, contact_name: "", contact_name2: null },
        ];
      }
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * Start editing the product contacts; this unlinks from the group but retains the product contacts
     * from the group (so we can edit them)
     */
    editContacts: function () {
      this.currentGroup = null;
      this.currentContacts = [...this.currentContacts];
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * Add a new product contact
     */
    addContact: function () {
      this.currentContacts.push({
        role: "",
        contact_id: null,
        contact_name: "",
        contact_name2: null,
      });
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * Delete a product contact
     */
    deleteContact: function (index) {
      this.currentContacts.splice(index, 1);
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * A role has been changed
     */
    roleChanged: function () {
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * A contact has been selected
     */
    contactSelected: function (index, data) {
      this.currentContacts[index].contact_id = data.ID;
      this.currentContacts[index].contact_name = data.CN;
      this.currentContacts[index].contact_name2 =
        data.CC != "" ? data.CC : null;
      this.updateFormData(this.currentGroup, this.currentContacts);
    },

    /**
     * Show the create contact modal
     */
    contactCreateModal: function (index) {
      this.newContactModalData = {
        contactIndex: index,
        corpName: "",
        corpContact: "",
        email: "",
        phone: "",
        fax: "",
        addressStreet: "",
        addressTown: "",
        addressPostCode: "",
        addressRegion: "",
      };
      this.$nextTick(() => {
        this.$bvModal.show(
          "productcontactsedit-" + this.componentId + "-newcontact"
        );
      });
    },

    /**
     * create a new contact
     */
    contactCreate: function (event) {
      event.preventDefault();

      // check the data entered
      let modalData = this.newContactModalData;
      if (modalData.corpName == "") {
        this.$bvModal.msgBoxOk("Please enter a Corporate Name");
        return;
      }

      if (
        modalData.addressStreet != "" ||
        modalData.addressTown != "" ||
        modalData.addressPostCode != "" ||
        modalData.addressRegion != ""
      ) {
        if (modalData.addressStreet == "") {
          this.$bvModal.msgBoxOk("A Street is required with an address");
          return;
        }
        if (modalData.addressTown == "") {
          this.$bvModal.msgBoxOk("A Town/City is required with an address");
          return;
        }
        if (modalData.addressTown == "") {
          this.$bvModal.msgBoxOk(
            "A Postal/ZIP code is required with an address"
          );
          return;
        }
      }

      // create the contact
      let postData = new URLSearchParams();
      if (this.roles[0].code == "00") {
        postData.append("FLXTY1", "PC");
      } else {
        postData.append("FLXTY1", "SN");
      }
      postData.append("titles_before", "");
      postData.append("names_before_key", "");
      postData.append("prefix_to_key", "");
      postData.append("key_name", "");
      postData.append("suffix_to_key", "");
      postData.append("titles_after", "");
      postData.append("qualifications_honours", "");
      postData.append("corporate_name", modalData.corpName);
      postData.append("corporate_contact", modalData.corpContact);
      postData.append("selfidentification", "");
      postData.append("image_type", "url");
      postData.append("image", "");
      postData.append("audio_type", "url");
      postData.append("audio", "");
      postData.append("address__default", "0");
      postData.append("address[0][address_id]", "");
      postData.append("address[0][address_type]", "B");
      postData.append("address[0][department]", "");
      postData.append("address[0][building_num]", "");
      postData.append("address[0][building_name]", "");
      postData.append("address[0][street]", modalData.addressStreet);
      postData.append("address[0][place]", "");
      postData.append("address[0][town]", modalData.addressTown);
      postData.append("address[0][province]", "");
      postData.append("address[0][postcode]", modalData.addressPostCode);
      postData.append("address[0][region]", modalData.addressRegion);
      postData.append("contact_detail[business_email]", modalData.email);
      postData.append("contact_detail[default_email]", "business_email");
      postData.append("contact_detail[home_email]", "");
      postData.append("contact_detail[other_email]", "");
      postData.append("contact_detail[business_phone]", modalData.phone);
      postData.append("contact_detail[default_phone]", "business_phone");
      postData.append("contact_detail[business_mobile]", "");
      postData.append("contact_detail[home_phone]", "");
      postData.append("contact_detail[home_mobile]", "");
      postData.append("contact_detail[other_phone]", "");
      postData.append("contact_detail[other_mobile]", "");
      postData.append("contact_detail[business_fax]", modalData.fax);
      postData.append("contact_detail[default_fax]", "business_fax");
      postData.append("contact_detail[home_fax]", "");
      postData.append("contact_detail[other_fax]", "");
      postData.append("contact_imdetail[msn]", "");
      postData.append("contact_imdetail[yahoo]", "");
      postData.append("contact_imdetail[skype]", "");
      postData.append("contact_imdetail[google]", "");
      postData.append("contact_imdetail[aol]", "");
      postData.append("contact_imdetail[facebook]", "");
      postData.append("contact_imdetail[twitter]", "");
      postData.append("contact_imdetail[blogs]", "");
      postData.append("contact_imdetail[goodreads]", "");
      postData.append("contact_imdetail[instagram]", "");
      postData.append("contact_imdetail[pinterest]", "");
      postData.append("contact_imdetail[youtube]", "");
      postData.append("contact_imdetail[linkedin]", "");
      postData.append("contact_imdetail[amazon]", "");
      postData.append("contact_imdetail[tiktok]", "");

      HTTP.post(this.$baseUrl + "contacts/edit", postData)
        .then((response) => {
          this.$bvModal.hide(
            "productcontactsedit-" + this.componentId + "-newcontact"
          );
          this.newContactModalData = null;
          this.contactSelected(modalData.contactIndex, {
            ID: response.data.contactid,
            CN: modalData.corpName,
            CC: modalData.corpContact,
          });
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(
            "An error has occurred while creating your contact"
          );
        });
    },
    /**
     * Update form data
     */
    updateFormData: function (group, contacts, initialise = false) {
      let formData = "";
      if (group) {
        formData = JSON.stringify(group.id);
      } else if (contacts) {
        let data = [];
        for (let i in contacts) {
          if (contacts[i].role != "" && contacts[i].contact_id) {
            data.push({
              role: contacts[i].role,
              contact: contacts[i].contact_id,
            });
          } else if (contacts[i].role != "") {
            // role but no contact
            this.$emit("error", "contact");
            return;
          } else if (contacts[i].contact_id) {
            // contact but no role
            this.$emit("error", "role");
            return;
          }
        }
        formData = JSON.stringify(data);
      }
      this.$emit("changed", formData);
      if (initialise) {
        return formData;
      }
      this.formData = formData;
    },
  },
};
</script>

<style>
div.biblioproductcontactsedit-group {
  margin-bottom: 1rem;
}
div.biblioproductcontactsedit-group-select {
  width: 90%;
  display: inline-block;
  margin-left: 0.5rem;
}
div.biblioproductcontactsedit-contact label {
  padding-left: 0.7rem;
  padding-right: 0.5rem;
}
select.biblioproductcontactsedit-role {
  width: 80%;
}
select.biblioproductcontactsedit-role[disabled] {
  background-color: #e9e9ed;
}
div.biblioproductcontactsedit-contact-noselect {
  width: 80%;
  display: inline-block;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  background-color: #e9e9ed;
}
div.biblioproductcontactsedit-contact-select {
  width: 80%;
  display: inline-block;
}
div.biblioproductcontactsedit-contact-noselect i,
div.biblioproductcontactsedit-contact-select i {
  color: grey;
}
div.biblioproductcontactsedit-newcontact input,
div.biblioproductcontactsedit-newcontact select {
  width: 95%;
}
</style>
