<template>
  <div>
    <!-- image -->
    <div class="row">
      <div class="col-3">Images Errors</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-eaw-e'"
          v-model="image.errorsAsWarnings"
          type="radio"
          value="E"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-eaw-e'">
          Are Errors
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-eaw-w'"
          v-model="image.errorsAsWarnings"
          type="radio"
          value="W"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-eaw-w'">
          Are Warnings
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Image Resizing</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-resize'"
          v-model="image.allowResize"
          type="checkbox"
          value="Y"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-resize'">
          is Allowed
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Image Upscaling</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-upscale'"
          v-model="image.allowUpscale"
          type="checkbox"
          value="Y"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-upscale'">
          is Allowed
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Send Images</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-send-a'"
          v-model="image.alwaysSend"
          type="radio"
          value="A"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-send-a'">
          Always
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-img-send-m'"
          v-model="image.alwaysSend"
          type="radio"
          value="M"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-img-send-n'">
          Only if Modified
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3" />
      <div class="col-9">
        If 'Only if Modified' is selected, then an image is only sent if it has
        been modified since it was last sent to this data recipient, or it has
        not been sent to this data recipient before.
        <br />
        This setting is ignored if 'media files' are sent instead of 'images'.
      </div>
    </div>
    <hr />
    <!-- ebook -->
    <div class="row">
      <div class="col-3">Sending eBooks</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebk-enable'"
          v-model="ebook.enabled"
          type="checkbox"
          value="Y"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebk-enable'">
          is Allowed
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">eBook Errors</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebk-eaw-e'"
          v-model="ebook.errorsAsWarnings"
          type="radio"
          value="E"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebk-eaw-e'">
          Are Errors
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebk-eaw-w'"
          v-model="ebook.errorsAsWarnings"
          type="radio"
          value="W"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebk-eaw-w'">
          Are Warnings
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Send eBooks</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebk-send-a'"
          v-model="ebook.alwaysSend"
          type="radio"
          value="A"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebk-send-a'">
          Always
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebk-send-m'"
          v-model="ebook.alwaysSend"
          type="radio"
          value="M"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebk-send-n'">
          Only if Modified
        </label>
      </div>
    </div>
    <hr />
    <!-- ebook for searching -->
    <div class="row">
      <div class="col-3">Sending eBooks for searching</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebs-enable'"
          v-model="ebookSearch.enabled"
          type="checkbox"
          value="Y"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebs-enable'">
          is Allowed
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">eBook for searching Errors</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebs-eaw-e'"
          v-model="ebookSearch.errorsAsWarnings"
          type="radio"
          value="E"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebs-eaw-e'">
          Are Errors
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebs-eaw-w'"
          v-model="ebookSearch.errorsAsWarnings"
          type="radio"
          value="W"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebs-eaw-w'">
          Are Warnings
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Send eBooks for searching</div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebs-send-a'"
          v-model="ebookSearch.alwaysSend"
          type="radio"
          value="A"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebs-send-a'">
          Always
        </label>
      </div>
      <div class="col-2">
        <input
          :id="'feed' + feedRecipientId + '-mediasettings-ebs-send-m'"
          v-model="ebookSearch.alwaysSend"
          type="radio"
          value="M"
          @change="settingChanged"
        />
        <label :for="'feed' + feedRecipientId + '-mediasettings-ebs-send-n'">
          Only if Modified
        </label>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings media settings component
 */
export default {
  name: "MediaSettings",

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Media settings object
     */
    mediaSettings: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    // note: prettier is disabled on various lines to allow the following line to use the
    //       term with the null-coalescing operator to be enclosed in brackets to improve
    //       readibility
    let mediaSettings = this.mediaSettings ?? {};
    let imageSettings = mediaSettings.image ?? {};
    let ebookSettings = mediaSettings.ebook ?? {};
    let ebookSearchSettings = mediaSettings.ebookSearch ?? {};
    return {
      image: {
        // eslint-disable-next-line prettier/prettier
        errorsAsWarnings: (imageSettings.errorsAsWarnings ?? true) ? "W" : "E",
        allowResize: imageSettings.allowResize ?? true,
        allowUpscale: imageSettings.allowUpscale ?? true,
        // eslint-disable-next-line prettier/prettier
        alwaysSend: (imageSettings.alwaysSend ?? true) ? "A" : "M",
      },
      ebook: {
        enabled: ebookSettings.enabled ?? false,
        // eslint-disable-next-line prettier/prettier
        errorsAsWarnings: (ebookSettings.errorsAsWarnings ?? true) ? "W" : "E",
        // eslint-disable-next-line prettier/prettier
        alwaysSend: (ebookSettings.alwaysSend ?? false) ? "A" : "M",
      },
      ebookSearch: {
        enabled: ebookSearchSettings.enabled ?? false,
        // eslint-disable-next-line prettier/prettier
        errorsAsWarnings: (ebookSearchSettings.errorsAsWarnings ?? true) ? "W" : "E",
        // eslint-disable-next-line prettier/prettier
        alwaysSend: (ebookSearchSettings.alwaysSend ?? false) ? "A" : "M",
      },
    };
  },

  methods: {
    /**
     * A setting has changed
     */
    settingChanged: function () {
      let newSettings = {
        image: {
          errorsAsWarnings: this.image.errorsAsWarnings == "W",
          allowResize: this.image.allowResize,
          allowUpscale: this.image.allowUpscale,
          alwaysSend: this.image.alwaysSend == "A",
        },
        ebook: {
          enabled: this.ebook.enabled,
          errorsAsWarnings: this.ebook.errorsAsWarnings == "W",
          alwaysSend: this.ebook.alwaysSend == "A",
        },
        ebookSearch: {
          enabled: this.ebookSearch.enabled,
          errorsAsWarnings: this.ebookSearch.errorsAsWarnings == "W",
          alwaysSend: this.ebookSearch.alwaysSend == "A",
        },
      };

      // keep non-modified settings
      let mediaSettings = this.mediaSettings ?? {};
      let imageSettings = mediaSettings.image ?? {};
      for (const prop in imageSettings) {
        if (
          prop.search(/errorsAsWarnings|allowResize|allowUpscale|alwaysSend/) ==
          -1
        ) {
          newSettings.image[prop] = imageSettings[prop];
        }
      }

      let ebookSettings = mediaSettings.ebook ?? {};
      for (const prop in ebookSettings) {
        if (prop.search(/enabled|errorsAsWarnings|alwaysSend/) == -1) {
          newSettings.ebook[prop] = ebookSettings[prop];
        }
      }

      let ebookSearchSettings = mediaSettings.ebookSearch ?? {};
      for (const prop in ebookSearchSettings) {
        if (prop.search(/enabled|errorsAsWarnings|alwaysSend/) == -1) {
          newSettings.ebookSearch[prop] = ebookSearchSettings[prop];
        }
      }

      for (const channel in mediaSettings) {
        if (channel.search(/image|ebook|ebookSearch/) == -1) {
          newSettings[channel] = mediaSettings[channel];
        }
      }

      this.$emit("change", newSettings);
    },
  },
};
</script>

<style scoped>
input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
}
</style>
