<template>
  <b-dropdown
    v-if="productId"
    text="Edit"
    variant="none"
    toggle-class="btn-link m-2"
  >
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-title/' + productId + linkSuffix"
    >
      Title &amp; Identifiers
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-biblio/' + productId + linkSuffix"
    >
      Bibliographic
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-format/' + productId + linkSuffix"
    >
      Format
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-contrib/' + productId + linkSuffix"
    >
      Contributors
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-subject/' + productId + linkSuffix"
    >
      Subjects &amp; Audience
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-fulltext/' + productId + linkSuffix"
    >
      Full Text
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-media/' + productId + linkSuffix"
    >
      Image/Media Files
    </b-dropdown-item>
    <b-dropdown-item
      :href="
        $baseUrl + 'biblio-edit/edit-contentitems/' + productId + linkSuffix
      "
    >
      Content Items
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-rights/' + productId + linkSuffix"
    >
      Territorial Rights
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-supply/' + productId + linkSuffix"
    >
      Suppliers &amp; Prices
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-market/' + productId + linkSuffix"
    >
      Market Representation
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-prizes/' + productId + linkSuffix"
    >
      Prizes &amp; Promotion
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-related/' + productId + linkSuffix"
    >
      Related Products
    </b-dropdown-item>
    <b-dropdown-item
      :href="
        $baseUrl + 'biblio-edit/edit-productcontacts/' + productId + linkSuffix
      "
    >
      Product Contacts
    </b-dropdown-item>
    <b-dropdown-item
      :href="$baseUrl + 'biblio-edit/edit-notes/' + productId + linkSuffix"
    >
      Additional Notes
    </b-dropdown-item>
    <b-dropdown-item
      v-if="workflowType == 'L' && $permitted('workflow/view')"
      :href="$baseUrl + 'biblio-edit/edit-workflow/' + productId + linkSuffix"
    >
      Workflow
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$permitted('foreignrights/view')"
      :href="$baseUrl + 'foreignrights/edit/' + productId + linkSuffix"
    >
      Foreign Rights
    </b-dropdown-item>
    <b-dropdown-item
      v-if="customForm"
      :href="$baseUrl + 'biblio-edit/edit-custom/' + productId + linkSuffix"
    >
      {{ customForm }}
    </b-dropdown-item>
    <b-dropdown-divider v-if="canSynchronise"></b-dropdown-divider>
    <b-dropdown-item
      v-if="canSynchronise"
      href="#"
      @click="$emit('synchronise', $event)"
    >
      Synchronise
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
/**
 * Displays a dropdown list of quick edit
 * links for a product
 */
export default {
  name: "ProductEditLinksDropdown",

  props: {
    /**
     * The id of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Custom form exists and its title
     */
    customForm: {
      type: String,
      default: null,
    },

    /**
     * Can we synchronise?
     */
    canSynchronise: {
      type: Boolean,
      default: false,
    },

    /**
     * What type of workflow is in use. C for Cake and L for legacy.
     */
    workflowType: {
      type: String,
      default: "",
    },
  },

  computed: {
    linkSuffix: function () {
      return this.returnTo ? "?return=" + this.returnTo : "";
    },
  },
};
</script>

<style></style>
