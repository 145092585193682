<template>
  <b-card id="contactCardContact">
    <template #header>
      <a id="contact" />
      <h5 class="d-inline-block">Contact</h5>
      <edit-icon
        v-if="$permitted('contacts/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/contacts/edit/' +
          contactId +
          '?current_tab=2' +
          (returnTo ? '&return=' + returnTo : '')
        "
      ></edit-icon>
      <contact-quick-jump-dropdown
        class="float-right m-0"
        :contact-id="contactId"
      ></contact-quick-jump-dropdown>
    </template>
    <dl v-if="contact" class="row">
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Work Address
        {{ contact.BusinessAddress_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="workAddress"></div>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Home Address
        {{ contact.HomeAddress_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="homeAddress"></div>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Other Address
        {{ contact.OtherAddress_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="otherAddress"></div>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Work E-mail
        {{ contact.BusinessEmail_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="contact.BusinessEmail"
          :href="'mailto:' + contact.BusinessEmail"
          >{{ contact.BusinessEmail }} <i class="fa-solid fa-envelope"></i
        ></a>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Home E-mail
        {{ contact.HomeEmail_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <a v-if="contact.HomeEmail" :href="'mailto:' + contact.HomeEmail"
          >{{ contact.HomeEmail }} <i class="fa-solid fa-envelope"></i
        ></a>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Other E-mail
        {{ contact.OtherEmail_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        <a v-if="contact.OtherEmail" :href="'mailto:' + contact.OtherEmail"
          >{{ contact.OtherEmail }} <i class="fa-solid fa-envelope"></i
        ></a>
        <template v-else>—</template>
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Work Telephone
        {{ contact.BusinessPhone_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.BusinessPhone | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Work Mobile
        {{ contact.BusinessMobile_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.BusinessMobile | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Home Telephone
        {{ contact.HomePhone_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.HomePhone | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Home Mobile
        {{ contact.HomeMobile_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.HomeMobile | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Other Telephone
        {{ contact.OtherPhone_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.OtherPhone | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Other Mobile
        {{ contact.OtherMobile_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.OtherMobile | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Work Fax
        {{ contact.BusinessFax_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.BusinessFax | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Home Fax
        {{ contact.HomeFax_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.HomeFax | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">
        Other Fax
        {{ contact.OtherFax_IsDefault == "Y" ? " (default)" : "" }}
      </dt>
      <dd class="col-sm-9">
        {{ contact.OtherFax | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">MSN</dt>
      <dd class="col-sm-9">
        {{ contact.InstantMessagingMsn | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Yahoo</dt>
      <dd class="col-sm-9">
        {{ contact.InstantMessagingYahoo | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Skype</dt>
      <dd class="col-sm-9">
        {{ contact.InstantMessagingSkype | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">Google</dt>
      <dd class="col-sm-9">
        {{ contact.InstantMessagingGoogle | valueOrPlaceholder }}
      </dd>
      <dt class="col-sm-3 font-weight-bold text-secondary">AOL</dt>
      <dd class="col-sm-9">
        {{ contact.InstantMessagingAol | valueOrPlaceholder }}
      </dd>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a descriptive list of contact data
 */
import ContactQuickJumpDropdown from "./ContactQuickJumpDropdown.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ContactDescriptiveListContact",

  components: {
    "contact-quick-jump-dropdown": ContactQuickJumpDropdown,
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Enable or disable edit links
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    workAddress: function () {
      let address = "";
      address += this.contact.BusinessAddress_Department
        ? this.contact.BusinessAddress_Department + "<br>"
        : "";
      address += this.contact.BusinessAddress_BuildingName
        ? this.contact.BusinessAddress_BuildingName + "<br>"
        : "";
      address += this.contact.BusinessAddress_BuildingNumber
        ? this.contact.BusinessAddress_BuildingNumber + " "
        : "";
      address += this.contact.BusinessAddress_Street ?? "";
      address +=
        this.contact.BusinessAddress_BuildingNumber ||
        this.contact.BusinessAddress_Street
          ? "<br>"
          : "";
      address += this.contact.BusinessAddress_Place
        ? this.contact.BusinessAddress_Place + "<br>"
        : "";
      address += this.contact.BusinessAddress_Town
        ? this.contact.BusinessAddress_Town + "<br>"
        : "";
      address += this.contact.BusinessAddress_Province
        ? this.contact.BusinessAddress_Province + "<br>"
        : "";
      address += this.contact.BusinessAddress_Region
        ? this.contact.BusinessAddress_Region + "<br>"
        : "";
      address += this.contact.BusinessAddress_Country
        ? this.contact.BusinessAddress_Country + "<br>"
        : "";
      address += this.contact.BusinessAddress_PostCode
        ? this.contact.BusinessAddress_PostCode + "<br>"
        : "";
      return address ? address : "—";
    },
    homeAddress: function () {
      let address = "";
      address += this.contact.HomeAddress_Department
        ? this.contact.HomeAddress_Department + "<br>"
        : "";
      address += this.contact.HomeAddress_BuildingName
        ? this.contact.HomeAddress_BuildingName + "<br>"
        : "";
      address += this.contact.HomeAddress_BuildingNumber
        ? this.contact.HomeAddress_BuildingNumber + " "
        : "";
      address += this.contact.HomeAddress_Street ?? "";
      address +=
        this.contact.HomeAddress_BuildingNumber ||
        this.contact.HomeAddress_Street
          ? "<br>"
          : "";
      address += this.contact.HomeAddress_Place
        ? this.contact.HomeAddress_Place + "<br>"
        : "";
      address += this.contact.HomeAddress_Town
        ? this.contact.HomeAddress_Town + "<br>"
        : "";
      address += this.contact.HomeAddress_Province
        ? this.contact.HomeAddress_Province + "<br>"
        : "";
      address += this.contact.HomeAddress_Region
        ? this.contact.HomeAddress_Region + "<br>"
        : "";
      address += this.contact.HomeAddress_Country
        ? this.contact.HomeAddress_Country + "<br>"
        : "";
      address += this.contact.HomeAddress_PostCode
        ? this.contact.HomeAddress_PostCode + "<br>"
        : "";
      return address ? address : "—";
    },
    otherAddress: function () {
      let address = "";
      address += this.contact.OtherAddress_Department
        ? this.contact.OtherAddress_Department + "<br>"
        : "";
      address += this.contact.OtherAddress_BuildingName
        ? this.contact.OtherAddress_BuildingName + "<br>"
        : "";
      address += this.contact.OtherAddress_BuildingNumber
        ? this.contact.OtherAddress_BuildingNumber + " "
        : "";
      address += this.contact.OtherAddress_Street ?? "";
      address +=
        this.contact.OtherAddress_BuildingNumber ||
        this.contact.OtherAddress_Street
          ? "<br>"
          : "";
      address += this.contact.OtherAddress_Place
        ? this.contact.OtherAddress_Place + "<br>"
        : "";
      address += this.contact.OtherAddress_Town
        ? this.contact.OtherAddress_Town + "<br>"
        : "";
      address += this.contact.OtherAddress_Province
        ? this.contact.OtherAddress_Province + "<br>"
        : "";
      address += this.contact.OtherAddress_Region
        ? this.contact.OtherAddress_Region + "<br>"
        : "";
      address += this.contact.OtherAddress_Country
        ? this.contact.OtherAddress_Country + "<br>"
        : "";
      address += this.contact.OtherAddress_PostCode
        ? this.contact.OtherAddress_PostCode + "<br>"
        : "";
      return address ? address : "—";
    },
  },
};
</script>

<style></style>
