<template>
  <div>
    <ul class="biblio_resultslist_menubar">
      <li v-if="ftpCatalogueDetails !== null">
        <a
          :href="
            $baseUrl +
            'ftp-catalogue-feed/edit-search/' +
            ftpCatalogueDetails.mode +
            '/' +
            ftpCatalogueDetails.id
          "
          class="btn btn-link"
        >
          <i class="fa fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li v-else-if="searchId && productId">
        <a
          :href="$baseUrl + 'search/unlink/' + searchId + '/' + page"
          class="btn btn-link"
        >
          <i class="fa fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li v-else-if="searchId">
        <a :href="$baseUrl + 'search/unlink/' + searchId" class="btn btn-link">
          <i class="fa fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li v-if="ftpCatalogueDetails !== null">
        <a
          :href="
            $baseUrl +
            'ftp-catalogue-feed/edit-search/' +
            ftpCatalogueDetails.mode +
            '/' +
            ftpCatalogueDetails.id
          "
          class="btn btn-link"
        >
          <i class="fa fa-magnifying-glass"></i>
          Search Form
        </a>
      </li>
      <li v-else-if="searchId && hasSearchForm">
        <a :href="$baseUrl + 'search/form/' + searchId" class="btn btn-link">
          <i class="fa fa-magnifying-glass"></i>
          Search Form
        </a>
      </li>
      <li v-else-if="searchForm && searchForm != 'biblio-template'">
        <a :href="$baseUrl + 'search/form/' + searchForm" class="btn btn-link">
          <i class="fa fa-magnifying-glass"></i>
          Search Form
        </a>
      </li>
      <li v-if="searchId && productId">
        <a :href="resultsListLink" class="btn btn-link">
          <i class="fa fa-list-ul"></i>
          Results List
        </a>
      </li>
      <li v-if="searchId && productStatus !== 'deleted'">
        <a class="btn btn-link" href="#" @click="$emit('sortBtnClick', $event)">
          <i class="fa fa-sort"></i>
          Sort
        </a>
      </li>
      <b-dropdown v-if="!productId" variant="none" toggle-class="btn-link">
        <template #button-content>
          <i class="fa fa-check-double"></i> Mark
        </template>
        <b-dropdown-item @click="$emit('markclick', $event, 'all')">
          All
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('markclick', $event, 'none')">
          None
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('markclick', $event, 'reverse')">
          Reverse
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        v-if="productId && productStatus !== 'deleted' && productStatus !== 'D'"
        text="Formats"
        variant="none"
        toggle-class="btn-link"
      >
        <b-dropdown-item href="#" @click="$emit('formatChanged', 'brief')"
          >Brief</b-dropdown-item
        >
        <b-dropdown-item href="#" @click="$emit('formatChanged', 'full')"
          >Full</b-dropdown-item
        >
        <b-dropdown-item href="#" @click="$emit('formatChanged', 'all')"
          >All Fields</b-dropdown-item
        >
      </b-dropdown>
      <template v-if="searchForm == 'biblio-template'">
        <product-edit-links-dropdown
          v-if="productId"
          :product-id="productId"
          :return-to="
            '/biblio/detailed/' +
            productId +
            '/' +
            searchId +
            '/' +
            page +
            (format != '' ? '/' + format : '')
          "
          :custom-form="customForm"
        >
        </product-edit-links-dropdown>
        <li>
          <a
            class="btn btn-link"
            :href="$baseUrl + 'biblio-new/new-template/' + searchId"
          >
            New
          </a>
        </li>
        <li v-if="productId">
          <a class="btn btn-link" href="#" @click="copyTemplate()">Copy</a>
        </li>
        <li v-if="productId">
          <a
            class="btn btn-link"
            href="#"
            @click="changeStatus('delete-template', 'a')"
          >
            Delete
          </a>
        </li>
        <b-dropdown v-else variant="none" toggle-class="btn-link" text="Delete">
          <b-dropdown-item @click="changeStatus('delete-template', 'a')">
            All
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatus('delete-template', 'm')">
            Marked
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatus('delete-template', 'u')">
            Unmarked
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template v-else>
        <li v-if="productStatus !== 'deleted' && productStatus !== 'D'">
          <a
            class="btn btn-link"
            :class="!markedRecordsActionsEnabled ? 'disabled' : null"
            data-v-step="biblio-searchresults-tag"
            @click="tagBtnClicked($event)"
          >
            <i class="fa fa-tag"></i>
            Tag
          </a>
        </li>
        <template v-if="productStatus !== 'deleted'">
          <b-dropdown
            v-if="
              productId && $permitted('biblio/edit') && productStatus !== 'D'
            "
            text="Copy"
            variant="none"
            toggle-class="btn-link"
          >
            <b-dropdown-item @click="copyRecord('normal')"
              >Normal</b-dropdown-item
            >
            <b-dropdown-item @click="copyRecord('altformat')"
              >Alternate Format</b-dropdown-item
            >
            <b-dropdown-item @click="copyRecord('newedition')"
              >New Edition</b-dropdown-item
            >
          </b-dropdown>
          <template v-if="productId">
            <b-dropdown
              v-if="$permitted('biblio/approve') && productStatus != 'D'"
              text="Status"
              variant="none"
              toggle-class="btn-link"
            >
              <b-dropdown-item href="#" @click="changeStatus('approve', 'a')">
                Approve
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeStatus('unapprove', 'a')">
                Unapprove
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$permitted('biblio/archive')"
                href="#"
                @click="changeStatus('archive', 'a')"
              >
                Archive
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-else-if="$permitted('biblio/archive') && productStatus == 'D'"
              text="Status"
              variant="none"
              toggle-class="btn-link"
            >
              <b-dropdown-item href="#" @click="changeStatus('restore', 'a')">
                Restore
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="changeStatus('delete', 'a')">
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-else>
            <b-dropdown
              v-if="$permitted('biblio/approve')"
              variant="none"
              toggle-class="btn-link"
            >
              <template #button-content
                ><i class="fa-regular fa-circle-check"></i> Approve</template
              >
              <b-dropdown-item href="#" @click="changeStatus('approve', 'a')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('approve', 'm')"
                >Marked</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('approve', 'u')"
                >Unmarked</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              v-if="$permitted('biblio/approve')"
              variant="none"
              toggle-class="btn-link"
            >
              <template #button-content
                ><i class="fa-regular fa-circle-xmark"></i> Unapprove</template
              >
              <b-dropdown-item href="#" @click="changeStatus('unapprove', 'a')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('unapprove', 'm')"
                >Marked</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('unapprove', 'u')"
                >Unmarked</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              v-if="$permitted('biblio/archive')"
              variant="none"
              toggle-class="btn-link"
            >
              <template #button-content
                ><i class="fa-solid fa-trash"></i> Archive</template
              >
              <b-dropdown-item href="#" @click="changeStatus('archive', 'a')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('archive', 'm')"
                >Marked</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('archive', 'u')"
                >Unmarked</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              v-if="$permitted('biblio/archive') && !allActive"
              variant="none"
              toggle-class="btn-link"
            >
              <template #button-content
                ><i class="fa fa-trash-arrow-up"></i> Unarchive</template
              >
              <b-dropdown-item href="#" @click="changeStatus('restore', 'a')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('restore', 'm')"
                >Marked</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('restore', 'u')"
                >Unmarked</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              v-if="$permitted('biblio/archive') && !allActive"
              variant="none"
              toggle-class="btn-link"
            >
              <template #button-content
                ><i class="fa-solid fa-trash"></i> Delete</template
              >
              <b-dropdown-item href="#" @click="changeStatus('delete', 'a')"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('delete', 'm')"
                >Marked</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                :disabled="!markedRecordsActionsEnabled"
                @click="changeStatus('delete', 'u')"
                >Unmarked</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </template>
        <product-edit-links-dropdown
          v-if="
            productId &&
            $permitted('biblio/edit') &&
            productStatus !== 'deleted' &&
            productStatus !== 'D'
          "
          :product-id="productId"
          :return-to="
            '/biblio/detailed/' +
            productId +
            '/' +
            searchId +
            '/' +
            page +
            (format != '' ? '/' + format : '')
          "
          :custom-form="customForm"
          :can-synchronise="canSynchronise"
          :workflow-type="workflowType"
          @synchronise="synchroniseClicked"
        >
        </product-edit-links-dropdown>
        <li
          v-if="
            productId && productStatus !== 'deleted' && productStatus !== 'D'
          "
        >
          <b-dropdown variant="none" toggle-class="btn-link">
            <template #button-content>
              <i class="fa fa-check-double"></i> Mark
            </template>
            <b-dropdown-item @click="$emit('markclick', $event, 'all')">
              All
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('markclick', $event, 'none')">
              None
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('markclick', $event, 'reverse')">
              Reverse
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <b-dropdown
          v-if="
            productId &&
            searchId &&
            productStatus !== 'deleted' &&
            productStatus !== 'D'
          "
          text="Output"
          variant="none"
          toggle-class="btn-link"
        >
          <b-dropdown-item
            :href="
              $baseUrl +
              'biblio/output/' +
              searchId +
              '/' +
              page +
              '/download/' +
              encodeURIComponent(productTitle)
            "
            target="_blank"
            >View PDF</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="outputEmailClicked"
            >Email PDF</b-dropdown-item
          >
        </b-dropdown>
        <b-button
          v-if="
            searchId &&
            $permitted('catalogue/view') &&
            productStatus !== 'deleted' &&
            productStatus !== 'D' &&
            searchForm != 'biblio-archive'
          "
          variant="link"
          @click="createMaterialsClicked"
          >Create Materials</b-button
        >
        <template
          v-if="
            $permitted([
              'export/userdefined',
              'export/spreadsheet',
              'export/onix',
              'export/dissemination',
              'workflow/view',
            ]) &&
            productStatus !== 'deleted' &&
            productStatus !== 'D' &&
            searchForm != 'biblio-archive'
          "
        >
          <b-dropdown
            v-if="searchId"
            variant="none"
            toggle-class="btn-link"
            data-v-step="export-btn"
          >
            <template #button-content
              ><i class="fa fa-download"></i> Export</template
            >
            <b-dropdown-item
              v-if="$permitted('export/spreadsheet')"
              href="#"
              @click="exportClicked('spreadsheet')"
              >Industry Standard</b-dropdown-item
            >
            <b-dropdown-item
              v-if="$permitted('export/userdefined')"
              href="#"
              @click="exportClicked('textfile')"
              >User Defined</b-dropdown-item
            >
            <b-dropdown-item
              v-if="$permitted('export/onix')"
              href="#"
              @click="exportClicked('xml')"
              >Industry Standard XML</b-dropdown-item
            >
            <b-dropdown-item
              v-if="$permitted('export/dissemination')"
              href="#"
              @click="exportClicked('dissemination')"
              >Dissemination to 3rd Parties</b-dropdown-item
            >
            <b-dropdown-item
              v-if="$permitted('workflow/view')"
              href="#"
              @click="exportClicked('workflow-reports')"
              >Workflow Reports</b-dropdown-item
            >
            <b-dropdown-item
              v-if="$permitted('foreignrights/view')"
              href="#"
              @click="exportClicked('foreignrights-reports')"
              >Foreign Rights Reports</b-dropdown-item
            >
            <template
              v-if="
                searchFormType == 'form' && $featureEnabled('ftpCatalogueFeeds')
              "
            >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                :disabled="!$permitted('ftp-catalogue/edit')"
                :href="ftpCatalogueEditLink"
                >Create Facebook Catalogue Feed</b-dropdown-item
              ></template
            >
          </b-dropdown>
          <li v-if="ftpCatalogueDetails">
            <a :href="ftpCatalogueEditLink" class="btn btn-link">
              <i class="fa-solid fa-save"></i>
              Save FTP Catalogue
            </a>
          </li>
        </template>
      </template>
      <!-- hidden link to refresh the page -->
      <li v-if="searchId && page">
        <a
          ref="refreshLink"
          :href="
            $baseUrl +
            'biblio/' +
            (productId ? 'detailed/' + productId : 'results-list') +
            '/' +
            searchId +
            '/' +
            page +
            (format != '' ? '/' + format : '')
          "
          class="biblio_resultslist_hiddenlink"
        >
        </a>
      </li>
    </ul>
    <div
      :id="'statuschangemodal_' + location"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 ref="modalTitle" class="modal-title"></h4>
            <button
              ref="modalCloseBtn"
              type="button"
              class="close"
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                ref="modalMessage"
                class="col-12 statuschangemodal-message"
              ></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              ref="modalOkBtn"
              type="button"
              class="btn btn-success"
              @click="doChangeStatus"
            >
              OK
            </button>
            <button
              ref="modalCancelBtn"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <edit-tags-modal
      :id="tagModalId"
      :ref="tagModalId"
      :record-ids="markedRecordIds"
      :marked="!productId"
      @tagsApplied="onTagsApplied"
      @tagCreated="onTagCreated"
    ></edit-tags-modal>
    <marketing-materials-modal
      :id="marketingMaterialsModalId"
      :ref="marketingMaterialsModalId"
      :search-id="searchId"
      :position-type="productId ? '' : 'p'"
      :position="page"
      :display-format="format"
    ></marketing-materials-modal>
    <export-modal
      :id="exportModalId"
      :ref="exportModalId"
      :search-id="searchId"
      :position-type="productId ? '' : 'p'"
      :position="page"
      :display-format="format"
      :type="exportType"
    ></export-modal>
    <email-detailed-record-pdf-modal
      v-if="productId"
      :id="emailBiblioPdfModalId"
      :ref="emailBiblioPdfModalId"
      :search-id="searchId"
      :position="page"
    ></email-detailed-record-pdf-modal>
  </div>
</template>

<script>
/**
 * Display the Biblio Menubar line
 */
import { HTTP } from "../../http-common.js";
import EditTagsModal from "../pages/modals/EditTagsModal.vue";
import MarketingMaterialsModal from "../pages/modals/MarketingMaterialsModal.vue";
import EmailDetailedRecordPdfModal from "../pages/modals/EmailDetailedRecordPdfModal.vue";
import ProductEditLinksDropdown from "./ProductEditLinksDropdown.vue";
import ExportModal from "../pages/modals/ExportModal.vue";
import { DateTime } from "luxon";

export default {
  name: "MenuBar",

  components: {
    "edit-tags-modal": EditTagsModal,
    "marketing-materials-modal": MarketingMaterialsModal,
    "product-edit-links-dropdown": ProductEditLinksDropdown,
    "export-modal": ExportModal,
    "email-detailed-record-pdf-modal": EmailDetailedRecordPdfModal,
  },

  props: {
    /**
     * A unique location identifier for this menubar
     */
    location: {
      type: String,
      required: true,
    },

    /**
     * Search form name
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     *  Search type ('form', 'form-tag', 'quick', 'link')
     */
    searchFormType: {
      type: String,
      default: "form",
    },

    /**
     * Has the search a search form?
     */
    hasSearchForm: {
      type: Boolean,
      default: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Are all records active?
     */
    allActive: {
      type: Boolean,
      default: false,
    },

    /**
     * Unique ID / Record Number for a product
     *
     * Passing a productId will enforce actions such as tagging
     * and status change to only apply to the supplier ID.
     * This is used for detailed display.
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Results list page number
     */
    resultsListPage: {
      type: Number,
      default: null,
    },

    /**
     * Display format
     */
    format: {
      type: String,
      default: "",
    },

    /**
     * Custom form exists and its title
     */
    customForm: {
      type: String,
      default: null,
    },

    /**
     * Actions which require there to be marked records
     * can be disabled using this flag
     */
    markedRecordsActionsEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Marked Records object
     */
    markedRecords: {
      type: Object,
      default: null,
    },

    /**
     * As an alternative to updating parent component
     * with events, a page refresh can be triggered.
     *
     * Set to false to prevent this.
     */
    usePageRefresh: {
      type: Boolean,
      default: true,
    },

    /**
     * Display setting for tab content
     * @values all, full, brief
     */
    display: {
      type: String,
      default: null,
    },

    /**
     * The product status when viewing a detailed
     * display
     */
    productStatus: {
      type: String,
      default: null,
    },

    /**
     * The product title when viewing a detailed
     * display
     */
    productTitle: {
      type: String,
      default: null,
    },

    /**
     * Can we synchronise?
     */
    canSynchronise: {
      type: Boolean,
      default: false,
    },

    /**
     * Object containing details of the FTP catalogue. If this is present
     * then the search form and results are being used to update the search
     * configuration on an existing catalogue.
     *
     * @property {string} mode The mode of the catalogue
     * @property {int} id The ID of the catalogue
     */
    ftpCatalogueDetails: {
      type: Object,
      default: null,
    },

    /**
     * What type of workflow is in use. C for Cake and L for legacy.
     */
    workflowType: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      statusChangeType: null,
      statusChangeWhich: null,
      tagModalId: "edit-tags-modal-" + this.location,
      marketingMaterialsModalId: "marketing-materials-modal-" + this.location,
      exportModalId: "export-modal-" + this.location,
      emailBiblioPdfModalId: "email-biblio-pdf-modal-" + this.location,
      exportType: null,
    };
  },

  computed: {
    markedRecordIds: function () {
      if (this.productId) {
        return [this.productId];
      }
      if (this.markedRecords == null) {
        return null;
      }
      return this.markedRecords.getAll();
    },
    resultsListLink: function () {
      let link =
        this.$baseUrl +
        "biblio/results-list/" +
        this.searchId +
        "/" +
        (this.resultsListPage ? this.resultsListPage : 1);
      if (this.ftpCatalogueDetails !== null) {
        link +=
          "?ftpCatalogueMode=" +
          this.ftpCatalogueDetails.mode +
          "&ftpCatalogueId=" +
          this.ftpCatalogueDetails.id;
      }
      return link;
    },
    ftpCatalogueEditLink: function () {
      if (this.ftpCatalogueDetails !== null) {
        return (
          this.$baseUrl +
          "ftp-catalogue-feed/edit/" +
          this.ftpCatalogueDetails.mode +
          "/" +
          this.ftpCatalogueDetails.id +
          "/" +
          this.searchId
        );
      }
      return (
        this.$baseUrl + "ftp-catalogue-feed/edit/facebook/0/" + this.searchId
      );
    },
  },

  mounted: function () {
    /**
     * Make the edit tag modal a global ref
     */
    this.$root.$refs[this.tagModalId] = this.$refs[this.tagModalId];
    /**
     * Make the marketing materials modal a global ref
     */
    this.$root.$refs[this.marketingMaterialsModalId] =
      this.$refs[this.marketingMaterialsModalId];
  },

  methods: {
    /**
     * Change status
     */
    changeStatus: function (type, which) {
      // setup the modal to ask the user
      let whichDesc =
        which == "m" ? " marked" : which == "u" ? " unmarked" : "";
      if (type == "delete" || type == "delete-template") {
        this.$refs.modalTitle.innerHTML = "Delete" + whichDesc + " records";
        if (this.productId !== null) {
          this.$refs.modalMessage.innerHTML =
            "Are you sure that you want to permanently delete this record?<br><br>" +
            "Please note that once a record has been permanently deleted, it cannot be restored.";
        } else {
          this.$refs.modalMessage.innerHTML =
            "Are you sure that you want to permanently delete all the" +
            whichDesc +
            " records in your search results?<br>" +
            "Any records which have been restored will be archived instead of permanently deleted.<br><br>" +
            "Please note that once a record has been permanently deleted, it cannot be restored.";
        }
        this.$refs.modalMessage.className = "statuschangemodal-message";
      } else {
        this.$refs.modalTitle.innerHTML =
          type.substring(0, 1).toUpperCase() +
          type.substring(1) +
          whichDesc +
          " records";
        this.$refs.modalMessage.innerHTML =
          "Are you sure that you want to " +
          type +
          (this.productId !== null
            ? " this record?"
            : " all the" + whichDesc + " records in your search results?");
        this.$refs.modalMessage.className = "statuschangemodal-message";
      }

      this.$refs.modalCloseBtn.disabled = false;
      this.$refs.modalOkBtn.disabled = false;
      this.$refs.modalCancelBtn.disabled = false;
      window.$("#statuschangemodal_" + this.location).modal("show");

      // remember what we are doing
      this.statusChangeType = type;
      this.statusChangeWhich = which;
    },

    /**
     * Do status change
     */
    doChangeStatus: function () {
      let type = this.statusChangeType;
      let which = this.statusChangeWhich;

      // disable buttons
      this.$refs.modalCloseBtn.disabled = true;
      this.$refs.modalOkBtn.disabled = true;
      this.$refs.modalCancelBtn.disabled = true;

      // update message
      let whichDesc =
        which == "m" ? " marked" : which == "u" ? " unmarked" : "";
      this.$refs.modalMessage.innerHTML =
        type.substring(0, 1).toUpperCase() +
        type.substring(1, type.length - 1) +
        "ing" +
        whichDesc +
        " records&hellip;";

      // perform status change
      let postData = new URLSearchParams();
      let productsString = "";
      if (this.productId !== null) {
        productsString = this.productId;
      } else {
        if (this.markedRecords == null) {
          return;
        }
        if (which == "m") {
          productsString = this.markedRecords.getAll().join(",");
        } else if (which == "u") {
          productsString =
            "@" + this.searchId + "-" + this.markedRecords.getAll().join(",");
        } else {
          productsString = "@" + this.searchId;
        }
      }
      postData.append("P", productsString);

      let url = this.$baseUrl + "biblio-edit/status-change/" + type;
      HTTP.post(url, postData)
        .then((response) => {
          if (response.data == "NONE") {
            // nothing to do, show message
            this.$refs.modalMessage.innerHTML =
              "There are no" +
              whichDesc +
              " records to " +
              this.statusChangeType;

            // enable close/cancel buttons
            this.$refs.modalCloseBtn.disabled = false;
            this.$refs.modalCancelBtn.disabled = false;
          } else {
            // success
            window.$("#statuschangemodal_" + this.location).modal("hide");
            if (this.usePageRefresh) {
              this.$refs.refreshLink.click();
            }
            /**
             * Status changed event
             *
             * @event
             *
             * @property {string} type The type of the new status
             * @property {string} products The product string of updated records
             */
            this.$emit(
              "statusChanged",
              type,
              productsString,
              this.$user,
              DateTime.now().toISO()
            );
          }
        })
        .catch(() => {
          // show message
          this.$refs.modalMessage.innerHTML =
            "An unexpected error has occured while trying to " +
            this.statusChangeType +
            " your records";
          this.$refs.modalMessage.className =
            "statuschangemodal-message statuschangemodal-message-error";

          // enable close/cancel buttons
          this.$refs.modalCloseBtn.disabled = false;
          this.$refs.modalCancelBtn.disabled = false;
        });
    },

    /**
     * Synchronises a record with all records with
     * the same TWI
     */
    synchroniseClicked: async function () {
      if (!this.productId) {
        return false;
      }
      let confirmation = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to synchronise the other records with the same Textual Work Identifier as this record?",
        {
          title: "Confirm",
          okVariant: "success",
          okTitle: "Yes",
          cancelVariant: "light",
          cancelTitle: "Cancel",
        }
      );

      if (!confirmation) {
        return false;
      }

      try {
        let response = await HTTP.put(
          this.$baseUrl + "biblio-edit/synchronise/" + this.productId
        );
        if (response.data == "OK") {
          this.$bvModal.msgBoxOk(
            "This record has been successfully Synchronised.",
            {
              title: "Confirmation",
              okVariant: "success",
            }
          );
        }
      } catch (error) {
        this.$bvModal.msgBoxOk("There was an error synchronising the record", {
          title: "Error",
          okVariant: "danger",
        });
      }
    },

    /**
     * Displays the tag modal when the tag action
     * is clicked
     */
    tagBtnClicked: function (event) {
      // show the tag modal
      this.$bvModal.show(this.tagModalId);
      this.$emit("tagsBtnClick", event);
    },

    /**
     * Triggers when tags have been saved in the
     * tag modal, and will refresh the data
     */
    onTagsApplied: function (tagsToRemove, tagsToAdd) {
      if (this.usePageRefresh) {
        // refresh the page after a slight delay to allow time for the apply-tags request to be sent
        window.setTimeout(() => {
          this.$refs.refreshLink.click();
        }, 1000);
      }

      /**
       * Tags applied event
       *
       * @event
       *
       * @property {array} tagsToRemove List of tagIds to remove from the product
       * @property {array} tagsToAdd List of tagIds to add to the product
       */
      this.$emit("tagsApplied", tagsToRemove, tagsToAdd);
    },

    /**
     * Triggers when a new tag is created within
     * the tag modal
     */
    onTagCreated: function (id, name, level) {
      /**
       * Tags created event
       *
       * @event
       *
       * @property {number} id The ID of the created tag
       * @property {string} name The name of the tag
       * @property {string} level The level of the tag 'USER' or 'GLOBAL'
       */
      this.$emit("tagCreated", id, name, level);
    },

    /**
     * Displays the marketing materials modal
     */
    createMaterialsClicked: function (event) {
      // show the create materials modal
      this.$emit("createMaterialsClicked", event);
      this.$refs[this.marketingMaterialsModalId].showModal(true);
    },

    /**
     * Displays the export modal
     */
    exportClicked: function (type) {
      // show the exports modal
      this.$emit("exportClicked", type);
      this.exportType = type;
      this.$refs[this.exportModalId].showModal(true);
    },

    /**
     * Displays the modal for sending
     * a product as an output
     */
    outputEmailClicked: function () {
      this.$emit("outputEmailClicked");
      this.$refs[this.emailBiblioPdfModalId].displayModal();
    },

    /**
     * Copies a record
     */
    copyRecord: async function (mode) {
      const copyUrl =
        this.$baseUrl +
        "biblio-new/copy-record/" +
        mode +
        "/" +
        this.searchId +
        "/" +
        this.page;

      let confirmation = await this.$bvModal.msgBoxConfirm(
        "Create a new record based on a copy of this record?",
        {
          title: "Confirm",
          okVariant: "success",
          okTitle: "Yes",
          cancelVariant: "light",
          cancelTitle: "Cancel",
        }
      );

      if (!confirmation) {
        return false;
      }

      window.location = copyUrl;
    },

    /**
     * Copies a template
     */
    copyTemplate: async function () {
      const copyUrl =
        this.$baseUrl +
        "biblio-new/new-template/" +
        this.searchId +
        "/" +
        this.page;

      let confirmation = await this.$bvModal.msgBoxConfirm(
        "Create a new template based on a copy of this template?",
        {
          title: "Confirm",
          okVariant: "success",
          okTitle: "Yes",
          cancelVariant: "light",
          cancelTitle: "Cancel",
        }
      );

      if (!confirmation) {
        return false;
      }

      window.location = copyUrl;
    },
  },
};
</script>

<style scoped>
ul.biblio_resultslist_menubar {
  margin: 2px 0px;
  padding-left: 5px;
}
ul.biblio_resultslist_menubar > li {
  display: inline;
  list-style-type: none;
  padding-right: 5px;
}
a.biblio_resultslist_hiddenlink {
  visibility: hidden;
}
div.statuschangemodal-message {
  padding: 0px 15px;
}
div.statuschangemodal-message-error {
  color: red;
}
</style>
