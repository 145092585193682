<template>
  <div>
    <div v-show="loading == true">
      <loading-spinner text="Loading..."></loading-spinner>
    </div>
    <div v-show="loading != true" class="mb-5">
      <!-- @slot Table header content will appear above the table -->
      <slot name="header"></slot>
      <div v-if="tableDataError">
        <!-- @slot Content to display when there was an error getting table data -->
        <slot name="data-error">
          <p>An error has occurred while retrieving data</p>
        </slot>
      </div>
      <div v-else-if="!tableHasData">
        <!-- @slot Content to display when there is no data in the table -->
        <slot name="no-data-display">
          <p>No records found</p>
        </slot>
      </div>
      <div v-show="tableHasData">
        <div v-if="searchEnabled === true">
          <base-search-input
            v-if="!backendPaginationActive"
            id="tableSearch"
            v-model="search"
            style="max-width: 50%"
            class="mb-2"
          />
          <base-help-text v-if="!simplePagination">
            Filtered {{ filteredRowCount }} / {{ rowCount }} records
            <template v-if="rowsPerPage > 0 && hasPageRecordNumbers"
              >| Showing records {{ currentPageStart }} to
              {{ currentPageEnd }}</template
            >
          </base-help-text>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <ui-paginator
              v-if="rowsPerPage > 8"
              :initial-page="currentPage"
              :rows-per-page="rowsPerPage"
              :total-rows="filteredRowCount"
              :backend-pagination="dataSource ? false : backendPaginationActive"
              :simple="simplePagination"
              :has-next-page="hasNextPage"
              @page-change="pageChanged"
            ></ui-paginator>
          </div>
          <div class="col-12 col-lg-6">
            <base-button
              v-if="filtersEnabled === true"
              class="my-4 mr-2 float-right"
              @click="toggleFilters()"
              ><i class="fa fa-filter"
            /></base-button>
            <!-- @slot Display export buttons above the table -->
            <slot name="export-buttons">
              <export-buttons
                v-if="exportUrl != null"
                class="my-4 mr-2 float-right"
                :url="exportUrl"
              ></export-buttons>
            </slot>
            <!-- @slot Display action buttons above the table -->
            <slot name="action-buttons"> </slot>
          </div>
          <div class="col-12">
            <div
              v-if="searchEnabled === false && currentTotalRows > 0"
              class="mb-2"
            >
              <base-help-text>
                Total rows: {{ currentTotalRows }}
              </base-help-text>
            </div>
          </div>
        </div>
        <div v-if="filtersEnabled === true" v-show="displayFilters" class="row">
          <div class="col-12">
            <data-filters
              ref="dataFilters"
              :fields="filterColumns"
              :data="tableRecords"
              :filter-list-url="filtersUrl"
              :filter-save-url="filtersSaveUrl"
              :filter-delete-url="filtersDeleteUrl"
              :date-format="dateFormat"
              :applying-filters="applyingFilters"
              :backend-dropdown-options="dropdownOptions"
              @filters-updated="filtersUpdated"
            ></data-filters>
          </div>
        </div>
        <div class="table-container">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th
                  v-for="(column, key, index) in displayedColumns"
                  :key="index"
                  :style="{
                    'text-align': column.align ? column.align : 'left',
                    cursor:
                      backendPaginationActive && !backendSortingActive
                        ? 'default'
                        : 'pointer',
                  }"
                  @click="sort(column.key)"
                >
                  {{ column.text }}

                  <sort-icon
                    v-if="
                      currentSortedBy == column.key &&
                      (!backendPaginationActive || backendSortingActive)
                    "
                    :sort-direction="currentSortDirection"
                  />
                  <!--
                    @slot Slot at the end of the table heder, used to add a Cake infotip element
                    @binding {object} column The table column
                  -->
                  <slot :name="['info-tip-' + column.key]" :column="column">
                  </slot>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="record in tableData"
                :key="record.id"
                :class="
                  typeof rowClass === 'function' ? rowClass(record) : rowClass
                "
              >
                <td
                  v-for="(item, key, index) in getDisplayColumns(record)"
                  :key="index"
                  :style="{
                    'text-align': getColAlignment(key),
                  }"
                >
                  <!--
                    @slot Table cell slot to use a custom HTML template in a particular column table cell.
                    @binding {object} item The data record the table row
                  -->
                  <slot :name="[key]" :record="record">
                    <template v-if="getColDisplayAsHtml(key) === true">
                      <!-- eslint-disable vue/no-v-html -->
                      <div v-html="item"></div>
                      <!--eslint-enable-->
                    </template>
                    <template v-else>
                      <template v-if="getColLink(key) != null">
                        <a
                          target="_blank"
                          :href="parseColLink(record, getColLink(key))"
                          >{{ item }}</a
                        >
                      </template>
                      <template v-else-if="getColType(key) == 'date'">
                        <span class="text-nowrap">
                          {{
                            item
                              | parseDate(
                                dateFormat
                                  ? dateFormat
                                  : $userPreferences.dateFormat,
                                dateOptions
                              )
                          }}
                        </span>
                      </template>
                      <template v-else>
                        {{ item }}
                      </template>
                    </template>
                  </slot>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ui-paginator
          v-if="rowsPerPage > 0"
          :initial-page="currentPage"
          :rows-per-page="rowsPerPage"
          :total-rows="filteredRowCount"
          :backend-pagination="dataSource ? false : backendPaginationActive"
          :simple="simplePagination"
          :has-next-page="hasNextPage"
          @page-change="pageChanged"
        ></ui-paginator>
        <!-- @slot Table footer content will appear below the table -->
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Table for displaying a data grid, with sorting, filtering and pagination
 */

import Paginator from "./Paginator.vue";
import SortIcon from "./SortIcon.vue";
import ExportButtons from "./ExportButtons.vue";
import DataFilters from "../../utility/DataFilters/DataFilters.vue";
import _isString from "lodash/isString";
import _isNumber from "lodash/isNumber";
import _orderBy from "lodash/orderBy";
import _groupBy from "lodash/groupBy";
import { DateTime, Interval } from "luxon";
import ParseDate from "../../../mixins/parseDate.js";
import { HTTP } from "../../../http-common.js";

export default {
  name: "BaseTable",

  components: {
    "sort-icon": SortIcon,
    "ui-paginator": Paginator,
    "export-buttons": ExportButtons,
    "data-filters": DataFilters,
  },

  mixins: [ParseDate],

  props: {
    /**
     * The columns that will be included in the table.
     * Defines the table headers. The columns array should be an
     * array of objects, with each object containing the documented properties.
     * The order of the column objects in the array will be the order of
     * columns in the table.
     *
     * @property {String} text The text which will display in the table header
     * @property {String} key The data key for the column. There must be a
     *                        matching key in the data prop.
     * @property {String} [align] Alignment of the column. left, right or center
     * @property {String} [type] Data type of the column, 'text', 'number' or 'date', default 'text'
     * @property {String} [link] Provides a URL to set this column as a link. A key can be included within
     *                           the link by enclosing it in {}.
     * @property {Bool} [displayAsHtml] Set to true to display the contents of this column has HTML. Meaning
     *                                  HTML can be contained within the columns value. This setting will
     *                                  overrite any other display options such as 'link'
     *
     * @example [{text: 'Name', key: 'user_name', link: "/api/endpoint/{item_id}"}, {text: 'Email', key: 'user_email'}]
     */
    columns: {
      type: Array,
      default: null,
    },

    /**
     * The data that will be displayed in the table.
     * The data array should be an array of objects, with
     * each object having key / value pairs that match the keys
     * in the columns prop.
     *
     * @example [{user_name: 'Tester', user_email: 'tester@example.com'}]
     */
    initialData: {
      type: Array,
      default: null,
    },

    /**
     * URL which should be an API endpoint that returns data
     * for the table. If this is set then the initialData
     * prop will be ignored, and instead data will be retrieved
     * from this URL via ajax.
     *
     * It is assumed the API will return ALL data for the table.
     * If a single page of data is returned then pagination data
     * must be included and the backendPaginationActive prop must be
     * set to true.
     */
    dataSource: {
      type: String,
      default: null,
    },

    /**
     * URL which should be an API endpoint that downloads
     * an export file.
     *
     * The URL should be able to have a 'format' query parameter
     */
    exportSource: {
      type: String,
      default: null,
    },

    /**
     * URL which returns data for the data filters component. Data
     * returned should be a list of the users saved filters for the
     * table being viewed.
     */
    filtersUrl: {
      type: String,
      default: null,
    },

    /**
     * URL which provides a save functionality for the data filters
     * component. URL should accept the data posted by the component
     * and save to the appropriate table filter.
     */
    filtersSaveUrl: {
      type: String,
      default: null,
    },

    /**
     * URL which provides a delete functionality for the data filters
     * component. URL should accept a delete request wich will be passed
     * the active filter object which should then be deleted.
     */
    filtersDeleteUrl: {
      type: String,
      default: null,
    },

    /**
     * The default column the table is sorted by
     */
    sortedBy: {
      type: String,
      default: null,
    },

    /**
     * The default sort direction
     *
     * @values asc,desc
     */
    sortDirection: {
      type: String,
      default: "asc",
    },

    /**
     * Sets whether the search input is enabled
     */
    searchEnabled: {
      type: Boolean,
      default: true,
    },

    /**
     * Sets whether the filters are enabled
     */
    filtersEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The initial page the table should display
     */
    initialPage: {
      type: Number,
      default: 1,
    },

    /**
     * The total number of records in the table
     *
     * Only needs to be set with backend paginated data
     */
    totalRows: {
      type: Number,
      default: null,
    },

    /**
     * The number of records that should appear in a single table page
     * 0 indicates that there should be no pagination
     */
    rowsPerPage: {
      type: Number,
      default: 0,
    },

    /**
     * Sets whether backend pagination is used
     *
     * When enabled, pagination will generate HTML links rather than switching page via Javascript
     */
    backendPagination: {
      type: Boolean,
      default: false,
    },

    /**
     * Only used when backend pagination is active
     * Sets whether the backend is capable of providing a sort on the data
     */
    backendSorting: {
      type: Boolean,
      default: false,
    },

    /**
     * The record number at the start of the current page
     *
     * Only needed when backendPaginationActive is true. Provided by
     * CakePHPs paging parameter
     */
    paginationStart: {
      type: Number,
      default: null,
    },

    /**
     * The record number at the end of the current page
     *
     * Only needed when backendPaginationActive is true. Provided by
     * CakePHPs paging parameter
     */
    paginationEnd: {
      type: Number,
      default: null,
    },

    /**
     * Format to be used for dates. For this to be used
     * the 'type' property on a column must be set to 'date'.
     *
     * To change a date to this requested format the date must
     * be in a standard SQL YYYY-MM-DD format
     */
    dateFormat: {
      type: String,
      default: null,
    },

    /**
     * Contains the option list for filters on dropdown columns
     * Only required when filters and backend pagination is enabled
     * Normally the dropdown options can be calculation automatically
     * but this is not possible with backend pagination, and therefore
     * they must be provided. Any dropdown columns without options provided
     * will instead work as a text column.
     *
     * Object keys should match the column key for each dropdown type column
     * and the value should be an array of options
     *
     * @example {type: ['A','B','C'], status: ['In Progress', 'Complete', 'Inactive']}
     */
    dropdownOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },

    /**
     * Use this prop to apply a class to the table row
     *
     * This prop is bound using :class so it can be a string
     * or it can be a function. The function should either return
     * a string or a valid class object.
     */
    rowClass: {
      type: [String, Function],
      default: null,
    },
  },

  data() {
    return {
      search: "",
      currentPage: this.initialPage,
      tableRecords: [],
      loading: true,
      tableDataError: false,
      displayFilters: false,
      filters: [],
      filterId: "",
      currentSortedBy: this.sortedBy,
      currentSortDirection: this.sortDirection,
      backendPaginationActive: this.backendPagination,
      backendSortingActive: this.backendSorting,
      currentPaginationStart: this.paginationStart,
      currentPaginationEnd: this.paginationEnd,
      currentTotalRows: this.totalRows,
      backendFilteredRowCount: this.rowCount,
      simplePagination: false,
      hasNextPage: false,
      applyingFilters: false,
    };
  },

  computed: {
    filteredData: function () {
      let tableData = this.tableRecords;
      // search and filters need to trigger this computation
      let filters = this.filters;
      let search = this.search;
      if (this.backendPaginationActive) {
        return this.orderData(tableData);
      }
      return this.filterData(
        this.sortData(this.orderData(tableData)),
        search,
        filters
      );
    },

    /**
     * Filters out hidden columns to determine
     * which columns should be displayed;
     */
    displayedColumns: function () {
      let cols = [];
      for (const col of this.columns) {
        if (!col["hidden"]) {
          cols.push(col);
        }
      }
      return cols;
    },

    filterColumns: function () {
      if (this.backendPaginationActive) {
        // disable dropdown filters while backend pagination is active
        // unless the dropdown options have been provided as a prop
        let cols = [];
        for (const col of this.columns) {
          if (
            col["type"] == "dropdown" &&
            !(col["key"] in this.dropdownOptions)
          ) {
            col["type"] = "text";
          }
          cols.push(col);
        }
        return cols;
      }
      return this.columns;
    },

    tableData: function () {
      let tableData = this.filteredData;
      if (!this.backendPaginationActive) {
        return this.paginateData(tableData);
      }
      return tableData;
    },

    filteredRowCount: function () {
      if (!this.backendPaginationActive) {
        return this.filteredData.length;
      }
      // while backend pagination is calculated backend
      if (this.backendFilteredRowCount) {
        return this.backendFilteredRowCount;
      }
      return this.rowCount;
    },

    rowCount: function () {
      if (this.currentTotalRows) {
        return this.currentTotalRows;
      }
      if (!this.tableRecords) {
        return 0;
      }
      return this.tableRecords.length;
    },

    tableHasData: function () {
      return (
        (this.backendPaginationActive === true && this.currentTotalRows > 0) ||
        (this.backendPaginationActive && this.simplePagination) ||
        (!this.backendPaginationActive && this.rowCount > 0)
      );
    },

    exportUrl: function () {
      if (!this.filterId) {
        return this.exportSource;
      }
      return (
        this.exportSource +
        (this.exportSource.includes("?") ? "&" : "?") +
        "filter=" +
        this.filterId
      );
    },

    currentPageStart: function () {
      if (this.backendPaginationActive) {
        return this.currentPaginationStart;
      }
      // find the first element of the current page in the overall data
      return this.filteredData.indexOf(this.tableData[0]) + 1;
    },

    currentPageEnd: function () {
      if (this.backendPaginationActive) {
        return this.currentPaginationEnd;
      }
      // find the last element of the current page in the overall data
      return (
        this.filteredData.indexOf(this.tableData[this.tableData.length - 1]) + 1
      );
    },

    hasPageRecordNumbers: function () {
      if (this.currentPageStart && this.currentPageEnd) {
        return true;
      }
      return false;
    },
  },

  watch: {
    sortedBy(value) {
      this.currentSortedBy = value;
    },
    sortDirection(value) {
      this.currentSortDirection = value;
    },
    backendPagination(value) {
      this.backendPaginationActive = value;
    },
    backendSorting(value) {
      this.backendSortingActive = value;
    },
    paginationStart(value) {
      this.currentPaginationStart = value;
    },
    paginationEnd(value) {
      this.currentPaginationEnd = value;
    },
    totalRows(value) {
      this.currentTotalRows = value;
    },
    initialData(value) {
      this.tableRecords = value;
    },
  },

  mounted: function () {
    if (!this.dataSource) {
      this.tableRecords = this.initialData;
      this.loading = false;
      return;
    }
    this.getTableData()
      .then((response) => {
        this.tableRecords = response.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.tableDataError = true;
      });
  },

  methods: {
    /**
     * Sets the current sorted column and direction
     *
     * @param {String} s The column name to sort
     * @returns {void}
     */
    sort: function (s) {
      if (this.backendPaginationActive && !this.backendSortingActive) {
        // backend sorting is not enabled
        return;
      } else {
        if (s === this.currentSortedBy) {
          // column is currently sorted - switch sort direction
          this.currentSortDirection =
            this.currentSortDirection === "asc" ? "desc" : "asc";
        }
        this.currentSortedBy = s;

        if (this.backendPaginationActive && this.backendSortingActive) {
          // re-rertieve the data with updated sorting
          this.getTableData()
            .then((response) => {
              this.tableRecords = response.data;
            })
            .catch(() => {
              this.loading = false;
              this.tableDataError = true;
            });
        }
      }
    },

    /**
     * Orders the keys of an array to match the order of keys in
     * the columns prop. Any keys in the array not in the
     * columns prop will be marked as hidden.
     *
     * @param {Array} data The data to be ordered
     * @returns {any}
     */
    orderData: function (data) {
      let orderedData = [];
      if (!data) {
        return orderedData;
      }
      // create list of column keys (excluding hidden columns) so they can be used
      // to check which keys exist within the data. Object.entires always converts
      // keys to strings, so they need to be cast as strings in colKeys variable as well.
      const colKeys = this.columns.flatMap((x) =>
        !x.hidden ? [String(x.key)] : []
      );
      for (const item of data) {
        // build a new item using the provided cols
        const orderedItem = {};
        for (const column of this.columns) {
          orderedItem[column.key] = item[column.key];
        }
        // create a list of hidden columns that won't be displayed in the table
        orderedItem["hidden"] = {};
        for (const [key, value] of Object.entries(item)) {
          if (!colKeys.includes(key)) {
            orderedItem["hidden"][key] = value;
            if (key in orderedItem) {
              // unset the hidden key
              delete orderedItem[key];
            }
          }
        }
        orderedData.push(orderedItem);
      }
      return orderedData;
    },

    /**
     * Sorts an array of data using the current sorted column
     * and direction.
     *
     * @param {Array} data The data to sort
     * @returns {Array}
     */
    sortData: function (data) {
      let sortedData = data;
      if (!this.backendPaginationActive) {
        // check if the sort is on a numerical column
        let numericalSort = false;
        let sortSrc = this.currentSortedBy;
        let sortSrcHidden = false;
        for (const col of this.columns) {
          if (col["key"] == this.currentSortedBy) {
            if (col["sortsrc"]) {
              sortSrc = col["sortsrc"];
              if (data.length != 0 && !(sortSrc in data[0]) && data[0].hidden) {
                sortSrcHidden = true;
              }
            }
            if (col["type"] == "number") {
              numericalSort = true;
            }
            break;
          }
        }
        if (numericalSort) {
          return _orderBy(
            sortedData,
            (i) => {
              return parseInt(
                sortSrcHidden ? i.hidden[sortSrc] : i[sortSrc],
                10
              );
            },
            [this.currentSortDirection]
          );
        } else if (sortSrcHidden) {
          return _orderBy(sortedData, (i) => i.hidden[sortSrc], [
            this.currentSortDirection,
          ]);
        } else {
          return _orderBy(sortedData, [sortSrc], [this.currentSortDirection]);
        }
      }
      return sortedData;
    },

    /**
     * Filters an array of data based on the current search value
     * as well as the advanced filters
     *
     * @param {Array} data The data to filter
     * @returns {Array}
     */
    filterData: function (data, search, filters) {
      let filteredData = data;
      return filteredData.filter((s) => {
        let filtersApplied = false;
        if (search) {
          filtersApplied = true;
          for (const prop in s) {
            // check field matches the global search
            if (
              _isString(s[prop]) &&
              search &&
              s[prop].toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
        }
        // check field matches advanced filters
        let matchedFilters = [];
        for (const field of filters) {
          let skipFilter = false;
          let matchedFilter = {
            index: field.index,
            matched: false,
            fieldKey: field.key,
          };
          if (
            field.blankOnly ||
            (field.type == "dropdown" &&
              Array.isArray(field.search) &&
              field.search.includes("None"))
          ) {
            filtersApplied = true;
            // only filter any blank or empty values
            if (!s[field.key]) {
              matchedFilter.matched = true;
            }
            matchedFilters.push(matchedFilter);
            continue;
          }
          // filtering is based on the type of filter
          switch (field.type) {
            case "number":
              if (field.from || field.to) {
                filtersApplied = true;
              } else {
                if (filters.length == 1) {
                  matchedFilter.matched = true;
                }
                break;
              }
              if (
                _isNumber(parseInt(s[field.key])) &&
                ((field.from &&
                  field.to &&
                  parseInt(s[field.key]) >= field.from &&
                  parseInt(s[field.key]) <= field.to) ||
                  (field.from &&
                    !field.to &&
                    parseInt(s[field.key]) >= field.from) ||
                  (!field.from &&
                    field.to &&
                    parseInt(s[field.key]) <= field.to))
              ) {
                matchedFilter.matched = true;
              }
              break;
            case "date": {
              if (field.value && field.start && field.end) {
                filtersApplied = true;
              } else {
                // only match if this is the only filter
                if (filters.length == 1) {
                  matchedFilter.matched = true;
                }
                break;
              }
              let dateVal = DateTime.fromISO(s[field.key]);
              let interval = Interval.fromDateTimes(field.start, field.end);
              if (interval.contains(dateVal)) {
                matchedFilter.matched = true;
              }
              break;
            }
            default:
              if (field.search) {
                filtersApplied = true;
              } else {
                // matchedFilter.matched = true;
                skipFilter = true;
                break;
              }
              if (Array.isArray(field.search)) {
                if (field.search.length == 0) {
                  skipFilter = true;
                  break;
                }
                for (const itemSearch of field.search) {
                  if (
                    _isString(s[field.key]) &&
                    itemSearch &&
                    s[field.key] == itemSearch
                  ) {
                    matchedFilter.matched = true;
                  }
                }
              } else {
                if (
                  _isString(s[field.key]) &&
                  field.search &&
                  s[field.key]
                    .toLowerCase()
                    .includes(field.search.toLowerCase())
                ) {
                  matchedFilter.matched = true;
                }
              }
              break;
          }
          if (!skipFilter) {
            matchedFilters.push(matchedFilter);
          }
        }
        // when no filters are applied then all records should display
        if (!filtersApplied) {
          return true;
        }
        if (matchedFilters.length == 0) {
          return false;
        }
        // check if all filters are matched
        const filtersByField = _groupBy(matchedFilters, "fieldKey");
        for (const field in filtersByField) {
          let fieldMatched = false;
          for (const filter of filtersByField[field]) {
            if (filter.matched == true) {
              fieldMatched = true;
            }
          }
          if (!fieldMatched) {
            return false;
          }
        }
        return true;
      });
    },

    /**
     * Filters an array of data based on the current pagination
     * values.
     *
     * @param {any} data The data to be filtered
     * @returns {Array}
     */
    paginateData: function (data) {
      let paginatedData = data;
      if (!this.rowsPerPage) {
        return paginatedData;
      }
      return paginatedData.filter((row, index) => {
        let start = (this.currentPage - 1) * this.rowsPerPage;
        let end = this.currentPage * this.rowsPerPage;
        if (index >= start && index < end) return true;
      });
    },

    /**
     * Gets the alignment of a column
     *
     * @param {String} key The key of the column
     * @return {String} The columns alignment
     */
    getColAlignment: function (key) {
      for (const col of this.columns) {
        if (col.key == key && col.align) {
          return col.align;
        }
      }
      return "left";
    },

    /**
     * Gets the type of a column
     *
     * @param {String} key The key of the column
     * @return {String} The columns type
     */
    getColType: function (key) {
      for (const col of this.columns) {
        if (col.key == key && col.type) {
          return col.type;
        }
      }
      return "string";
    },

    /**
     * Gets whether a column has the displayAsHtml option
     *
     * @param {String} key The key of the column
     * @return {Bool} Whether the column is set to display as HTML
     */
    getColDisplayAsHtml: function (key) {
      for (const col of this.columns) {
        if (col.key == key && col.displayAsHtml) {
          return col.displayAsHtml;
        }
      }
      return false;
    },

    /**
     * Gets the link for a column
     *
     * @param {String} key The key of the column
     * @return {String} The columns link
     */
    getColLink: function (key) {
      for (const col of this.columns) {
        if (col.key == key && col.link) {
          return col.link;
        }
      }
      return null;
    },

    /**
     * Parses the link for a column and replaces any column keys
     * with the values
     */
    parseColLink: function (record, link) {
      for (const col of this.columns) {
        link = link.replace("{" + col.key + "}", record[col.key]);
      }
      return this.$baseUrl + link;
    },

    /**
     * Event listener for paginators page changing
     *
     * @param {Number} page The new page number
     */
    pageChanged(page) {
      const currentPage = page;
      this.currentPage = currentPage;
      if (this.dataSource && this.backendPaginationActive) {
        this.getTableData()
          .then((response) => {
            this.tableRecords = response.data;
          })
          .catch(() => {
            this.loading = false;
            this.tableDataError = true;
          });
      }
    },

    /**
     * Filters out hidden columns from the provided record
     * so that they don't display in the table
     *
     * @param {Object} record The record
     */
    getDisplayColumns(record) {
      const data = {};
      for (const [key, value] of Object.entries(record)) {
        if (key != "hidden") {
          data[key] = value;
        }
      }
      return data;
    },

    /**
     * Loads the data for the table from Ajax
     * if a data source is provided. Otherwise returns
     * the initial data.
     *
     */
    getTableData() {
      return new Promise((resolve, reject) => {
        if (!this.dataSource) {
          return resolve(this.initialData);
        }
        // get the report data using ajax
        HTTP.get(this.dataSource, {
          params: {
            page: this.currentPage,
            rowsPerPage: this.rowsPerPage,
            query: this.search,
            filter: this.filterId,
            filters: this.filters,
            sort: this.currentSortedBy,
            sortDirection: this.currentSortDirection,
          },
        })
          .then((response) => {
            if (response.data.paging) {
              // backend pagination has been enabled due to the number of records
              this.backendPaginationActive = true;
              this.backendSortingActive = true;
              this.currentPage = parseInt(response.data.paging.page);
              if (response.data.paging.simple) {
                this.simplePagination = true;
                this.hasNextPage = response.data.paging.nextPage;
              } else {
                this.currentTotalRows = parseInt(response.data.paging.count);
                this.currentPaginationStart = parseInt(
                  response.data.paging.start
                );
                this.currentPaginationEnd = parseInt(response.data.paging.end);
                this.backendFilteredRowCount = parseInt(
                  response.data.paging.filteredCount
                );
              }
            }
            return resolve(response.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    /**
     * Toggles the display of the filters section
     */
    toggleFilters() {
      this.displayFilters = !this.displayFilters;
    },

    /**
     * Triggers when the filters get updated
     * Updates the local filter variable
     */
    filtersUpdated(filters, filterId) {
      this.filters = filters;
      this.filterId = filterId;
      if (this.dataSource && this.backendPaginationActive) {
        this.applyingFilters = true;
        this.getTableData()
          .then((response) => {
            this.tableRecords = response.data;
            this.applyingFilters = false;
          })
          .catch(() => {
            this.loading = false;
            this.tableDataError = true;
          });
      }
    },

    /**
     * Reloads the data if there is a valid URL data source
     */
    reloadData() {
      if (!this.dataSource) {
        return false;
      }
      this.loading = true;
      this.getTableData()
        .then((response) => {
          this.tableRecords = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.tableDataError = true;
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-bottom: 0px;
}
.table th {
  white-space: nowrap;
  border-top: none;
}
.table-container {
  overflow-x: auto;
}
</style>
