<template>
  <div class="biblioproductcontactsform">
    <!-- product contacts -->
    <div class="row">
      <div class="col-12 biblioproductcontactsform-header">
        Product Contacts
      </div>
    </div>
    <biblio-productcontacts
      component-id="prod"
      contacts-desc="product"
      :roles="productContactRoles"
      :groups="productContactsGroups"
      :group="productContactsGroup"
      :product-contacts="productContacts"
      :all-countries="allCountries"
      @changed="
        (data) => {
          prodFormData = data;
          prodFormError = null;
        }
      "
      @error="
        (which) => {
          prodFormError = which;
        }
      "
    />
    <input name="prod" type="hidden" :value="prodFormData" />
    <hr />
    <!-- supplier contacts -->
    <div class="row">
      <div class="col-12 biblioproductcontactsform-header">
        Supplier Contacts
      </div>
    </div>
    <b-tabs v-model="suppliersTab">
      <b-tab
        v-for="supplier in suppliersList"
        :key="supplier.index"
        :title="supplier.name"
      >
        <biblio-productcontacts
          :component-id="'supl' + supplier.index"
          contacts-desc="supplier"
          :roles="supplierContactRoles"
          :groups="supplierContactsGroups"
          :group="supplier.group"
          :product-contacts="supplier.contacts"
          :all-countries="allCountries"
          @changed="
            (data) => {
              supplier.formData = data;
              supplier.formError = null;
            }
          "
          @error="
            (which) => {
              supplier.formError = which;
            }
          "
        />
        <input
          :name="'supl' + supplier.index + '-id'"
          type="hidden"
          :value="supplier.id"
        />
        <input
          :name="'supl' + supplier.index + '-data'"
          type="hidden"
          :value="supplier.formData"
        />
      </b-tab>
    </b-tabs>
    <!-- buttons -->
    <div class="formcontrols biblioproductcontactsform-footer">
      <button
        name="saveNext"
        class="btn btn-success"
        type="submit"
        :disabled="saveDisabled"
        @click="checkForm"
      >
        <span class="fa fa-save"></span> Save &amp; Continue
      </button>
      <button
        name="saveReturn"
        class="btn btn-success"
        type="submit"
        :disabled="saveDisabled"
        @click="checkForm"
      >
        <span class="fa fa-save"></span> Save &amp; Return
      </button>
      <a :href="cancelUrl" class="btn btn-danger">
        <span class="fa fa-close"></span> Cancel
      </a>
      <button
        name="save"
        class="btn btn-success"
        type="submit"
        :disabled="saveDisabled"
        @click="checkForm"
      >
        <span class="fa fa-save"></span> Apply Changes (Save)
      </button>
    </div>
  </div>
</template>

<script>
/**
 * Bibliographic product & supplier contacts page
 */
import BiblioProductContactsEdit from "../../biblio/edit/BiblioProductContactsEdit.vue";

export default {
  name: "BiblioProductContactsPage",

  components: {
    "biblio-productcontacts": BiblioProductContactsEdit,
  },

  props: {
    /**
     * Product Contact Roles
     */
    productContactRoles: {
      type: Array,
      required: true,
    },

    /**
     * Supplier Contact Roles
     */
    supplierContactRoles: {
      type: Array,
      required: true,
    },
    /**
     * Product Contacts Groups
     */
    productContactsGroups: {
      type: Array,
      default: null,
    },

    /**
     * Supplier Contacts Groups
     */
    supplierContactsGroups: {
      type: Array,
      default: null,
    },

    /**
     * Product Contacts Group
     */
    productContactsGroup: {
      type: Number,
      default: null,
    },

    /**
     * Product Contacts
     */
    productContacts: {
      type: Array,
      default: null,
    },

    /**
     * Suppliers
     */
    suppliers: {
      type: Array,
      required: true,
    },

    /**
     * The list of all countries grouped by continent and optionally with regions
     */
    allCountries: {
      type: Array,
      required: true,
    },

    /**
     * Cancel URL
     */
    cancelUrl: {
      type: String,
      required: true,
    },

    /**
     * Saving disabled
     */
    saveDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    let suppliers = [];
    for (let i in this.suppliers) {
      let supplierContacts = [];
      if (this.suppliers[i].getSupplierContacts) {
        for (let j in this.suppliers[i].getSupplierContacts) {
          supplierContacts.push({
            role: this.suppliers[i].getSupplierContacts[j].getRole,
            contact_id: this.suppliers[i].getSupplierContacts[j].getContactId,
            contact_name:
              this.suppliers[i].getSupplierContacts[j].getOrganisation,
            contact_name2:
              this.suppliers[i].getSupplierContacts[j].getOrganisationContact,
          });
        }
      }
      suppliers.push({
        index: suppliers.length + 1,
        id: this.suppliers[i].getTableId,
        name: this.suppliers[i].getName,
        group: this.suppliers[i].getSupplierContactsGroup
          ? this.suppliers[i].getSupplierContactsGroup.getTableId
          : null,
        contacts: supplierContacts,
        formData: "",
        formError: null,
      });
    }

    return {
      prodFormData: "",
      prodFormError: null,
      suppliersList: suppliers,
      suppliersTab: 0,
    };
  },

  methods: {
    /**
     * Check the form before saving
     */
    checkForm: function (event) {
      if (this.prodFormError === "role") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a contact selected but no role selected"
        );
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (this.prodFormError === "contact") {
        this.$bvModal.msgBoxOk(
          "There is a product contact entry with a role selected but no contact selected"
        );
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      for (let i in this.suppliersList) {
        if (this.suppliersList[i].formError === "role") {
          this.suppliersTab = i;
          this.$bvModal.msgBoxOk(
            "There is a supplier contact entry with a contact selected but no role selected"
          );
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        if (this.suppliersList[i].formError === "contact") {
          this.suppliersTab = i;
          this.$bvModal.msgBoxOk(
            "There is a supplier contact entry with a role selected but no contact selected"
          );
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
    },
  },
};
</script>

<style>
div.biblioproductcontactsform-header {
  font-weight: bold;
  margin-bottom: 1rem;
}
div.biblioproductcontactsform-footer {
  margin-top: 2rem;
}
</style>
