var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"biblioproductcontactsform"},[_vm._m(0),_c('biblio-productcontacts',{attrs:{"component-id":"prod","contacts-desc":"product","roles":_vm.productContactRoles,"groups":_vm.productContactsGroups,"group":_vm.productContactsGroup,"product-contacts":_vm.productContacts,"all-countries":_vm.allCountries},on:{"changed":function (data) {
        _vm.prodFormData = data;
        _vm.prodFormError = null;
      },"error":function (which) {
        _vm.prodFormError = which;
      }}}),_c('input',{attrs:{"name":"prod","type":"hidden"},domProps:{"value":_vm.prodFormData}}),_c('hr'),_vm._m(1),_c('b-tabs',{model:{value:(_vm.suppliersTab),callback:function ($$v) {_vm.suppliersTab=$$v},expression:"suppliersTab"}},_vm._l((_vm.suppliersList),function(supplier){return _c('b-tab',{key:supplier.index,attrs:{"title":supplier.name}},[_c('biblio-productcontacts',{attrs:{"component-id":'supl' + supplier.index,"contacts-desc":"supplier","roles":_vm.supplierContactRoles,"groups":_vm.supplierContactsGroups,"group":supplier.group,"product-contacts":supplier.contacts,"all-countries":_vm.allCountries},on:{"changed":function (data) {
            supplier.formData = data;
            supplier.formError = null;
          },"error":function (which) {
            supplier.formError = which;
          }}}),_c('input',{attrs:{"name":'supl' + supplier.index + '-id',"type":"hidden"},domProps:{"value":supplier.id}}),_c('input',{attrs:{"name":'supl' + supplier.index + '-data',"type":"hidden"},domProps:{"value":supplier.formData}})],1)}),1),_c('div',{staticClass:"formcontrols biblioproductcontactsform-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"name":"saveNext","type":"submit","disabled":_vm.saveDisabled},on:{"click":_vm.checkForm}},[_c('span',{staticClass:"fa fa-save"}),_vm._v(" Save & Continue ")]),_c('button',{staticClass:"btn btn-success",attrs:{"name":"saveReturn","type":"submit","disabled":_vm.saveDisabled},on:{"click":_vm.checkForm}},[_c('span',{staticClass:"fa fa-save"}),_vm._v(" Save & Return ")]),_c('a',{staticClass:"btn btn-danger",attrs:{"href":_vm.cancelUrl}},[_c('span',{staticClass:"fa fa-close"}),_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-success",attrs:{"name":"save","type":"submit","disabled":_vm.saveDisabled},on:{"click":_vm.checkForm}},[_c('span',{staticClass:"fa fa-save"}),_vm._v(" Apply Changes (Save) ")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 biblioproductcontactsform-header"},[_vm._v(" Product Contacts ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 biblioproductcontactsform-header"},[_vm._v(" Supplier Contacts ")])])}]

export { render, staticRenderFns }