<template>
  <b-dropdown
    v-if="productId && display !== 'print'"
    text="Jump To"
    variant="none"
    toggle-class="btn-link"
  >
    <b-dropdown-item @click="scrollToElement('productCardTitleAndIdentifiers')">
      Title &amp; Identifiers
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardBibliographic')">
      Bibliographic
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardContributors')">
      Contributors
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardSubjectsAndAudience')"
    >
      Subjects &amp; Audience
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardImagesAndMedia')"
    >
      Images &amp; Media
    </b-dropdown-item>
    <b-dropdown-item
      v-if="hasCustomFields"
      @click="scrollToElement('productCardCustomFields')"
    >
      Custom Fields
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardAdditionalNotes')"
    >
      Additional Notes
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardRelatedProducts')"
    >
      Related Products
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardProductFormats')">
      Format
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardFullText')">
      Full Text
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardContentItems')">
      Content Items
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardTerritorialRights')"
    >
      Territorial Rights
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardSuppliersAndPrices')">
      Suppliers &amp; Prices
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardMarketRepresentation')"
    >
      Market Representation
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardPrizesAndPromotion')"
    >
      Prizes &amp; Promotion
    </b-dropdown-item>
    <b-dropdown-item
      v-if="display !== 'brief'"
      @click="scrollToElement('productCardProductContacts')"
    >
      Product Contacts
    </b-dropdown-item>
    <b-dropdown-item @click="scrollToElement('productCardProductStatus')">
      Status
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
/**
 * Displays a dropdown list of quick jump links
 * to navigate to different scroll positions on the
 * biblio detail page
 */
export default {
  name: "ProductQuickJumpDropdown",

  props: {
    /**
     * The id of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * Display setting for tab content
     * @values all, full, brief
     */
    display: {
      type: String,
      default: null,
    },

    /**
     * Whether or not to show the custom fields dropdown option
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Scrolls to a HTML ID on a page
     */
    scrollToElement(id) {
      const el = document.getElementById(id);
      if (el != null) {
        setTimeout(function () {
          el.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    },
  },
};
</script>

<style></style>
