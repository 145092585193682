<template>
  <div :class="rowClass">
    <div v-if="caption" class="col-3 mt-2 pr-0 caption">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <label v-html="captionHtml" />
      <select
        v-if="allowExcept"
        v-model="currentExcept"
        size="1"
        @change="listModified"
      >
        <option value="N">(these only)</option>
        <option value="Y">(all except)</option>
      </select>
    </div>
    <div :class="selectorClass">
      <v-select
        ref="selector"
        v-model="currentItems"
        :input-id="inputId"
        label="name"
        :options="allItemsDisplay"
        :multiple="multiple"
        :filterable="selectorFilterable"
        :close-on-select="!multiple"
        :placeholder="'Select ' + itemType + ' or start typing'"
        @open="selectorOpen"
        @close="selectorClose"
        @search="selectorSearch"
        @input="listModified"
      >
        <template #option="templateItem">
          <div
            class="list-item"
            :class="typeof templateItem.id == 'string' ? 'invalid-item' : null"
          >
            {{ templateItem.name }}
          </div>
        </template>
        <template #selected-option="templateItem">
          <div
            class="list-item"
            :class="typeof templateItem.id == 'string' ? 'invalid-item' : null"
          >
            {{ templateItem.name }}
          </div>
        </template>
        <template #search="{ attributes, events }">
          <input
            class="vs__search"
            v-bind="attributes"
            @input="selectorSearchInput"
            @compositionstart="events.compositionstart"
            @compositionend="events.compositionend"
            @keydown="events.keydown"
            @blur="events.blur"
            @focus="events.focus"
          />
        </template>
        <template #list-footer>
          <div v-show="isMore" ref="loader" class="list-item">
            <i>Loading more options...</i>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings accepted items authority dynamic list component
 */
import { HTTP } from "../../../http-common.js";
import vSelect from "vue-select";
import _debounce from "lodash/debounce";

export default {
  name: "AcceptedItemsAuthDynList",

  components: {
    "v-select": vSelect,
  },

  props: {
    /**
     * Caption
     */
    caption: {
      type: String,
      default: null,
    },

    /**
     * Item type
     */
    itemType: {
      type: String,
      default: "item",
    },

    /**
     * Selector input id
     */
    inputId: {
      type: String,
      default: null,
    },

    /**
     * Current items list (multiple items mode)
     */
    items: {
      type: Array,
      default: null,
    },

    /**
     * Current item (single item mode)
     */
    item: {
      type: [Number, String],
      default: null,
    },

    /**
     * Except items
     */
    except: {
      type: Boolean,
      default: false,
    },

    /**
     * Allow multiple items
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * URL path to get all items
     */
    allItemsUrl: {
      type: String,
      required: true,
    },

    /**
     * Allow except
     */
    allowExcept: {
      type: Boolean,
      default: true,
    },

    /**
     * Row class
     */
    rowClass: {
      type: String,
      default: "row",
    },

    /**
     * Selector Class
     */
    selectorClass: {
      type: String,
      default: "col-9 mt-2",
    },
  },

  data: function () {
    return {
      allItems: [],
      currentItems: this.multiple ? [] : null,
      currentExcept: this.except ? "Y" : "N",
      itemsLastChange: this.items,
      itemLastChange: this.item,
      pagination: {
        count: 0,
        page: 0,
        nextPage: false,
      },
      observer: new IntersectionObserver(this.infiniteScroll),
      displayItems: 100,
      searchValue: "",
      loadingData: false,
      selectorFilterable: false,
    };
  },

  computed: {
    /**
     * Get the caption as HTML with | representing a line break
     */
    captionHtml: function () {
      return this.caption.replace(/\|/g, "<br />&emsp;");
    },

    /**
     * Get the items up to the display limit
     */
    allItemsDisplay() {
      return this.allItems ? this.allItems.slice(0, this.displayItems) : [];
    },

    /**
     * Is there more data to display or load
     */
    isMore() {
      return (
        this.displayItems < this.allItems.length || this.pagination.nextPage
      );
    },
  },

  watch: {
    /**
     * items property change (not caused by the change event)
     */
    items: function () {
      if (this.multiple && this.items != this.itemsLastChange) {
        this.currentItems = [];
        if (this.items) {
          this.findInitialItems();
        }
        this.itemsLastChange = this.items;
      }
    },

    /**
     * item property change (not caused by the change event)
     */
    item: function () {
      if (!this.multiple && this.item != this.itemLastChange) {
        this.currentItems = [];
        if (this.item) {
          this.findInitialItems();
        }
        this.itemLastChange = this.item;
      }
    },
  },

  mounted: function () {
    // if we have existing items then we need to look them up
    if (this.multiple ? this.items : this.item) {
      this.findInitialItems();
    }

    // return the initial load of data
    HTTP.get(this.$baseUrl + this.allItemsUrl)
      .then((response) => {
        this.allItems = response.data.data;
        this.pagination = response.data.pagination;
        if (this.allItems.length == this.pagination.count) {
          // all items loaded, switch to using the selector's filter
          this.selectorFilterable = true;
        }
      })
      .catch(() => {
        this.$bvModal.msgBoxOk(
          "Unable to retrieve list of " + this.itemType + "s"
        );
      });
  },

  methods: {
    /**
     * Find initial items
     */
    findInitialItems: function () {
      // get the list(s) of items to find
      let findIdList = [];
      let findNameList = [];

      let itemsList = this.multiple ? this.items : [this.item];
      for (const itemIndex in itemsList) {
        let item = itemsList[itemIndex];
        if (typeof item == "string") {
          findNameList.push(item);
        } else {
          findIdList.push(item);
        }
      }

      // find the items by id
      if (findIdList.length != 0) {
        let url =
          this.$baseUrl + this.allItemsUrl + "?findid=" + findIdList.join(".");
        HTTP.get(url)
          .then((response) => {
            for (const index in response.data.data) {
              if (this.multiple) {
                this.currentItems.push(response.data.data[index]);
              } else {
                this.currentItems = response.data.data[index];
                break;
              }
            }
          })
          .catch(() => {
            this.$bvModal.msgBoxOk(
              "Unable to retrieve list of " + this.itemType + "s"
            );
          });
      }

      // find the items by name
      if (findNameList.length != 0) {
        let url =
          this.$baseUrl +
          this.allItemsUrl +
          "?find=" +
          encodeURIComponent(findNameList.join("\t"));
        HTTP.get(url)
          .then((response) => {
            for (const index in response.data.data) {
              if (this.multiple) {
                this.currentItems.push(response.data.data[index]);
              } else {
                this.currentItems = response.data.data[index];
                break;
              }
            }
          })
          .catch(() => {
            this.$bvModal.msgBoxOk(
              "Unable to retrieve list of " + this.itemType + "s"
            );
          });
      }
    },

    /**
     * The list has been modified
     */
    listModified: async function () {
      await this.$nextTick();
      if (this.multiple) {
        // convert list of objects with an ids to a list of ids
        let list = [];
        for (const itemIndex in this.currentItems) {
          let item = this.currentItems[itemIndex];
          if (item.id != 0) {
            list.push(item.id);
          }
        }
        this.itemsLastChange = [...list];
        this.$emit("change", list, this.currentExcept == "Y");
      } else if (this.currentItems && this.currentItems.id != 0) {
        this.itemLastChange = this.currentItems.id;
        this.$emit("change", this.currentItems.id, this.currentExcept == "Y");
      } else {
        this.itemLastChange = null;
        this.$emit("change", null, this.currentExcept == "Y");
      }
    },

    /**
     * Loads more data and updated the available items
     */
    loadMoreData: async function () {
      await this.$nextTick();
      if (!this.loadingData) {
        this.loadingData = true;

        let url = this.$baseUrl + this.allItemsUrl;
        if (this.pagination.page) {
          const nextPage = this.pagination.page + 1;
          url += "?page=" + nextPage;
        }
        if (this.searchValue) {
          url += "&search=" + encodeURIComponent(this.searchValue);
        }

        try {
          const results = await HTTP.get(url);

          if (this.pagination.page !== results.data.pagination.page) {
            let allItems = [...this.allItems, ...results.data.data];
            allItems.filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            );
            this.allItems = allItems;
            this.pagination = results.data.pagination;
            if (!this.searchValue && allItems.length >= this.pagination.count) {
              // all items loaded and no search, switch to using the selector's filter
              this.selectorFilterable = true;
            }

            this.observer.unobserve(this.$refs.loader);
            this.selectorOpen();
          }
        } catch (e) {
          this.$bvModal.msgBoxOk(
            "Unable to retrieve list of " + this.itemType + "s"
          );
        }
        this.loadingData = false;
      }
    },

    /**
     * Performs a search of the dropdown items
     */
    selectorSearch: function (value) {
      this.searchValue = value;

      if (!this.selectorFilterable) {
        let url =
          this.$baseUrl +
          this.allItemsUrl +
          "?search=" +
          encodeURIComponent(this.searchValue);

        HTTP.get(url).then((response) => {
          this.allItems = response.data.data;
          this.pagination = response.data.pagination;
        });
      }
    },

    /**
     * Adds a debounce to the search input of the dropdown
     */
    selectorSearchInput: _debounce(function (e) {
      this.$refs.selector.search = e.target.value;
    }, 500),

    /**
     * When the selector's dropdown opens, watch the dropdown footer element to
     * check when it's in view
     */
    async selectorOpen() {
      if (this.isMore) {
        await this.$nextTick();
        this.observer.observe(this.$refs.loader);
      }
    },

    /**
     * When the selector's dropdown closes, stop watching
     */
    selectorClose() {
      this.observer.disconnect();
    },

    /**
     * Called by the observer and increases the limit of items currently available
     * to be displayed in the dropdown
     */
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const targetParent = target.offsetParent;
        const scrollTop = targetParent.scrollTop;

        // check if additional data needs to be loaded
        this.displayItems += 100;
        if (this.allItems.length < this.pagination.count) {
          await this.loadMoreData();
        }

        await this.$nextTick();
        targetParent.scrollTop = scrollTop;
      }
    },
  },
};
</script>

<style scoped>
div.caption {
  padding-top: 5px;
}
div.caption select {
  float: right;
}
div.list-item {
  padding: 0px 2px;
}
div.invalid-item {
  background-color: #ff9999;
}
</style>
